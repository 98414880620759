import moment from "moment-timezone"

export const useTime = () => {
    const formatTime = (time, format) => {
        return moment(time).utc().format(format);
    }
    const formatTimeCol = (time, format) => {
        return moment(time).tz("America/Bogota").format(format);
    }
    const actualTimeCol = (format) => {
        return moment().tz("America/Bogota").format(format)
    }
    return {
        formatTime,
        formatTimeCol,
        actualTimeCol
    }
}
import React from 'react'
import { Button, ButtonGroup } from "react-bootstrap";
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GeneralDatatable } from "../../General";

export const CitiesTable = (props) => {
  const columns = [
    {
      id: "id",
      name: "Número",
      selector: (row) => row?.id,
      sortable: true,
    },
    {
      name: "Departamento",
      selector: (row) => row?.departamento?.nombre,
      sortable: true
    },
    {
      name: "Codigo",
      selector: (row) => row?.codigo,
      sortable: true
    },
    {
      name: "Nombre",
      selector: (row) => row?.nombre,
      sortable: true,
    },
    {
      name: "Acciones",
      button: true,
      cell: (row) => {
        return (
          <>
            <ButtonGroup aria-label="Basic example">
              <Button
                size="sm"
                variant="warning"
                onClick={(e) => props.onEdit(row.id)}
              >
                <FontAwesomeIcon icon={faEdit} />
              </Button>
              <Button
                size="sm"
                variant="danger"
                onClick={(e) => props.onDelete(row.id)}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </Button>
            </ButtonGroup>
          </>
        );
      },
    },
  ];
  return (
    <>
      <GeneralDatatable
        columns={columns}
        data={props.data}
        customProps={{ defaultSortFieldId: "id", defaultSortAsc: false }}
      />
    </>
  )
}

import React from 'react'
import { Badge, Button, ButtonGroup } from "react-bootstrap";
import {faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GeneralDatatable } from "../../General";


export const InvoiceEditTable = (props) => {
    const columns = [
        {
            id: "id",
            name: "Número",
            selector: (row) => row?.id,
            sortable: true,
        },
        {
            name: "Fecha",
            sortable: true,
            cell: row => {
                let fecha = `${row?.fecha ?? ""}`;
                return (
                    <>
                        {fecha !== "" && (
                            <>
                                {fecha} <br />
                            </>
                        )}
                    </>
                )
            }
        },
        {
            name: "Placa",
            selector: (row) => row?.equipo?.placa,
            sortable: true
        },
        {
            name: "Tecnico",
            sortable: true,
            cell: row => {
                return (
                    <>
                        {row?.user_id !== null && (
                            <>
                                {row?.user?.nombre} <br />
                            </>
                        )}
                        {row?.vendor_id !== null && row?.user_id === null && (
                            <>
                                Vendedor solicitante: <br />
                                {row?.vendor?.nombre}
                            </>
                        )}
                    </>
                )
            }
        },
        {
            name: "Total",
            sortable: true,
            cell: row => (
              <>
                {
                  new Intl.NumberFormat('es-CO', { maximumFractionDigits: 2 }).format(
                    row?.total ?? 0
                  )
                }
              </>
            )
          },
          {
            name: "Estado",
            sortable: true,
            cell: (row) => {
              let color = "primary";
              let border = "";
              let label = ""
              switch (row.estado) {
                case 0:
                  color = "danger";
                  border = "danger";
                  label = "Servicio solicitado";
                  break;
                case 1:
                  color = "warning";
                  border = "warning";
                  label = "Por realizar";
                  break;
                case 2:
                  color = "success";
                  border = "success";
                  label = "Realizado";
                  break;
                case 3:
                  color = "warning";
                  border = "danger";
                  label = "En seguimiento";
                  break;
                case 4:
                  color = "primary";
                  border = "primary";
                  label = "Facturado";
                  break;
                case 5:
                  color = "info";
                  border = "info";
                  label = "Por abrobar";
                  break;
                default:
                  color = "danger";
                  border = "warning";
                  label = "Estado no válido";
                  break;
              }
              return (
                <Badge pill bg={color} className={`border border-${border}`} >
                  {label}
                </Badge>
              );
            },
          },
        {
            name: "Acciones",
            button: true,
            cell: (row) => {
                return (
                    <>
                        <ButtonGroup aria-label="Basic example">

                            <Button
                                size="sm"
                                variant="info"
                                onClick={(e) => props.onView(row.id)}
                            >
                                <FontAwesomeIcon icon={faEye} />
                            </Button>

                        </ButtonGroup>
                    </>
                );
            },
        },
    ]
    return (
        <>
            <GeneralDatatable
                columns={columns}
                data={props.data}
                customProps={{ defaultSortFieldId: "id", defaultSortAsc: false }}
            />
        </>
    )
}

import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSync } from "@fortawesome/free-solid-svg-icons";
import { Row, Col, Button, Modal, Form, FormControl } from "react-bootstrap";
import { RolesTable } from "./RolesTable";
import { useAxios } from "../../../contexts";
import { Loader } from "../../Loader/Loader";
import { useAlert, useForm, useNotify } from "../../../hooks";

export const ManageRoles = () => {
  const [loading, setLoading] = useState(false);
  const [sLoading, setSLoading] = useState(false);
  const [editRolState, setEditRolState] = useState({});
  const [roles, setRoles] = useState([]);
  const [showAddRol, setShowAddRol] = useState(false);
  const handleCloseCreate = () => setShowAddRol(false);
  const { notify, DelAlert } = useNotify();
  const handleShowCreate = () => setShowAddRol(true);
  const { privateFetch } = useAxios();
  const { serialize } = useForm();
  const { Alert } = useAlert();
  const [showModalEdit, setShowModalEdit] = useState(false);
  const handleCloseEdit = () => setShowModalEdit(false);
  useEffect(() => {
    getRoles();
    return () => {
      setRoles([]);
      setLoading(false);
    };
    // eslint-disable-next-line
  }, []);
  const getRoles = async () => {
    setLoading(true);
    const { data: roles } = await privateFetch.get("/api/users/getRoles");
    setRoles(roles.roles);
    setLoading(false);
  };
  const guardarRol = async (ev) => {
    ev.preventDefault();
    setSLoading(true);
    const formData = serialize(ev.target);
    const { data } = await privateFetch.post("/api/users/guardarRol", formData);
    notify({
      ...data,
      callback: () => {
        ev.target.reset();
      },
    });
    getRoles();
    setShowAddRol(false);
    setSLoading(false);
  };
  const editRol = (id) => {
    const rol = roles.filter(val => val.id === id)?.[0];
    setEditRolState(rol);
    setShowModalEdit(true);
  };
  const editarRol = async (ev) => {
    ev.preventDefault();
    setSLoading(true);
    const formData = serialize(ev.target);
    const { data } = await privateFetch.put("/api/users/actualizarRol/" + editRolState?.id, formData);
    notify({
      ...data,
      callback: () => {
        ev.target.reset();
        setEditRolState({});
      },
    });
    getRoles();
    setShowModalEdit(false);
    setSLoading(false);
  }
  const deleteRol = async (id) => {
    if ([1, 2, 3, 4].includes(id)) {
      return Alert({
        type: "warning",
        text: "Éste tipo de rol no se puede eliminar, ya que dañaría el sistema",
      });
    }
    await DelAlert({
      text: "Desea eliminar el rol?",
      onConfirm: async () => {
        const { data } = await privateFetch.delete(
          "/api/users/deleteRol/" + id
        );
        notify(data);
        getRoles();
      },
      onCancel: () => {
        Alert({
          type: "warning",
          text: "Eliminación cancelada",
        });
      },
    });
  };
  return loading ? (
    <Loader />
  ) : (
    <>
      <Row className="mb-2">
        <Col sm="auto">
          <Button size="sm" variant="primary" onClick={getRoles}>
            <FontAwesomeIcon icon={faSync} />
          </Button>
        </Col>
        <Col sm="auto">
          <Button size="sm" variant="primary" onClick={handleShowCreate}>
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </Col>
      </Row>
      <RolesTable data={roles} onEdit={editRol} onDelete={deleteRol} />
      <Modal show={showAddRol} onHide={handleCloseCreate}>
        <Modal.Header closeButton>
          <Modal.Title>Nuevo Rol</Modal.Title>
        </Modal.Header>
        {!loading ? (
          <Form onSubmit={guardarRol} autoComplete="off">
            <Modal.Body>
              <Row>
                <Col sm>
                  <label>Nombre</label>
                  <FormControl size="sm" type="text" name="nombre" required />
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button size="sm" variant="secondary" onClick={handleCloseCreate}>
                Cerrar
              </Button>
              <Button
                type="submit"
                size="sm"
                variant="primary"
                disabled={sLoading}
              >
                {sLoading ? <></> : <>Guardar</>}
              </Button>
            </Modal.Footer>
          </Form>
        ) : (
          <Loader />
        )}
      </Modal>
      <Modal show={showModalEdit} onHide={handleCloseEdit}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Rol</Modal.Title>
        </Modal.Header>
        <Form onSubmit={editarRol} autoComplete="off">
          <Modal.Body>
            <Row>
              <Col sm>
                <label>Nombre</label>
                <FormControl size="sm" type="text" name="nombre" defaultValue={editRolState?.nombre} required />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button size="sm" variant="secondary" onClick={handleCloseEdit}>
              Cerrar
            </Button>
            <Button
              type="submit"
              size="sm"
              variant="primary"
              disabled={sLoading}
            >
              {sLoading ? <></> : <>Guardar</>}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

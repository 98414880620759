import React, { useEffect, useState } from 'react'
import { useForm, useNotify, usePermissions } from '../../../hooks';
import { useAuth, useAxios } from '../../../contexts';
import { useNavigate, useParams } from 'react-router-dom';
import { GESTION_CLIENTES } from '../../../utils';
import { Button, Card, Col, Container, Form, FormControl, Row, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { EditInfCorreos, Loader } from '../../../components';

export const EditClient = () => {
    const [cliente, setCliente] = useState({});
    const [emails, setEmails] = useState([]);
    const [loading, setLoading] = useState(false);
    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const { serialize } = useForm();
    const { user } = useAuth();
    const { notify } = useNotify();
    const { id } = useParams();
    const { privateFetch } = useAxios();
    const navigate = useNavigate();
    const { validateRoutePermission } = usePermissions();

    useEffect(() => {
        document.title = "Editar cliente";
        if (!id) navigate("/home");
        validateRoutePermission(user, GESTION_CLIENTES);
        getCliente();
        return () => {
            setCliente({});
            setLoading(false);
        }
        // eslint-disable-next-line
    }, []);
    const getCliente = async () => {
        setLoading(true);
        const { data } = await privateFetch.get(`/api/clients/getClients/${id}`);
        setCliente(data.clients);
        setLoading(false);
    }
    const saveClient = async (ev) => {
        ev.preventDefault();
        setSpinnerLoading(true);
        const formData = {
            ...serialize(ev.target),
            correos: emails
        };
        const { data } = await privateFetch.put(
            "api/clients/actualizarCliente/" + id, formData
        );
        notify({
            ...data
        })
        getCliente();
        setSpinnerLoading(false);
    }
    return (
        <>
            <Container fluid className='mt-2'>
                <Card>
                    <Card.Header>Editar Cliente</Card.Header>
                    {!loading ? (
                        <Form onSubmit={saveClient} autoComplete='off'>
                            <Card.Body>
                                <Row>
                                    <Col sm="auto">
                                        <label>Razón social</label>
                                        <FormControl size="sm" required name='nombre' defaultValue={cliente?.nombre} />
                                    </Col>
                                    <Col sm="auto">
                                        <label>Nombre responsable</label>
                                        <FormControl size="sm" required name='nombre_responsable' defaultValue={cliente?.nombre_responsable} />
                                    </Col>
                                    <Col sm="auto">
                                        <label>NIT</label>
                                        <FormControl size="sm" required name='nit' defaultValue={cliente?.nit} />
                                    </Col>
                                    <Col sm='auto'>
                                        <label>Telefono</label>
                                        <FormControl size='sm' name='telefono' required defaultValue={cliente?.telefono} />
                                    </Col>
                                    <Col sm='auto'>
                                        <label>Correo</label>
                                        <FormControl size='sm' type="email" name='correo' required defaultValue={cliente?.correo} />
                                    </Col>
                                    <Col sm='auto'>
                                        <label>Correo alternativo</label>
                                        <FormControl size='sm' type="email" name='correo_alternativo' defaultValue={cliente?.correo_alternativo} />
                                    </Col>
                                    <Col sm='auto'>
                                        <label>Averías</label>
                                        <Form.Check // prettier-ignore
                                            type="switch"
                                            value={true}
                                            name="informe_averias"
                                            defaultChecked={cliente?.informe_averias ?? false}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm className='mt-2'>
                                        <label>Direccion</label>
                                        <FormControl
                                            as="textarea"
                                            size="sm"
                                            type="text"
                                            name="direccion"
                                            required
                                            defaultValue={cliente?.direccion}
                                        />
                                    </Col>
                                </Row>
                                <EditInfCorreos setEmails={setEmails} correos={cliente?.main_correo_infs} emails={emails} />
                            </Card.Body>
                            <Card.Footer>
                                <Button type='submit' size='sm' variant='primary' disabled={spinnerLoading}>
                                    {
                                        spinnerLoading ? (
                                            <>
                                                <Spinner animation="grow" variant="warning" />
                                            </>
                                        ) : (
                                            <>
                                                <FontAwesomeIcon icon={faSave} /> &nbsp;
                                                Guardar
                                            </>
                                        )
                                    }
                                </Button>
                            </Card.Footer>
                        </Form>
                    ) : (
                        <Loader />
                    )}
                </Card>
            </Container>
        </>
    )
}

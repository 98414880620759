import { View, Text } from '@react-pdf/renderer';
import React from 'react'

export const Checks1 = ({ inform }) => {
    const tiposOperacion = {
        1: "Preventivo",
        2: "Correctivo",
        3: "Alistamiento",
        4: "Baja"
    }
    return (
        <>
            <View style={{
                paddingLeft: "20px",
                paddingRight: "20px"
            }}>
                <View style={{
                    display: "flex",
                    flexDirection: 'row',
                }}>
                    <View style={{
                        width: "60%",
                        display: "flex",
                        flexDirection: 'row',
                    }}
                    >
                        <Text style={{
                            fontWeight: 'heavy'
                        }}
                        >Código cliente:</Text>
                        <Text style={{
                            minWidth: "130px",
                            marginLeft: "3px",
                        }}>{inform?.codigo_cliente}</Text>
                    </View>
                    <Text>Fecha:</Text>
                    <Text style={{
                        minWidth: "100px",
                        marginLeft: "3px"
                    }}>{inform?.fecha}
                    </Text>
                </View>
                <View style={{
                    display: "flex",
                    flexDirection: 'row',
                    paddingTop: "2px"
                }}>
                    <View style={{
                        width: "70%",
                        display: "flex",
                        flexDirection: 'row',
                    }}>
                        <Text>Persona que recibe:</Text>
                        <Text style={{
                            paddingLeft: "3px"
                        }}>{inform?.persona_recibe}</Text>
                    </View >
                    <View style={{
                        width: "50%",
                        display: "flex",
                        flexDirection: 'row',
                    }}>
                        <Text>Refrigeracion: </Text>
                        <View style={{
                            width: "15px",
                            height: "15px",
                            backgroundColor: '#f0f0f0',
                            borderRadius: 3,
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                            <Text>{inform?.tipo_refrigeracion === 1 ? "x" : null}</Text>
                        </View>
                        <Text style={{ marginLeft: "20px" }}>Electrico: </Text>
                        <View style={{
                            width: "15px",
                            height: "15px",
                            backgroundColor: '#f0f0f0',
                            borderRadius: 3,
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                            <Text>{inform?.tipo_refrigeracion === 2 ? "x" : null}</Text>
                        </View>
                    </View>
                </View>
                <View style={{
                    display: "flex",
                    flexDirection: 'row',
                    paddingTop: "2px"
                }}>
                    <View style={{
                        width: "70%",
                        display: "flex",
                        flexDirection: 'row',
                    }}>
                        <Text>Establecimiento: </Text>
                        <Text style={{
                            paddingLeft: "3px"
                        }}>{inform?.establecimiento}</Text>
                    </View >
                    <View style={{
                        width: "50%",
                        display: "flex",
                        flexDirection: 'row',
                    }}>
                        <Text>Propiedad de: </Text>
                        <Text style={{
                            paddingLeft: "3px"
                        }}>{inform?.equipo?.cliente?.nombre}</Text>
                    </View>
                </View>
                <View style={{
                    display: "flex",
                    flexDirection: 'row',
                    paddingTop: "2px"
                }}>
                    <View style={{
                        width: "70%",
                        display: "flex",
                        flexDirection: 'row',
                    }}>
                        <Text>Tel: </Text>
                        <Text style={{
                            paddingLeft: "3px",
                            width: "120px"
                        }}>{inform?.telefono}</Text>
                        {/*<Text>Cel: </Text>
                        <Text style={{
                            paddingLeft: "3px"
                        }}></Text>*/}
                    </View >
                    <View style={{
                        width: "50%",
                        display: "flex",
                        flexDirection: 'row',
                    }}>
                        <Text>Numero activo: </Text>
                        <Text style={{
                            paddingLeft: "3px"
                        }}>{inform?.equipo?.placa}</Text>
                    </View>
                </View>
                <View style={{
                    display: "flex",
                    flexDirection: 'row',
                    paddingTop: "2px"
                }}>
                    <View style={{
                        width: "70%",
                        display: "flex",
                        flexDirection: 'row',
                    }}>
                        <Text>Regional: </Text>
                        <Text style={{
                            paddingLeft: "3px"
                        }}>{inform?.city?.city?.nombre}</Text>
                    </View >
                    <View style={{
                        width: "50%",
                        display: "flex",
                        flexDirection: 'row',
                    }}>
                        <Text>Capacidad (Pies):</Text>
                        <Text style={{
                            paddingLeft: "3px"
                        }}>{inform?.equipo?.capacidad_pies}</Text>
                    </View>
                </View>
                <View style={{
                    display: "flex",
                    flexDirection: 'row',
                    paddingTop: "2px"
                }}>
                    <View style={{
                        width: "70%",
                        display: "flex",
                        flexDirection: 'row',
                    }}>
                        <Text>FALLA REPORTADA: </Text>
                        <Text style={{
                            maxWidth: "180px",
                            paddingLeft: "3px"
                        }}>{inform?.falla_reportada}</Text>
                    </View >
                    <View style={{
                        width: "50%",
                        display: "flex",
                        flexDirection: 'row',
                    }}>
                        <Text>{tiposOperacion[inform?.tipo_operacion ?? 1]}</Text>
                        <View style={{
                            width: "15px",
                            height: "15px",
                            backgroundColor: '#f0f0f0',
                            borderRadius: 3,
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginRight: "3px",
                            marginLeft: "2px"
                        }}>
                            <Text>X</Text>
                        </View>

                    </View>
                </View>
            </View>
        </>
    )
}

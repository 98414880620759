import { faMinus, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, FormSelect, Row, Table, Modal, FormControl, ButtonGroup } from 'react-bootstrap'
import { useAuth } from '../../../contexts';
import { usePermissions } from '../../../hooks';
import { VALORES_INFORME } from '../../../utils';

export const Procedures = ({ edit, procedures, appliedProc, setProcedures, inform, setChecks, setTouched }) => {
  const { user } = useAuth()
  const [showModal, setShowModal] = useState(false);
  const procedureRef = useRef();
  const cantidadRef = useRef();
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const { validate } = usePermissions();
  useEffect(() => {
    //setAppliedFromSaved();
    //console.log(appliedProc)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setTouched(true);
    if (appliedProc?.length === 0) return setChecks(false);
    setChecks(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appliedProc])

  const addItem = (ev) => {
    const { current: procedimiento } = procedureRef;
    const { current: cantidad } = cantidadRef;
    if ([null, ""].includes(procedimiento?.value) || [null, ""].includes(cantidad?.value)) return;
    const value = parseInt(procedimiento?.value ?? "0");
    const index = appliedProc?.findIndex((val) => val.proc_id === value);
    if (index !== -1) {
      setProcedures(prev => {
        const copy = [...prev];
        copy[index].cantidad += (parseInt(cantidad?.value ?? "0"));
        return copy;
      })
    } else {
      const actualProcedure = procedures?.filter(val => val.id === value)?.[0];
      const copy = [...appliedProc];
      copy.push({
        id: null,
        proc_id: actualProcedure?.id,
        nombre: actualProcedure?.nombre,
        codigo: actualProcedure?.codigo,
        valor: actualProcedure?.precio,
        cantidad: parseInt(cantidad?.value ?? "0")
      })
      setProcedures(copy);
    }
    procedimiento.value = "";
    cantidad.value = "";
    handleClose();
  }
  const deleteItem = (key) => {
    const proceduresF = appliedProc.filter((val, keyA) => keyA !== key);
    setProcedures(proceduresF);
  }
  const lessOne = (key) => {
    const copy = [...appliedProc];
    if (copy?.[key]?.cantidad === 1) return;
    copy[key].cantidad -= 1;
    setProcedures(copy)
  }
  const plusOne = (key) => {
    const copy = [...appliedProc];
    copy[key].cantidad += 1;
    setProcedures(copy)
  }
  return (
    <>
      <Row>
        <Col sm={4}>
          <Button type='button' size='sm' variant='success' onClick={handleShow} >
            Añadir procedimiento <FontAwesomeIcon icon={faPlus} />
          </Button>
        </Col>
      </Row>
      <Row className='mt-3'>
        <Col sm>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>#</th>
                <th>Nombre</th>
                <th>Cantidad</th>
                <th>Código</th>
                {
                  validate(user, VALORES_INFORME) && (
                    <>
                      <th>Valor unitario</th>
                      <th>Valor total</th>
                    </>
                  )
                }
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {
                appliedProc?.map((val, key) => (
                  <tr key={key}>
                    <td>
                      {key + 1}
                    </td>
                    <td>
                      {val?.nombre}
                    </td>
                    <td>
                      {val?.cantidad}
                    </td>
                    <td>
                      {val?.codigo}
                    </td>
                    {
                      validate(user, VALORES_INFORME) && (
                        <>
                          <td>
                            {val?.valor}
                          </td>
                          <td>
                            {val?.valor * val?.cantidad}
                          </td>
                        </>
                      )
                    }
                    <td>
                      <ButtonGroup size='sm'>
                        {
                          val?.cantidad > 1 && (
                            <Button variant='warning' size="sm" onClick={e => lessOne(key)}>
                              <FontAwesomeIcon icon={faMinus} />
                            </Button>
                          )
                        }
                        <Button variant='success' size="sm" onClick={e => plusOne(key)}>
                          <FontAwesomeIcon icon={faPlus} />
                        </Button>
                        <Button variant='danger' size="sm" onClick={e => deleteItem(key)}>
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </Button>
                      </ButtonGroup>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </Table>
        </Col>
      </Row >
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Añadir nuevo procedimiento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm>
              <label>Procedimiento</label>
              <FormSelect size='sm' ref={procedureRef}>
                <option value="">Seleccione</option>
                {
                  procedures?.map((val, index) => (
                    <option key={index} value={val?.id}>{val?.nombre}</option>
                  ))
                }
              </FormSelect>
            </Col>
          </Row>
          <Row>
            <Col sm>
              <label>Cantidad</label>
              <FormControl size='sm' type='number' ref={cantidadRef} />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button size='sm' variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
          <Button size='sm' type='button' variant="primary" onClick={addItem}>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

import React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';

export const RepuestosTable = ({ repuestos, user, querys }) => {
    const styles = StyleSheet.create({
        scrollContainer: {
            flexGrow: 1,
        },
        table: {
            margin: 20,
            borderColor: '#cccccc',
            borderStyle: 'solid',
        },
        row: {
            flexDirection: 'row',
            borderBottomWidth: 1,
            borderBottomColor: '#cccccc',
            paddingVertical: 5,
        },
        headerCell: {
            flex: 1,
            textAlign: 'center',
            fontWeight: 'bold',
        },
        cell: {
            flex: 1,
            textAlign: 'center',
            minWidth: 50,
        },
    });
    return (
        <>
            <Text style={{
                textAlign: "center",
            }}
            >Lista de Repuestos</Text>
            <View style={styles.table}>
                <View style={styles.row}>
                    <Text style={styles.headerCell}>Número</Text>
                    <Text style={styles.headerCell}>Repuestos</Text>
                    <Text style={styles.headerCell}>Código</Text>
                    <Text style={styles.headerCell}>Cantidad</Text>
                    {
                        querys() && (
                            <>
                                <Text style={styles.headerCell}>Valor Unitario</Text>
                                <Text style={styles.headerCell}>Valor Total</Text>
                            </>
                        )
                    }
                </View>
                {
                    repuestos?.length > 0 ? (
                        repuestos?.map((val, i) => (
                            <View key={i} style={styles.row}>
                                <Text style={styles.cell}>{i + 1}</Text>
                                <Text style={styles.cell}>{val?.inv?.nombre}</Text>
                                <Text style={styles.cell}>{val?.inv?.codigo}</Text>
                                <Text style={styles.cell}>{val?.cantidad}</Text>
                                {
                                    querys() && (
                                        <>
                                            <Text style={styles.cell}>{val?.valor} COP</Text>
                                            <Text style={styles.cell}>{val?.valor * val?.cantidad} COP</Text>
                                        </>
                                    )
                                }
                            </View>
                        ))
                    ) : (
                        <View style={styles.row}>
                            <Text style={styles.cell}></Text>
                            <Text style={styles.cell}>No Aplica</Text>
                            <Text style={styles.cell}></Text>
                            <Text style={styles.cell}></Text>
                            {
                                querys() & (
                                    <>
                                        <Text style={styles.cell}></Text>
                                        <Text style={styles.cell}></Text>
                                    </>
                                )
                            }

                        </View>
                    )
                }
            </View>
        </>
    )
}
import React from 'react'
import logo from "../../assets/img/logoSC.png";

export const Logo = (props) => {
  return (
    <>
        <img className={props.className ?? ''} width={props.width} src={logo} alt="logo" />
    </>
  )
}

import React, { useEffect, useState } from 'react'
import { Alert, Col, Container, Row, FormSelect, Spinner } from 'react-bootstrap';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  LineController
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useAuth, useAxios } from '../../../contexts';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  LineController
);

const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

export const Home = () => {
  const { user } = useAuth();
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState("");
  const [savedData1, setSavedData1] = useState([]);
  const [savedData2, setSavedData2] = useState([]);
  const [labels, setLabels] = useState(meses);
  const [loading, setLoading] = useState(false);
  const { privateFetch } = useAxios();
  const options = (name) => ({
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: name,
      },
    },
  });
  const data1 = {
    labels: labels,
    datasets: [
      {
        type: "line",
        label: 'Cantidad',
        borderColor: "rgb(75, 192, 192)",
        borderWidth: 2,
        data: savedData1,
        fill: false,
        backgroundColor: '#0db7f2',
      },
    ],
  };
  const data2 = {
    labels: labels,
    datasets: [
      {
        label: 'Cantidad',
        data: savedData2,
        backgroundColor: '#0db7f2'
      },
    ],
  };
  useEffect(() => {
    document.title = "Home";
  }, []);
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year, month]);

  const returnYears = () => {
    const options = [];
    const actualYear = new Date().getFullYear();
    for (let i = actualYear; i > actualYear - 5; i--) {
      options.push(<>
        <option key={i} value={i}>{i}</option>
      </>)
    }
    return options;
  }
  const returnMonths = () => {
    const options = [];
    for (let i = 0; i < 12; i++) {
      options.push(<>
        <option key={i} value={i + 1}>{meses[i]}</option>
      </>)
    }
    return options;
  }
  const getData = async () => {
    setLoading(true)
    if (!month) {
      setLabels(meses);
    }
    const { data } = await privateFetch.post("/api/home/getCharts", {
      year: !year ? new Date().getFullYear() : year,
      month: ![null, ""].includes(month) ? month : null
    })
    //Manejar data
    setLabels(data?.data?.labels);
    setSavedData1(data?.data?.monthsQty);
    setSavedData2(data?.data?.monthsAmount)
    setLoading(false)
  }
  return (
    <Container fluid className='mt-3'>
      <Row className='my-3'>
        <Col sm>
          <Alert>
            Hola <strong>{user?.nombre}</strong> ,bienvenido de nuevo a Servicold MD
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col sm={3}>
          <FormSelect size='sm' value={year} onChange={e => e.target.value !== "" ? setYear(parseInt(e?.target?.value)) : null} >
            <option value="">Año</option>
            {
              returnYears()?.map((val, i) => (
                <React.Fragment key={i}>
                  {val}
                </React.Fragment>
              ))
            }
          </FormSelect>
        </Col>
        <Col sm={3}>
          <FormSelect size='sm' defaultValue={month} onChange={e => e.target.value !== "" ? setMonth(parseInt(e?.target?.value)) : setMonth(null)} >
            <option value="">Mes</option>
            {
              returnMonths()?.map((val, i) => (
                <React.Fragment key={i}>
                  {val}
                </React.Fragment>
              ))
            }
          </FormSelect>
        </Col>
      </Row>
      <Row>
        <Col sm>
          {
            loading ? (<>
              <Spinner size='md' variant='warning' animation='grow' /> &nbsp; Cargando datos...
            </>) : (<>
              <Bar options={options('Servicios al año')} data={data1} />
            </>)
          }
        </Col>
        {
          user?.rol_id === 1 && (
            <Col sm>
              <Row>
                <Col sm>
                  {
                    loading ? (<>
                      <Spinner size='md' variant='warning' animation='grow' /> &nbsp; Cargando datos...
                    </>) : (<>
                      <Bar options={options('Valor al año')} data={data2} />
                    </>)
                  }
                </Col>
              </Row>
            </Col>
          )
        }
      </Row>
    </Container>
  )
}

import React from 'react'
import { Badge, Button, ButtonGroup } from "react-bootstrap";
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GeneralDatatable } from "../../General";

export const EquipmentTable = (props) => {
  const columns = [
    {
      id: "id",
      name: "Número",
      selector: (row) => row?.id,
      sortable: true,
    },
    {
      name: "Cliente",
      selector: (row) => row?.cliente?.nombre,
      sortable: true,
    },
    {
      name: "Placa",
      selector: (row) => row?.placa,
      sortable: true,
    },
    
    {
      name: "Denominación de tipo",
      selector: (row) => row?.tipo?.nombre,
      sortable: true,
    },
    {
      name: "Estado",
      sortable: true,
      cell: (row) => {
        let color = "primary";
        let label = "";
        switch (row.estado) {
          case 0:
            color = "danger";
            label = "Inactivo"
            break;
          case 1:
            color = "success";
            label = "Activo"
            break;
          case 2:
            color = "warning";
            label = "Por reparar"
            break;
          case 3:
            color = "primary";
            label = "Reparado"
            break;
          default:
            color = "info"
            label = "En bodega"
            break;
        }
        return (
          <Badge pill bg={color}>
            {label}
          </Badge>
        );
      },
    },
    {
      name: "Acciones",
      button: true,
      cell: (row) => {
        return (
          <>
            <ButtonGroup aria-label="Basic example">
              <Button
                size="sm"
                variant="warning"
                onClick={(e) => props.onEdit(row.id)}
              >
                <FontAwesomeIcon icon={faEdit} />
              </Button>
              <Button
                size="sm"
                variant="danger"
                onClick={(e) => props.onDelete(row.id)}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </Button>
            </ButtonGroup>
          </>
        );
      },
    },
  ]
  return (
    <>
      <GeneralDatatable
        columns={columns}
        data={props.data}
        customProps={{ defaultSortFieldId: "id", defaultSortAsc: false }}
      />
    </>
  )
}

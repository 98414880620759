import { faArrowLeft, faArrowRight, faPause } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Button, Col, Row, Table } from 'react-bootstrap'

export const AssignmentTable = ({ data, info, setId, fromId, toId }) => {
    return (
        <Col sm>
            <Row>
                <Col sm>
                    {info?.title}
                </Col>
            </Row>
            <Row>
                <Col sm>
                    <Table striped bordered responsive hover>
                        <thead>
                            <tr>
                                <th>Empresa</th>
                                {
                                    info?.type === 2 && (
                                        <th>Técnico</th>
                                    )
                                }
                                <th>Cantidad</th>
                                <th>Mover</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data?.filter(info.filter)?.map((val, index) => (
                                    <tr key={index}>
                                        <td>{val?.cliente?.nombre}</td>
                                        {
                                            info?.type === 2 && (
                                                <td>{val?.user?.nombre}</td>
                                            )
                                        }
                                        <td>{val?.cantidad}</td>
                                        <td>
                                            <Button size='sm' variant={fromId === val?.id && fromId !== null  ? 'success': toId === val?.id && toId !== null ? 'danger' : 'primary'} onClick={e => setId(e, val?.id)}>
                                                <FontAwesomeIcon icon={fromId === val?.id && fromId !== null ? faArrowRight: toId === val?.id && toId !== null ? faArrowLeft : faPause } />
                                            </Button>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Col>
    )
}

import React, { useState } from 'react'
import { useAuth } from '../../contexts'
import { useForm, useNotify } from '../../hooks';
import { useAxios } from "../../contexts"
import { Loader } from '../Loader/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey, faUser } from '@fortawesome/free-solid-svg-icons';
import { Button, Row, Col } from 'react-bootstrap';
import styles from "./Login.module.css";
import { ModalRecovery } from './ModalRecovery';

export const LoginForm = () => {
    const { Login } = useAuth();
    const { serialize } = useForm();
    const { notify } = useNotify();
    const { publicFetch } = useAxios();
    const [loading, setLoading] = useState(false);
    const [recoveryModal, setShowRModal] = useState(false);
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const formData = serialize(e.target);
        const { data } = await publicFetch.post("/api/login/login", formData);
        notify({
            ...data,
            callback: () => { if (data.code === 200) Login(data) }
        });
        setLoading(false);
    }
    return loading ? (
        <Loader />
    ) : (
        <>
            <form onSubmit={handleSubmit} >
                <div className="form-froup p-1">
                    <label htmlFor="username text-muted">
                        <h6 className='text-grey'>
                            <FontAwesomeIcon icon={faUser} />&nbsp;
                            Usuario
                        </h6 >
                    </label>
                    <input type="text" name="email" required className='form-control mb-2' />
                    <div className="invalid-feedback">Usuario</div>
                    <label htmlFor="password" className="mt-4">
                        <h6 className="text-grey">
                            <FontAwesomeIcon icon={faKey} />&nbsp;
                            Contraseña
                        </h6>
                    </label>
                    <input name="password" required id="password" type="password" className="form-control" />
                    <div className="invalid-feedback">Contraseña</div>
                </div >
                <Row className="m-3 justify-content-center">
                    <Col sm="auto">
                        <Button size="sm" onClick={e => setShowRModal(prev => !prev)} className={`${styles["btn-primary"]}`} variant="primary">
                            ¿Olvidó su contraseña?
                        </Button>
                    </Col>
                </Row>
                <Row className="m-3 justify-content-center">
                    <Col sm="auto">
                        <Button size="sm" className={`${styles["btn-primary"]}`} variant="primary" type="submit" >
                            Iniciar sesión
                        </Button>
                    </Col>
                </Row>
            </form >
            <ModalRecovery show={recoveryModal} setShow={setShowRModal} />
        </>
    )
}

import React from 'react';
import { View, Text } from '@react-pdf/renderer';

export const Checks3 = ({ inform }) => {
    return (
        <>
            <View style={{
                width: "48%",
                backgroundColor: '#f0f0f0',
                padding: "5px"
            }}>
                <Text style={{
                    textAlign: 'center',
                    paddingTop: "5px"
                }} >Sistema de Refrigeración</Text>
                <View style={{
                    display: "flex",
                    flexDirection: 'row',
                    paddingTop: "5px",
                    paddingLeft: "5px"
                }}>
                    <View style={{
                        width: "60%",
                        padding: "3px"
                    }}>
                        <Text style={{ marginTop: "2.2px" }}>Referencia Compresor</Text>
                        <Text style={{ marginTop: "2.2px" }}>Tipo de Refrigerantes</Text>
                    </View>
                    <View style={{ width: "40%", alignItems: 'center', }}>
                        <View style={{
                            width: "50px",
                            height: "15px",
                            backgroundColor: '#ffffff',
                            borderRadius: 3,
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginRight: "3px",
                            marginLeft: "2px",
                            marginTop: "3px"
                        }}>
                            <Text>{inform?.referencia_compresor}</Text>
                        </View>
                        <View style={{
                            width: "50px",
                            height: "15px",
                            backgroundColor: '#ffffff',
                            borderRadius: 3,
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginRight: "3px",
                            marginLeft: "2px",
                            marginTop: "3px"
                        }}>
                            <Text>{inform?.refrigerante?.nombre}</Text>
                        </View>
                    </View>
                </View>

                <View style={{
                    display: "flex",
                    flexDirection: 'row',
                }}>
                    <View style={{
                        marginLeft: "10px",
                    }}>
                        <Text style={{ marginTop: "2.2px" }}>T°C Llegada:</Text>
                    </View>
                    <View style={{
                        width: "50px",
                        height: "15px",
                        backgroundColor: '#ffffff',
                        borderRadius: 3,
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginRight: "3px",
                        marginLeft: "2px",
                        marginTop: "3px"
                    }}>
                        <Text>{inform?.temp_llegada}</Text>
                    </View>
                    <View style={{
                        marginLeft: "10px",
                    }}>
                        <Text style={{ marginTop: "2.2px" }}>Final:</Text>
                    </View>
                    <View style={{
                        width: "50px",
                        height: "15px",
                        backgroundColor: '#ffffff',
                        borderRadius: 3,
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginRight: "3px",
                        marginLeft: "5px",
                        marginTop: "3px"
                    }}>
                        <Text>{inform?.temp_salida}</Text>
                    </View>
                </View>

                <Text style={{
                    textAlign: 'center',
                    paddingTop: "5px"
                }} >Condensador</Text>

                <View style={{
                    display: "flex",
                    flexDirection: 'row',
                    paddingLeft: "5px"
                }}>
                    <View style={{
                        width: "60%",
                        padding: "3px"
                    }}>
                        <Text style={{ marginTop: "3.3px" }}>Normal</Text>
                        <Text style={{ marginTop: "3.3px" }}>Obstrudio de Grasa</Text>
                        <Text style={{ marginTop: "3.3px" }}>Obstrudio de Polvo</Text>
                        <Text style={{ marginTop: "3.3px" }}>Fugas</Text>
                    </View>
                    <View style={{ width: "40%", alignItems: 'center', }}>
                        <View style={{
                            width: "50px",
                            height: "15px",
                            backgroundColor: '#ffffff',
                            borderRadius: 3,
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginRight: "3px",
                            marginLeft: "2px",
                            marginTop: "3px"
                        }}>
                            <Text>{inform?.cond_normal ? "Sí" : "No"}</Text>
                        </View>
                        <View style={{
                            width: "50px",
                            height: "15px",
                            backgroundColor: '#ffffff',
                            borderRadius: 3,
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginRight: "3px",
                            marginLeft: "2px",
                            marginTop: "3px"
                        }}>
                            <Text>{inform?.cond_obst_grasa ? "Sí" : "No"}</Text>
                        </View>
                        <View style={{
                            width: "50px",
                            height: "15px",
                            backgroundColor: '#ffffff',
                            borderRadius: 3,
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginRight: "3px",
                            marginLeft: "2px",
                            marginTop: "3px"
                        }}>
                            <Text>{inform?.cond_obst_polvo ? "Sí" : "No"}</Text>
                        </View>
                        <View style={{
                            width: "50px",
                            height: "15px",
                            backgroundColor: '#ffffff',
                            borderRadius: 3,
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginRight: "3px",
                            marginLeft: "2px",
                            marginTop: "3px"
                        }}>
                            <Text>{inform?.cond_fugas ? "Sí" : "No"}</Text>
                        </View>
                    </View>
                </View>

                <View style={{
                    display: "flex",
                    flexDirection: 'row',
                    paddingLeft: "5px"
                }}>
                    <View style={{
                        width: "15%",
                        padding: "3px"
                    }}>
                        <Text>Otros:</Text>
                    </View>
                    <View style={{ width: "85%", marginLeft: "5px" }}>
                        <View style={{
                            width: "181px",
                            height: "15px",
                            backgroundColor: '#ffffff',
                            borderRadius: 3,
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginRight: "3px",
                            marginLeft: "2px",
                            marginTop: "3px"
                        }}>
                            <Text>{inform?.cond_otros}</Text>
                        </View>
                    </View>
                </View>

                <Text style={{
                    textAlign: 'center',
                    paddingTop: "5px"
                }} >Evaporador</Text>

                <View style={{
                    display: "flex",
                    flexDirection: 'row',
                    paddingLeft: "5px"
                }}>
                    <View style={{
                        width: "60%",
                        padding: "3px"
                    }}>
                        <Text style={{ marginTop: "3.3px" }}>Normal</Text>
                        <Text style={{ marginTop: "3.3px" }}>Bloqueo</Text>
                        <Text style={{ marginTop: "3.3px" }}>Fugas</Text>
                        <Text style={{ marginTop: "3.3px" }}>Otros</Text>
                        <Text style={{ marginTop: "3.3px" }}>T° Entrega</Text>
                    </View>
                    <View style={{ width: "40%", alignItems: 'center', }}>
                        <View style={{
                            width: "50px",
                            height: "15px",
                            backgroundColor: '#ffffff',
                            borderRadius: 3,
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginRight: "3px",
                            marginLeft: "2px",
                            marginTop: "3px"
                        }}>
                            <Text>{inform?.evap_normal ? "Sí" : "No"}</Text>
                        </View>
                        <View style={{
                            width: "50px",
                            height: "15px",
                            backgroundColor: '#ffffff',
                            borderRadius: 3,
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginRight: "3px",
                            marginLeft: "2px",
                            marginTop: "3px"
                        }}>
                            <Text>{inform?.evap_bloqueado ? "Sí" : "No"}</Text>
                        </View>
                        <View style={{
                            width: "50px",
                            height: "15px",
                            backgroundColor: '#ffffff',
                            borderRadius: 3,
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginRight: "3px",
                            marginLeft: "2px",
                            marginTop: "3px"
                        }}>
                            <Text>{inform?.evap_fugas ? "Sí" : "No"}</Text>
                        </View>
                        <View style={{
                            width: "50px",
                            height: "15px",
                            backgroundColor: '#ffffff',
                            borderRadius: 3,
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginRight: "3px",
                            marginLeft: "2px",
                            marginTop: "3px"
                        }}>
                            <Text>{inform?.evap_otros}</Text>
                        </View>
                        <View style={{
                            width: "50px",
                            height: "15px",
                            backgroundColor: '#ffffff',
                            borderRadius: 3,
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginRight: "3px",
                            marginLeft: "2px",
                            marginTop: "3px"
                        }}>
                            <Text>{inform?.evap_temp_entrada}</Text>
                        </View>
                    </View>
                </View>

                <View style={{
                    display: "flex",
                    flexDirection: 'row',
                    paddingLeft: "10px",
                    paddingTop: "5px"
                }}>
                    <Text>Perdida de Producto:</Text>
                    <View style={{
                        width: "50%",
                        display: "flex",
                        flexDirection: 'row',
                        marginLeft: "5px"
                    }}>
                        <Text>Si:</Text>
                        <View style={{
                            width: "15px",
                            height: "15px",
                            backgroundColor: '#ffffff',
                            borderRadius: 3,
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginRight: "5px",
                            marginLeft: "2px",
                        }}>
                            <Text>{inform?.averias ? "X" : null}</Text>
                        </View>
                        <Text >No:</Text>
                        <View style={{
                            width: "15px",
                            height: "15px",
                            backgroundColor: '#ffffff',
                            borderRadius: 3,
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginRight: "3px",
                            marginLeft: "2px"
                        }}>
                            <Text>{!inform?.averias ? "X" : null}</Text>
                        </View>
                    </View>
                </View>

                <View style={{
                    display: "flex",
                    flexDirection: 'row',
                    paddingLeft: "10px",
                    paddingTop: "3px"
                }}>
                    <View style={{
                        width: "50%",
                        alignItems: 'center',
                        display: "flex",
                        flexDirection: 'row',
                    }}>
                        <Text>Total:</Text>
                        <View style={{
                            width: "50px",
                            height: "15px",
                            backgroundColor: '#ffffff',
                            borderRadius: 3,
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginRight: "3px",
                            marginLeft: "2px",
                        }}>
                            <Text>{inform?.averia_tipo === 1 ? "X" : null}</Text>
                        </View>
                    </View>

                    <View style={{
                        width: "50%",
                        alignItems: 'center',
                        display: "flex",
                        flexDirection: 'row',
                    }}>
                        <Text>Parcial:</Text>
                        <View style={{
                            width: "50px",
                            height: "15px",
                            backgroundColor: '#ffffff',
                            borderRadius: 3,
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginRight: "3px",
                            marginLeft: "2px",
                        }}>
                            <Text>{inform?.averia_tipo === 2 ? "X" : null}</Text>
                        </View>
                    </View>
                </View>

                <View style={{
                    paddingLeft: "10px",
                    paddingTop: "5px",
                    display: "flex",
                    flexDirection: 'row',
                }}>
                    <Text>Unidades:</Text>
                    <View style={{
                        width: "50px",
                        height: "15px",
                        backgroundColor: '#ffffff',
                        borderRadius: 3,
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginRight: "3px",
                        marginLeft: "2px",

                    }}>
                        <Text>{inform?.averia_tipo === 3 ? "X" : null}</Text>
                    </View>
                </View>


            </View>
        </>
    )
}

import React from 'react'
import { Badge, Button, ButtonGroup, Spinner } from "react-bootstrap";
import { faEdit, faExclamationTriangle, faFilePdf, faImage, faTrashAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GeneralDatatable } from "../../General";
import { usePermissions, useTime } from '../../../hooks';
import { useAuth } from '../../../contexts';
import { ELIMINAR_INFORMES } from '../../../utils';

export const InformTable = (props) => {
  const { formatTimeCol } = useTime();
  const { validate } = usePermissions();
  const { user } = useAuth();
  const conditionalStyles = [
    {
      when: row => row?.prioridad === 1 && [0, 1, 3].includes(row?.estado ?? '0'),
      style: {
        backgroundColor: "#CDFBC4",
        color: "black",
      }
    },
    {
      when: row => row?.prioridad === 2 && [0, 1, 3].includes(row?.estado ?? '0'),
      style: {
        backgroundColor: "#FAF1B5",
        color: "black",
      }
    },
    {
      when: row => row?.prioridad === 3 && [0, 1, 3].includes(row?.estado ?? '0'),
      style: {
        backgroundColor: "#FFA2A2",
        color: "black",
      }
    }
  ]
  const columns = [
    {
      id: "id",
      name: "#",
      selector: (row) => row?.id,
      sortable: true,
      width: "70px"
    },
    {
      name: "Fecha",
      sortable: true,
      cell: row => {
        let fecha = `${row?.fecha ?? ""}`;
        let Case01 = () => (<></>);
        switch (row?.estado) {
          case 0:
          case 1:
            Case01 = () => (
              <>
                Reportado el: <br />
                {formatTimeCol(row?.hora_inicio_programada, "YYYY-MM-DD HH:mm:ss") ?? ''}
              </>
            )
            break;

          default:
            break;
        }
        return (
          <>
            {fecha !== "" && (
              <>
                {fecha} <br />
              </>
            )}
            <Case01 />
          </>
        )
      },
      width: "120px"
    },
    {
      name: "Dirección y observaciones",
      sortable: true,
      cell: row => {
        return (
          <>
            {
              row?.direccion && row?.direccion !== "" && (
                <>
                  Dirección: <br />
                  {row?.direccion}<br />
                </>
              )
            }
            {
              row?.estado === 2 ? (
                <>
                  {
                    row?.observaciones && row?.observaciones !== "" && (
                      <>
                        Observaciones: <br />
                        {row?.observaciones}<br />
                      </>
                    )
                  }
                </>
              ) : (
                <>
                  {
                    row?.observaciones_programadas && row?.observaciones_programadas !== "" && (
                      <>
                        Observaciones: <br />
                        {row?.observaciones_programadas}<br />
                      </>
                    )
                  }
                </>
              )
            }

          </>
        )
      },
    },
    {
      name: "Placa y código cliente",
      sortable: true,
      cell: (row) => {
        return (
          <>
            {
              ![0, 1].includes(row?.estado) && (
                <>
                  Placa: <br />{row?.equipo?.placa} <br />
                </>
              )
            }
            Código cliente: <br />{row?.codigo_cliente} <br />
            Teléfono: <br />{row?.telefono} <br />
          </>
        )
      },
    },
    {
      name: "Tecnico o vendedor",
      sortable: true,
      cell: row => {
        return (
          <>
            {row?.vendor_id !== null && (
              <>
                Vendedor: <br />
                {row?.vendor?.nombre} <br />
              </>
            )}
            {row?.user_id !== null && (
              <>
                Técnico: <br />
                {row?.user?.nombre} <br />
              </>
            )}
          </>
        )
      },
      width: "200px"
    },
    {
      name: "Acciones y Estado",
      wrap: true,
      style: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      },
      width: "200px",
      cell: (row) => {
        const averias = row?.main_informe_invs?.filter(val => val?.inv?.tipo === 2);
        let color = "primary";
        let border = "";
        let label = ""
        switch (row.estado) {
          case 0:
            color = "danger";
            border = "danger";
            label = "Servicio solicitado";
            break;
          case 1:
            color = "warning";
            border = "warning";
            label = "Por realizar";
            break;
          case 2:
            color = "success";
            border = "success";
            label = "Realizado";
            break;
          case 3:
            color = "warning";
            border = "danger";
            label = "En seguimiento";
            break;
          case 4:
            color = "primary";
            border = "primary";
            label = "Facturado";
            break;
          case 5:
            color = "info";
            border = "info";
            label = "Por abrobar";
            break;
          default:
            color = "danger";
            border = "warning";
            label = "Estado no válido";
            break;
        }
        return (
          <>
            <>
              <Badge pill bg={color} className={`border border-${border}`} >
                {label}
              </Badge>
            </>
            <>
              <ButtonGroup className='mt-2'>
                {
                  averias?.length > 0 && (
                    <Button
                      size="sm"
                      variant="danger"
                      title='informe con averias'
                    >
                      <FontAwesomeIcon icon={faExclamationTriangle} />
                    </Button>
                  )
                }
                {
                  row?.estado === 0 && row?.foto && (
                    <Button
                      size="sm"
                      variant="info"
                      onClick={(e) => props.showImg(row.id)}
                    >
                      {
                        props?.loadingImg ? (
                          <>
                            <Spinner size='sm' animation="grow" variant="warning" />
                          </>
                        ) : (
                          <>
                            <FontAwesomeIcon icon={faImage} />
                          </>
                        )
                      }
                    </Button>
                  )
                }
                {
                  user?.rol_id === 4 && [2, 4, 5].includes(row?.estado) && (
                    <Button
                      size="sm"
                      variant="outline-danger"
                      onClick={(e) => props.onViewPDF(row.id, false, user?.id)}
                      title="Informe sin valores"
                    >
                      <FontAwesomeIcon icon={faFilePdf} />
                    </Button>
                  )
                }
                {
                  [2, 4, 5].includes(row?.estado) && (
                    <Button
                      size="sm"
                      variant="danger"
                      onClick={(e) => props.onViewPDF(row.id, true, user?.id)}
                      title="Informe con valores"
                    >
                      <FontAwesomeIcon icon={faFilePdf} />
                    </Button>
                  )
                }
                {
                  [1].includes(user?.rol_id) && [0, 1, 3].includes(row?.estado) && (
                    <Button
                      size='sm'
                      variant='primary'
                      onClick={e => props.onChangeTechnical(row.id)}
                      title='Cambiar técnico'
                    >
                      <FontAwesomeIcon icon={faUser} />
                    </Button>
                  )
                }
                {
                  [1, 2].includes(user?.rol_id) && ![4, 5].includes(row?.estado) && (
                    <Button
                      size="sm"
                      variant="warning"
                      onClick={(e) => props.onEdit(row.id)}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </Button>
                  )
                }
                {
                  validate(user, ELIMINAR_INFORMES) && ![4, 5].includes(row?.estado) && (
                    <Button
                      size="sm"
                      variant="danger"
                      onClick={(e) => props.onDelete(row.id)}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                  )
                }
              </ButtonGroup>
            </>
          </>
        );
      },
    },
  ]
  return (
    <>
      <GeneralDatatable
        columns={columns}
        data={props.data}
        customProps={{ defaultSortFieldId: "id", defaultSortAsc: false, ...props }}
        conditionalStyles={conditionalStyles}
        showFilters={false}
      />
    </>
  )
}

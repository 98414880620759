import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth, useAxios } from '../../../contexts';
import { GESTION_BODEGAS } from '../../../utils';
import { useEffect } from 'react';
import { usePermissions } from '../../../hooks';
import { useState } from 'react';
import { Button, Card, Col, Container, Row, Form, Spinner, FormControl, FormSelect } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { Loader } from '../../Loader/Loader';

export const EditStore = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [spinnerLoading,] = useState(false);
  const { validateRoutePermission } = usePermissions();
  const [loading, setLoading] = useState(false);
  const { privateFetch } = useAxios();
  const [store, setStore] = useState([]);
  const [corregimientos, setCorregimientos] = useState([]);

  useEffect(() => {
    if (!id) navigate("/home");
    validateRoutePermission(user, GESTION_BODEGAS);
    getStores();
    // eslint-disable-next-line
  }, [])

  const getStores = async () => {
    setLoading(true);
    const { data } = await privateFetch.get(`api/store/getStores/${id}`);
    const { data: corregimientosDB } = await privateFetch.get("api/location/getCorregimientos");
    console.log(data);
    setStore(data.store);
    setCorregimientos(corregimientosDB.corregimiento);
    setLoading(false)
  }
  return (
    <>
      <Container fluid className='mt-2'>
        <Card>
          <Card.Header>Editar Bodega</Card.Header>
          {!loading ? (
            <Form autoComplete='off'>
              <Card.Body>
                <Row>
                  <Col sm>
                    <label>Codigo</label>
                    <FormControl type='text' step="any" size="sm" required name='codigo' defaultValue={store?.codigo} />
                  </Col>
                  <Col sm>
                    <label>Nombre</label>
                    <FormControl type='text' step="any" size="sm" required name='nombre' defaultValue={store?.nombre} />
                  </Col>
                  <Col sm>
                    <label>Cuidad</label>
                    <FormSelect defaultValue={store?.ciudad_id} size="sm" name="ciudad_id" required >
                      <option value="">Seleccione</option>
                      {corregimientos.map((val, id) => (
                        <option key={id} value={val.id}>
                          {val?.nombre}
                        </option>
                      ))}
                    </FormSelect>
                  </Col>
                  <Col sm='auto'>
                    <label>Estado</label>
                    <FormSelect size="sm" required name="estado" defaultValue={store?.estado}>
                      <option value="">Seleccione</option>
                      {[{ id: true, nombre: "Activo" }, { id: false, nombre: "Inactivo" }].map((val, id) => (
                        <option key={id} value={val.id}>
                          {val.nombre}
                        </option>
                      ))}
                    </FormSelect>
                  </Col>

                </Row>
              </Card.Body>
              <Card.Footer>
                <Button type='submit' size='sm' variant='primary'>
                  {
                    spinnerLoading ? (
                      <>
                        <Spinner animation="grow" variant="warning" />
                      </>
                    ) : (
                      <>
                        <FontAwesomeIcon icon={faSave} /> &nbsp;
                        Guardar
                      </>
                    )
                  }
                </Button>
              </Card.Footer>
            </Form>
          ) : (
            <Loader />
          )}
        </Card>
      </Container >
    </>
  )
}


import React from "react";
import { Button, ButtonGroup, Badge } from "react-bootstrap";
import { GeneralDatatable } from "../../General";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { usePermissions } from "../../../hooks";
import { useAuth } from "../../../contexts";
import { TODAS_CC } from "../../../utils";

export const CAccountsTable = (props) => {
    const { user } = useAuth();
    const { validate } = usePermissions();
    const columns = [
        {
            id: "id",
            name: "Número",
            selector: (row) => row.id,
            sortable: true,
        },
        ...(validate(user, TODAS_CC) ? [{
            name: "Técnico",
            selector: (row) => row?.user?.nombre,
            sortable: true,
        }] : []),
        {
            name: "Fecha Inicial",
            selector: (row) => row.desde,
            sortable: true,
        },
        {
            name: "Fecha Fin",
            selector: (row) => row.hasta,
            sortable: true,
        },
        {
            name: "Fecha",
            selector: (row) => row.fecha,
            sortable: true,
        },
        {
            name: "Total",
            selector: (row) => row.valor,
            sortable: true,
        },
        {
            name: "Estado",
            sortable: true,
            cell: (row) => {
                let color = "primary";
                let border = "";
                let label = ""
                switch (row.estado) {
                    case 1:
                        color = "primary";
                        border = "primary";
                        label = "Generada";
                        break;
                    case 2:
                        color = "success";
                        border = "success";
                        label = "Aprobada";
                        break;
                    case 3:
                        color = "warning";
                        border = "warning";
                        label = "Pagada";
                        break;
                    default:
                        color = "danger";
                        border = "warning";
                        label = "Error";
                        break;
                }
                return (
                    <Badge pill bg={color} className={`border border-${border}`} >
                        {label}
                    </Badge>
                );
            },
        },
        {
            name: "Acciones",
            button: true,
            cell: (row) => {
                return (
                    <>
                        <ButtonGroup aria-label="Basic example">
                            <Button
                                size="sm"
                                variant="warning"
                                onClick={(e) => props.onEdit(row.id)}
                            >
                                <FontAwesomeIcon icon={faEdit} />
                            </Button>
                            <Button
                                size="sm"
                                variant="danger"
                                onClick={(e) => props.onDelete(row.id)}
                            >
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </Button>
                        </ButtonGroup>
                    </>
                );
            },
        },
    ];
    return (
        <GeneralDatatable
            columns={columns}
            data={props.data}
            customProps={{ defaultSortFieldId: "id", defaultSortAsc: false }}
        />
    )
}

import PropTypes from 'prop-types';

export const StepperFooter = ({
	isPrevBtn,
	previousStepHandler,
	isLastStep,
	nextStepHandler,
	submitHandler,
	stepperContent,
	currentTabIndex,
}) => {
	const submitCurrentStep = async () => {
		await stepperContent[currentTabIndex].clicked();
		nextStepHandler();
	};

	return (
		<div
			className="stepper-footer"
			style={{ justifyContent: isPrevBtn ? 'space-between' : 'flex-end' }}
		>
			{isPrevBtn && (
				<button type='button' className="stepper-footer-btn" onClick={previousStepHandler}>
					Volver a {stepperContent[currentTabIndex - 1].label}
				</button>
			)}
			<button
				type='button'
				className={`stepper-footer-btn ${isLastStep ? 'success' : 'primary'}`}
				onClick={
					isLastStep
						? submitHandler
						: stepperContent[currentTabIndex].clicked
							? submitCurrentStep
							: nextStepHandler
				}
				disabled={
					(isLastStep
						? stepperContent.some((el) => !el.isComplete)
						: !stepperContent[currentTabIndex].isComplete) ||
					stepperContent[currentTabIndex].isLoading
				}
			>
				{isLastStep ? 'Guardar' : `Continuar a ${stepperContent[currentTabIndex + 1].label}`}
			</button>
		</div>
	);
};

StepperFooter.propTypes = {
	isPrevBtn: PropTypes.bool,
	previousStepHandler: PropTypes.func.isRequired,
	isLastStep: PropTypes.bool,
	nextStepHandler: PropTypes.func.isRequired,
	submitHandler: PropTypes.func.isRequired,
	currentTabIndex: PropTypes.number.isRequired,
	stepperContent: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			content: PropTypes.node.isRequired,
			clicked: PropTypes.func,
			isWarning: PropTypes.bool,
			isError: PropTypes.bool,
			isComplete: PropTypes.bool,
			isLoading: PropTypes.bool,
		})
	),
};

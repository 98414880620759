import React, { useState, useEffect } from 'react'
import { useNotify, useAlert, useForm, usePermissions } from '../../../hooks';
import { useAuth, useAxios } from '../../../contexts';
import { ClientesTable } from '../../../components/controllers/clientes/ClientesTable';
import { Row, Col, Button, Modal, Form, FormControl } from "react-bootstrap";
import { faPlus, faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Loader } from '../../../components';
import { GESTION_CLIENTES } from '../../../utils';
import { useNavigate } from 'react-router-dom';

export const ManageClientes = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { privateFetch } = useAxios();
  const { Alert } = useAlert();
  const { notify, DelAlert } = useNotify();
  const [loading, setLoading] = useState(false);
  const [cliente, setCliente] = useState([]);
  const [showAddClient, setShowAddClient] = useState(false);
  const handleShowCreate = () => setShowAddClient(true);
  const handleCloseCreate = () => setShowAddClient(false);
  const { serialize } = useForm();
  const { validateRoutePermission } = usePermissions();
  useEffect(() => {
    validateRoutePermission(user, GESTION_CLIENTES);
    document.title = "Clientes";
    getClientes();
    return () => {
      setCliente([]);
    }
    // eslint-disable-next-line
  }, [])
  const getClientes = async () => {
    setLoading(true);
    const { data: clients } = await privateFetch.get(`/api/clients/getClients`);
    setCliente(clients.clients)
    setLoading(false);
  }
  const guardarCliente = async (ev) => {
    ev.preventDefault();
    const formData = serialize(ev.target);
    const { data } = await privateFetch.post(
      "/api/clients/guardarCliente",
      formData
    );
    notify({
      ...data,
      callback: () => {
        ev.target.reset();
      },
    });
    getClientes();
    setShowAddClient(false);
    setLoading(false);
  }
  const editClient = (id) => {
    navigate("/clients/edit-client/" + id)
  }
  const deleteClient = async (id) => {
    await DelAlert({
      text: "Desea eliminar el cliente?",
      onConfirm: async () => {
        const { data } = await privateFetch.delete(
          "/api/clients/deleteClient/" + id
        );
        notify(data);
        getClientes();
      },
      onCancel: () => {
        Alert({
          type: "warning",
          text: "Eliminación cancelada",
        });
      },
    });
  }
  return loading ? (
    <Loader />
  ) : (
    <>
      <Row className="mb-2">
      <Col sm="auto">
          <Button size="sm" variant="primary" onClick={getClientes}>
            <FontAwesomeIcon icon={faSync} />
          </Button>
        </Col>
        <Col sm="auto">
          <Button size="sm" variant="primary" onClick={handleShowCreate}>
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </Col>
      </Row>
      <ClientesTable data={cliente} onEdit={editClient} onDelete={deleteClient} />
      <Modal show={showAddClient} onHide={handleCloseCreate}>
        <Modal.Header closeButton>
          <Modal.Title>Nuevo Cliente</Modal.Title>
        </Modal.Header>
        {!loading ? (
          <Form onSubmit={guardarCliente} autoComplete='off'>
            <Modal.Body>
              <Row>
                <Col sm>
                  <label>Razón social</label>
                  <FormControl size="sm" type="text" name="nombre" required />
                </Col>
              </Row>
              <Row>
                <Col sm>
                  <label>Nombre responsable</label>
                  <FormControl size="sm" type="text" name="nombre_responsable" required />
                </Col>
              </Row>
              <Row>
                <Col sm>
                  <label>NIT</label>
                  <FormControl size="sm" type="text" name="nit" required />
                </Col>
              </Row>
              <Row>
                <Col sm>
                  <label>Teléfono</label>
                  <FormControl size="sm" type="text" name="telefono" required />
                </Col>
              </Row>
              <Row>
                <Col sm>
                  <label>Correo electrónico</label>
                  <FormControl size="sm" type="email" name="correo" required />
                </Col>
              </Row>
              <Row>
                <Col sm>
                  <label>Correo electrónico alternativo</label>
                  <FormControl size="sm" type="email" name="correo_alternativo" />
                </Col>
              </Row>
              <Row>
                <Col sm>
                  <label>Dirección</label>
                  <FormControl
                    as="textarea"
                    size="sm"
                    type="text"
                    name="direccion"
                    required
                  />
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button size="sm" variant="secondary" onClick={handleCloseCreate}>
                Cerrar
              </Button>
              <Button type="submit" size="sm" variant="primary">
                Guardar
              </Button>
            </Modal.Footer>
          </Form>
        ) : (
          <Loader />
        )}
      </Modal>
    </>
  )
}

import React, { useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import { EditStore } from './EditStore';
import { EditStoreEquipment } from './EditStoreEquipment';


export const EditTabsStore = () => {
    const [key, setKey] = useState('info');

    return (
        <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3 mt-4"
        >
            <Tab eventKey="info" title="Informacion">
                <EditStore />
            </Tab>
            <Tab eventKey="equipment" title="Equipos">
                <EditStoreEquipment />
            </Tab>
        </Tabs>
    )
}

import React, { useEffect, useState } from 'react'
import { useAuth, useAxios } from '../../../contexts'
import { CAccountsTable, Loader } from '../../../components';
import { Row, Col, Button, Modal, Form, FormControl, Spinner, FormSelect } from "react-bootstrap";
import { faPlus, faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAlert, useForm, useNotify, usePermissions, useTime } from '../../../hooks';
import { TODAS_CC, VER_CC } from '../../../utils';
import { useNavigate } from "react-router-dom";

//Mismo controlador de facturas. Duplicado con data distinta
export const CAccounts = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [loadingInv, setLoadingInv] = useState(false);
  const [cc, setCC] = useState([]);
  const [users, setUsers] = useState([]);
  const { privateFetch } = useAxios();
  const [showAddCC, setShowAddCC] = useState(false);
  const handleShowAddCC = () => setShowAddCC(true);
  const handleCloseAddCC = () => setShowAddCC(false);
  const { serialize } = useForm();
  const { actualTimeCol } = useTime();
  const { validateRoutePermission, validate } = usePermissions();
  const { notify, DelAlert } = useNotify();
  const { Alert } = useAlert();
  const navigate = useNavigate();
  useEffect(() => {
    validateRoutePermission(user, VER_CC)
    document.title = "Cuentas de cobro";
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const getData = async () => {
    setLoading(true);
    const { data } = await privateFetch.get("/api/caccounts/getCC");
    const { data: users } = await privateFetch.post("/api/users/getUsersFiltered", { rol_id: 2 });
    setUsers(users?.users);
    setCC(data?.cc);
    setLoading(false);
  }
  const generateCC = async (ev) => {
    ev.preventDefault();
    const formData = serialize(ev.target);
    setLoadingInv(true);
    const { data } = await privateFetch.post("/api/caccounts/generateCC", formData);
    notify({
      ...data,
      callback: () => {
        ev.target.reset();
      },
    });
    getData();
    handleCloseAddCC();
    setLoading(false);
    setLoadingInv(false);
  }
  const editCC = (id) => {
    navigate("/caccount/edit-cc/" + id)
  }
  const deleteCC = async (id) => {
    await DelAlert({
      text: "Desea eliminar este elemento?",
      onConfirm: async () => {
        const { data } = await privateFetch.delete(
          "/api/caccounts/deleteCC/" + id
        );
        notify(data);
        getData();
      },
      onCancel: () => {
        Alert({
          type: "warning",
          text: "Eliminación cancelada",
        });
      },
    });
  }
  return loading ? (
    <Loader />
  ) : (
    <>
      <Row className="mb-2">
        <Col sm="auto">
          <Button size="sm" variant="primary" onClick={getData}>
            <FontAwesomeIcon icon={faSync} />
          </Button>
        </Col>
        <Col sm="auto">
          <Button size="sm" variant="primary" onClick={handleShowAddCC}>
            Generar Cuenta de cobro &nbsp;
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </Col>
      </Row>
      <CAccountsTable data={cc} onEdit={editCC} onDelete={deleteCC} />
      <Modal show={showAddCC} onHide={handleCloseAddCC}>
        <Modal.Header closeButton>
          <Modal.Title>Generar Factura</Modal.Title>
        </Modal.Header>
        <Form autoComplete='off' onSubmit={generateCC}>
          <Modal.Body>
            <Row>
              <Col sm>
                <label>Desde:</label>
                <FormControl type='date' size="sm" name="desde" required max={actualTimeCol("YYYY-MM-DD")} />
              </Col>
            </Row>
            <Row>
              <Col sm>
                <label>Hasta:</label>
                <FormControl type='date' size="sm" name="hasta" required max={actualTimeCol("YYYY-MM-DD")} />
              </Col>
            </Row>
            {
              validate(user, TODAS_CC) ? (
                <>
                  <Row>
                    <Col sm>
                      <label>Usuario</label>
                      <FormSelect name="user_id" required size="sm">
                        <option value="">Seleccione</option>
                        {
                          users?.map((val) => (
                            <option key={val?.id} value={val?.id}>{val?.nombre}</option>
                          ))
                        }
                      </FormSelect>
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <input type='number' hidden name='user_id' defaultValue={user?.id} />
                </>
              )
            }
          </Modal.Body>
          <Modal.Footer>
            <Button size="sm" variant="secondary" onClick={handleCloseAddCC}>
              Cerrar
            </Button>
            <Button size="sm" variant="primary" type='submit' disabled={loadingInv}>
              {
                loadingInv ? (
                  <>
                    <Spinner size='sm' variant="warning" animation="grow" /> Generando cuenta de cobro ...
                  </>
                ) : (
                  <>
                    Generar
                  </>
                )
              }
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

import React from 'react'
import { Col, Form, FormControl, FormSelect, Row } from 'react-bootstrap'

export const SelectorChecks = ({ data, index, setFields }) => {
    const changeValue = (e) => {
        setFields(prev => {
            const data = [...prev];
            data[index].value = (data[index]?.type === "boolean" ? Boolean(e.target?.value === "true" ? 1 : 0) : e.target?.value);
            return data;
        })
    }
    const changevalueText = (e, indexI) => {
        setFields(prev => {
            const data = [...prev];
            data[index].inputs[indexI].value = e.target?.value;
            return data;
        })
    }
    const changeValueCheckBox = (ev, indexI) => {
        setFields(prev => {
            const data = [...prev];
            data[index].inputs[indexI].value = ev.target?.checked;
            return data;
        })
    } 
    return (
        <Row>
            {
                (data?.type === "boolean" || data?.type === "selector") && (
                    <>
                        <Col sm>{data?.label} {data?.required && (<><span className='text-danger'>*</span></>)}</Col>
                        <Col sm>
                            <FormSelect size="sm" name={data?.name} onChange={changeValue} required={data?.required ?? true} defaultValue={data?.value}>
                                <option value=""></option>
                                {
                                    data?.options?.map((val, indexS) => (
                                        <option key={indexS} value={val.id}>{val?.nombre}</option>
                                    ))
                                }
                            </FormSelect>
                        </Col>
                    </>
                )
            }
            {
                data?.type === "text" && (
                    <>
                        {
                            data?.label !== "" && (
                                <Col sm>{data?.label}</Col>
                            )
                        }
                        {
                            data?.inputs?.map((val, indexI) => (
                                <React.Fragment key={indexI}>
                                    <Col sm>{val?.label} {val?.required && (<><span className='text-danger'>*</span></>)}</Col>
                                    <Col sm>
                                        <FormControl {...val?.props ? val?.props : {}} onChange={e => changevalueText(e, indexI)} key={index} type={val?.type} size="sm" name={val?.name} required={val?.required} defaultValue={val?.value} />
                                    </Col>
                                </React.Fragment>
                            ))
                        }
                    </>
                )
            }
            {
                data?.type === "checkbox" && (
                    <>
                        {
                            data?.label !== "" && (
                                <Col sm>{data?.label}</Col>
                            )
                        }
                        {
                            data?.inputs?.map((val, indexI) => (
                                <React.Fragment key={indexI}>
                                    <Col sm>{val?.label} {val?.required && (<><span className='text-danger'>*</span></>)}</Col>
                                    <Col sm>
                                        <Form.Check {...val?.props ? val?.props : {}} onChange={e => changeValueCheckBox(e, indexI)} key={index} size="sm" name={val?.name} required={val?.required} defaultValue={val?.value} />
                                    </Col>
                                </React.Fragment>
                            ))
                        }
                    </>
                )
            }
        </Row>
    )
}

import React, { useMemo, useState } from "react";
import { Filter } from "./Filter";
import DataTable from "react-data-table-component";
import { Col, Row } from "react-bootstrap";

export const GeneralDatatable = ({ showFilters = true, columns, data, customProps, conditionalStyles = [] }) => {
  const [filterText, setFilterText] = useState("");
  const [filterId, setFilterTextId] = useState("");
  const [resetPageToggle, setResetPageToggle] = useState(false);
  const filteredItems = data.filter(
    (item) => {
      if (filterId === "") return JSON.stringify(item).toLowerCase().includes(filterText.toLowerCase());
      return item?.id?.toString() === filterId;
    }
  );
  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPageToggle(!resetPageToggle);
        setFilterText("");
      }
    };
    const handleClearId = () => {
      if (filterId) {
        setResetPageToggle(!resetPageToggle);
        setFilterTextId("");
      }
    }
    return (
      <Row className="justify-content-around">
        <Col sm="auto">
          <Filter
            onFilter={(e) => setFilterTextId(e.target.value)}
            onClear={handleClearId}
            filterText={filterId}
            placeholder={"# Id"}
          />
        </Col>
        <Col sm="auto">
          <Filter
            onFilter={(e) => setFilterText(e.target.value)}
            onClear={handleClear}
            filterText={filterText}
            placeholder={"Buscar..."}
          />
        </Col>
      </Row>
    );
  }, [filterText, resetPageToggle, filterId]);
  const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  };
  return (
    <DataTable
      responsive
      columns={columns}
      data={filteredItems}
      striped
      pagination
      paginationComponentOptions={paginationComponentOptions}
      paginationPerPage={20}
      subHeader
      {...(showFilters && ({ subHeaderComponent }))}
      conditionalRowStyles={conditionalStyles}
      {...customProps}
    />
  );
};

import React, { useState } from 'react'
import { useEffect } from 'react';
import { Button, Card, Col, Container, FormControl, Row, Form, FormSelect, Spinner, Table } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, useNotify, usePermissions } from '../../../hooks';
import { useAuth, useAxios } from '../../../contexts';
import { Loader } from '../../Loader/Loader';
import { faPlus, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GESTION_DEPARTAMENTOS_CIUDADES } from '../../../utils';
import { EditCorregimiento } from './EditCorregimiento';
import { useRef } from 'react';


export const EditCity = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState();
  const [spinnerLoading, setSpinnerLoading] = useState(false);
  const { validateRoutePermission } = usePermissions();
  const [departamentos, setDepartamentos] = useState([]);
  const [cities, setCities] = useState({});
  const [corregimientos, setCorregimientos] = useState([]);
  const { privateFetch } = useAxios();
  const { serialize } = useForm();
  const { notify } = useNotify();
  const nombreC = useRef();
  const valueC = useRef();


  useEffect(() => {
    if (!id) navigate("/home");
    validateRoutePermission(user, GESTION_DEPARTAMENTOS_CIUDADES);
    getCities();
    // eslint-disable-next-line
  }, [])

  const getCities = async () => {
    setLoading(true);
    const { data: cities } = await privateFetch.get(`/api/location/getCities/${id}`);
    const { data: deptos } = await privateFetch.get("/api/location/getDepartments");
    setCities(cities.cities);
    setCorregimientos(cities?.cities?.main_corregimientos);
    setDepartamentos(deptos.states);
    setLoading(false);
  }

  const editCity = async (ev) => {
    ev.preventDefault();
    setSpinnerLoading(true);
    const formData = {
      ...serialize(ev.target),
      corregimientos
    };
    const { data } = await privateFetch.put("api/location/editCity/" + id, formData);
    notify({
      ...data
    })
    setSpinnerLoading(false)
  }

  const AddCorregimiento = () => {
    const { current: nombre } = nombreC;
    const { current: valor } = valueC;
    if (!nombre?.value || !valor?.value) return;
    const data = [
      ...corregimientos, {
        id: null,
        nombre: nombre?.value,
        locked: false,
        is_city: false,
        service_value: valor?.value ?? 0
      }
    ]
    nombre.value = "";
    valor.value = "";
    setCorregimientos(data)
  }

  return (
    <>
      <Container fluid className='mt-2'>
        <Card>
          <Card.Header>Editar Ciudad</Card.Header>
          {!loading ? (
            <Form onSubmit={editCity} autoComplete='off'>
              <Card.Body>
                <Row>
                  <Col sm="auto">
                    <label>Nombre</label>
                    <FormControl size="sm" required name='nombre' defaultValue={cities?.nombre} />
                  </Col>
                  <Col sm={3}>
                    <label>Departamento</label>
                    <FormSelect size="sm" required name="departamento_id" defaultValue={cities?.departamento_id} >
                      <option value="">Seleccione</option>
                      {
                        departamentos.map((val, id) => (
                          <option key={id} value={val.id}>{val.nombre}</option>
                        ))
                      }
                    </FormSelect>
                  </Col>
                  <Col sm="auto">
                    <label>Codigo</label>
                    <FormControl type='text' size="sm" required name='codigo' defaultValue={cities?.codigo} />
                  </Col>
                </Row>
                <Row className='my-3'>
                  <Col sm="auto">
                    <label>Corregimientos</label>
                  </Col>
                </Row>
                <Row>
                  <Col sm="auto">
                    <FormControl size="sm" type="text" ref={nombreC} placeholder='Nombre' />
                  </Col>
                  <Col sm="auto">
                    <FormControl size="sm" type="number" step="any" ref={valueC}  placeholder='Valor' />
                  </Col>
                  <Col sm="auto">
                    <Button size="sm" variant="success" onClick={AddCorregimiento}>
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  </Col>
                </Row>
                <Row className='mt-3'>
                  <Col sm={10}>
                    <Table striped bordered hover responsive>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Nombre</th>
                          <th>Valor</th>
                          <th>Validaciones</th>
                          <th>Acciones</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          corregimientos?.map((val, key) => (
                            <EditCorregimiento key={key} index={key} data={val} setData={setCorregimientos} />
                          ))
                        }
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <Button type='submit' size='sm' variant='primary'>
                  {
                    spinnerLoading ? (
                      <>
                        <Spinner animation="grow" variant="warning" />
                      </>
                    ) : (
                      <>
                        <FontAwesomeIcon icon={faSave} /> &nbsp;
                        Guardar
                      </>
                    )
                  }
                </Button>
              </Card.Footer>
            </Form>
          ) : (
            <Loader />
          )}
        </Card>
      </Container>
    </>
  )
}

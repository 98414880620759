import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from "react-router-dom";
import { Loader } from '../../../Loader';
import { useAuth, useAxios } from '../../../../contexts';
import { PDFViewer, Document, Page, View } from "@react-pdf/renderer"
import { Header } from './Header';
import { Checks1 } from './Checks1';
import { Checks2 } from './Checks2';
import { Checks3 } from './Checks3';
import { ProceduresTable } from './ProceduresTable';
import { ObservationsField } from './ObservationsField';
import { RepuestosTable } from './RepuestosTable';
import { ProductsTable } from './ProductsTable';
import { PlateImage } from './PlateImage';

export const PDFInform = () => {
    const { user } = useAuth();
    const { id } = useParams();
    const [querys] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const [inform, setInform] = useState({});
    const [img2Show, setImg2Show] = useState(null);
    const { privateFetch } = useAxios();

    useEffect(() => {
        document.title = "Informe técnico PDF";
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const getData = async () => {
        setLoading(true);
        try {
            const { data: informS } = await privateFetch.get("/api/informs/getInforms/" + id);
            setInform(informS.informe)
            const { data, status } = await privateFetch.get(informS.informe?.foto_placa, {
                responseType: 'blob',
            });
            if (status === 200) {
                const result = await new Promise((resolve, reject) => {
                    const reader = new window.FileReader();
                    reader.readAsDataURL(data);
                    reader.onload = () => {
                        resolve(reader.result);
                    }
                })
                setImg2Show(result);
            }
        } catch (error) {
            console.log(error)
            console.log("Error al cargar la imagen");
        }
        setLoading(false);
    }
    const validatePermission = () => {
        return [1, 4].includes(user?.rol_id) && (querys?.get("showValues") === "1" && user?.id === parseInt(querys?.get("user_id") ?? "0"))
    }
    return loading ? (
        <Loader />
    ) : (
        <>
            <PDFViewer style={{ width: "100%", height: "100vh" }}>
                <Document>
                    <Page size="A4" style={{
                        fontSize: ""
                    }}>
                        <Header inform={inform} />
                        <Checks1 inform={inform} />
                        <View style={{
                            paddingLeft: "20px",
                            paddingRight: "20px",
                            paddingTop: "8px",
                            display: "flex",
                            flexDirection: 'row',
                            justifyContent: 'space-between'
                        }}>
                            <Checks2 inform={inform} />
                            <Checks3 inform={inform} />
                        </View>
                        <ObservationsField querys={validatePermission} inform={inform} user={user} />
                        <ProceduresTable querys={validatePermission} procedures={inform?.main_informe_procs ?? []} user={user} />
                        <RepuestosTable querys={validatePermission} repuestos={inform?.main_informe_invs?.filter(val => val?.inv?.tipo === 1) ?? []} user={user} />
                        <ProductsTable products={inform?.main_informe_invs?.filter(val => val?.inv?.tipo === 2) ?? []} />
                        {
                            img2Show && (
                                <PlateImage src={img2Show} />
                            )
                        }
                    </Page>
                </Document >
            </PDFViewer>
        </>
    )
}
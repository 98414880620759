import React from "react";
import { Badge, Button, ButtonGroup } from "react-bootstrap";
import { GeneralDatatable } from "../../General";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

export const UsersTable = (props) => {
  const columns = [
    {
      id: "id",
      name: "Número",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Cédula",
      selector: (row) => row.cifnif,
      sortable: true,
    },
    {
      name: "Nombre",
      selector: (row) => row.nombre,
      sortable: true,
    },
    {
      name: "Apellidos",
      selector: (row) => row.apellido,
      sortable: true,
    },
    {
      name: "Correo electrónico",
      selector: (row) => row.correo,
      sortable: true,
    },
    {
      name: "Rol",
      button: true,
      cell: (row) => {
        let color = "info";
        switch (row?.rol?.id) {
          case 2:
            color = "warning";
            break;
          case 3:
            color = "info";
            break;
          case 4:
            color = "success";
            break;
          default:
            color = "primary";
            break;
        }
        return (
          <Badge pill bg={color}>
            {row?.rol?.nombre}
          </Badge>
        );
      },
    },
    {
      name: "Acciones",
      button: true,
      cell: (row) => {
        return (
          <>
            <ButtonGroup aria-label="Basic example">
              <Button
                size="sm"
                variant="warning"
                onClick={(e) => props.onEdit(row.id)}
              >
                <FontAwesomeIcon icon={faEdit} />
              </Button>

              <Button
                size="sm"
                variant="danger"
                onClick={(e) => props.onDelete(row.id)}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </Button>
            </ButtonGroup>
          </>
        );
      },
    },
  ];
  return (
    <GeneralDatatable
      columns={columns}
      data={props.data}
      customProps={{ defaultSortFieldId: "id", defaultSortAsc: false }}
    />
  );
};

import React from 'react'
import { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { ManageCiudades, ManageDptos } from '../../../components/controllers/departamentos_ciudades';

export const DepartamentosCiudades = () => {
    const [key, setKey] = useState('ciudades');
    const [reload, setReload] = useState(false);

    return (
        <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3 mt-4"
        >
            <Tab eventKey="departamento" title="Departamento">
                <ManageDptos reload={setReload}/>
            </Tab>
            <Tab eventKey="ciudades" title="Ciudades">
                <ManageCiudades reload={reload}/>
            </Tab>
        </Tabs>
    )
}

import React from 'react';
import { Image, Text, View } from "@react-pdf/renderer";
import image from "../../../../assets/img/logoSC.png";

export const Header = ({ inform }) => {
    return (
        <>
            <View className="header" style={{
                width: "100%",
                padding: "20px",
                display: "flex",
                flexDirection: 'row',
                justifyContent: 'space-around',
            }} >
                <View className="logo" >
                    <Image style={{
                        width: "80px",
                        height: "70px"
                    }} src={image ?? ""} />
                </View>
                <View className="info" style={{
                    textAlign: "center",
                    alignItems: "center"
                }}>
                    <Text>Nit. 900.742.747-1</Text>
                    <Text>Calle 70# 11w-44</Text>
                    <Text>kilómetro 3 via Girón</Text>
                    <Text>Bucaramanga, Santander</Text>
                    <Text>Cel 311 217 2645 - 322 580 7262</Text>
                </View>
                <View className="orden" style={{
                    paddingTop: "10px"
                }}>
                    <Text>Orden de pedido</Text>
                    <View style={{
                        minWidth: "80px",
                        height: "20px",
                        backgroundColor: '#f0f0f0',
                        borderRadius: 3,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <Text>{inform?.id}</Text>
                    </View>
                </View>
            </View >
        </>
    )
}

import { faCheck, faLock, faSave, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { useRef } from 'react'
import { Button, Form, InputGroup } from 'react-bootstrap'

export const EditCorregimiento = ({ index, data, setData }) => {
    const [corregimiento,] = useState(data);
    const [show, setShow] = useState(false);
    const [showV, setShowV] = useState(false);
    const edit = useRef();
    const value = useRef();
    const seeChanges = (e) => {
        if (e.target?.type === "text" && corregimiento?.nombre !== e.target?.value) return setShow(true);
        if (corregimiento?.service_value !== e.target?.value) return setShowV(true);
        setShow(false)
        return setShowV(false)
    }
    const saveChanges = () => {
        setData(prev => {
            const data = [...prev]
            data[index].nombre = edit?.current?.value;
            data[index].service_value = value?.current?.value;
            return data;
        })
        setShow(false)
        setShowV(false)
    }
    const deleteItem = (key) => {
        setData(prev => {
            let data = [...prev];
            data = data.filter((val, keyA) => keyA !== key);
            return data;
        })
    }

    return (
        <tr>
            <td>
                {index + 1}
            </td>
            <td>
                <InputGroup size='sm' className="mb-3">
                    <Form.Control
                        ref={edit}
                        defaultValue={data?.nombre}
                        onChange={seeChanges}
                        placeholder="Nombre"
                    />
                    {
                        show && (
                            <Button variant="outline-success" onClick={saveChanges}>
                                <FontAwesomeIcon icon={faSave} />
                            </Button>
                        )
                    }
                </InputGroup>

            </td>
            <td>
                <InputGroup size='sm' className="mb-3">
                    <Form.Control
                        ref={value}
                        type='number'
                        step="any"
                        defaultValue={data?.service_value}
                        onChange={seeChanges}
                        placeholder="Valor"
                    />
                    {
                        showV && (
                            <Button variant="outline-success" onClick={saveChanges}>
                                <FontAwesomeIcon icon={faSave} />
                            </Button>
                        )
                    }
                </InputGroup>
            </td>
            <td>
                {
                    data?.locked && (
                        <FontAwesomeIcon className='mx-2 text-primary' icon={faLock} />
                    )
                }
                {
                    data?.is_city && (
                        <FontAwesomeIcon className='text-success' icon={faCheck} />
                    )
                }
            </td>
            <td>
                {
                    !data?.locked && (
                        <Button variant='danger' size="sm" onClick={e => deleteItem(index)}>
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </Button>
                    )
                }
            </td>

        </tr>
    )
}

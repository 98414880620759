import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef } from 'react'
import { Button, Col, FormControl, Row, Table } from 'react-bootstrap'

export const EditInfCorreos = ({ correos, setEmails, emails }) => {
    const nombre = useRef();
    const correo = useRef();
    useEffect(() => {
        setEmails(correos);
        // eslint-disable-next-line
    }, []);
    const AddEmail = () => {
        const { current: name } = nombre;
        const { current: correoE } = correo;
        if (!name?.value || !correoE?.value) return;
        const mail = [
            ...emails, {
                id: null,
                nombre: name?.value,
                correo: correoE?.value
            }
        ]
        name.value = "";
        correoE.value = "";
        setEmails(mail);
    }
    const deleteItem = (key) => {
        const mails = emails.filter((val, keyA) => keyA !== key);
        setEmails(mails);
    }

    return (
        <>
            <Row className='my-3'>
                <Col sm={6}>
                    Correos informativos
                </Col>
            </Row>
            <Row className='my-3'>
                <Col sm="auto">
                    <label>Nombre</label>
                    <FormControl size="sm" ref={nombre} />
                </Col>
                <Col sm="auto">
                    <label>Correo</label>
                    <FormControl size="sm" ref={correo} />
                </Col>
                <Col sm="auto">
                    <br />
                    <Button size="sm" variant="success" onClick={AddEmail}>
                        <FontAwesomeIcon icon={faPlus} />
                    </Button>
                </Col>
            </Row>
            <Row className='my-3'>
                <Col sm={6}>
                    <Table striped bordered hover responsive>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Nombre</th>
                                <th>Correo</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                emails?.map((val, key) => (
                                    <tr key={key}>
                                        <td>
                                            {key + 1}
                                        </td>
                                        <td>
                                            {val?.nombre}
                                        </td>
                                        <td>
                                            {val?.correo}
                                        </td>
                                        <td>
                                            <Button variant='danger' size="sm" onClick={e => deleteItem(key)}>
                                                <FontAwesomeIcon icon={faTrashAlt} />
                                            </Button>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </>
    )
}

export const EDITAR_REPORTES = 1;
export const CONSULTAR_ESTADO_SERVICIOS = 2;
export const CONSULTAR_AVERIAS_PROD_COLOMBINA = 3;
export const GENERAR_PREFACTURASE = 4;
export const SUBIR_REPORTE_TECNICO = 5;
export const SUBIR_REPORTE_PRODUCTOS_DANADOS = 6;
export const GENERAR_CUENTA_COBRO = 7;
export const CONSULTAR_INV_REPUSTOS = 8;
export const CONSULTAR_PREFACTURA = 9;
export const GESTION_USUARIOS = 10;
export const PANEL_ADMIN = 11;
export const GESTION_CLIENTES = 12;
export const GESTION_INVENTARIO = 13;
export const GESTION_ASIGNACIONES_INVENTARIO = 14;
export const GESTION_DEPARTAMENTOS_CIUDADES = 15;
export const GESTION_PROCEDIMIENTOS = 16;
export const GESTION_EQUIPOS = 17;
export const BOTON_INFORME = 18;
export const TODOS_INFORMES = 19;
export const CREAR_INFORME = 20;
export const EDITAR_INFORME = 21;
export const GESTION_BODEGAS = 22;
export const ELIMINAR_INFORMES = 23;
export const VALORES_INFORME = 24;
export const EDITAR_VALORES = 25;
export const GENERAR_FACTURAS = 26;
export const TODAS_FACTURAS = 27;
export const ELIMINAR_FACTURAS = 28;
export const EDITAR_FACTURAS = 29;
export const VER_CC = 30;
export const EDITAR_CC = 31;
export const ELIMINAR_CC = 32;
export const TODAS_CC = 33;
import React from 'react';
import { View, Text, Image, StyleSheet } from '@react-pdf/renderer';

export const ObservationsField = ({ inform, user, querys }) => {
    const styles = StyleSheet.create({
        sign: {
            width: "160px",
            height: "80px",
        }
    });
    return (
        <>
            <View style={{
                padding: "20px",
            }}>
                <View style={{
                    backgroundColor: '#f0f0f0',
                    padding: "10px",
                }} >
                    <Text>OBSERVACIONES:</Text>
                    <Text style={{
                        minWidth: "100%",
                        minHeight: "40px"
                    }}>{inform?.observaciones}</Text>
                </View>

                <View style={{
                    marginTop: "10px",
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: "320px"
                }} className="horas">
                    <View style={{
                        display: "flex",
                        flexDirection: "row",
                    }}>
                        <View style={{
                            display: "flex",
                            flexDirection: "row"
                        }}>
                            <Text>Hora inicio:</Text>
                            <Text style={{ paddingLeft: "3px" }}>{inform?.hora_inicio}</Text>
                        </View>

                        <View style={{
                            display: "flex",
                            flexDirection: "row",
                            marginLeft: "20px"
                        }}>
                            <Text>Hora salida:</Text>
                            <Text style={{ paddingLeft: "3px" }}>{inform?.hora_final}</Text>
                        </View>
                    </View>
                </View>

                {/*} <View style={{
                    backgroundColor: '#f0f0f0',
                    padding: "10px",
                    marginTop: "10px"
                }} >
                    <Text>RESPUESTOS UTILIZADOS:</Text>
                    <Text style={{
                        minWidth: "100%",
                        minHeight: "40px"
                    }}>{data.test}</Text>
                </View>*/}

                <View style={{
                    display: "flex",
                    flexDirection: "row",
                }}>
                    <View style={{
                        marginTop: "10px",
                        width: "65%",
                        height: "110px"
                    }}>
                        <View style={{
                            display: 'flex',
                            flexDirection: "row"
                        }}>
                            <View style={{
                                width: "50%",
                                height: "90px",
                                backgroundColor: '#ffffff',
                            }}>

                                <View style={{
                                    marginBottom: "8px"
                                }}>
                                    {
                                        inform?.user?.firma_base64 ? (
                                            <Image src={`${inform?.user?.firma_base64 ?? ""}`} style={styles.sign} />
                                        ) : (
                                            <Text>Sin firma</Text>
                                        )
                                    }
                                </View>
                                <Text style={{ textAlign: 'center', marginTop: "2px", paddingTop: "2px", borderTop: "1px solid #333333", width: "95%" }}>Firma del Tecnico</Text>
                            </View>

                            <View style={{
                                width: "50%",
                                height: "90px",
                                backgroundColor: '#ffffff',
                            }}>
                                <View style={{
                                    marginBottom: "8px"
                                }}>
                                    {
                                        inform?.firma_base64 ? (
                                            <Image src={`${inform?.firma_base64 ?? ""}`} style={styles.sign} />
                                        ) : (
                                            <Text>
                                                Sin firma
                                            </Text>
                                        )
                                    }
                                </View>
                                <Text style={{ textAlign: 'center', paddingTop: "2px", borderTop: "1px solid #333333", width: "90%" }}>Nombre y Firma del Cliente</Text>
                            </View>
                        </View>
                    </View>

                    {
                        (querys()) && (
                            <View style={{
                                padding: "10px",
                                marginTop: "10px",
                                width: "40%",
                                backgroundColor: '#f0f0f0',
                                marginLeft: "3px"
                            }}>
                                <View style={{
                                    display: "flex",
                                    flexDirection: 'row',
                                    paddingTop: "5px"
                                }}>
                                    <View>
                                        <Text style={{ marginTop: "3.3px" }}>Mano de obra:</Text>
                                        <Text style={{ marginTop: "3.3px" }}>Materiales:</Text>
                                        <Text style={{ marginTop: "3.3px" }}>Transporte:</Text>
                                        <Text style={{ marginTop: "3.3px" }}>Total:</Text>
                                    </View>

                                    <View style={{ width: "60%", alignItems: 'center', }}>
                                        <View style={{
                                            width: "100px",
                                            height: "15px",
                                            backgroundColor: '#ffffff',
                                            borderRadius: 3,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            marginRight: "3px",
                                            marginLeft: "2px",
                                            marginTop: "3px"
                                        }}>
                                            <Text>{inform?.procedures} COP</Text>
                                        </View>
                                        <View style={{
                                            width: "100px",
                                            height: "15px",
                                            backgroundColor: '#ffffff',
                                            borderRadius: 3,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            marginRight: "3px",
                                            marginLeft: "2px",
                                            marginTop: "3px"
                                        }}>
                                            <Text>{inform?.materiales} COP</Text>
                                        </View>
                                        {/*<View style={{
                                            width: "100px",
                                            height: "15px",
                                            backgroundColor: '#ffffff',
                                            borderRadius: 3,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            marginRight: "3px",
                                            marginLeft: "2px",
                                            marginTop: "3px"
                                        }}>
                                            <Text>{inform?.mano_obra} COP</Text>
                                    </View>*/}
                                        <View style={{
                                            width: "100px",
                                            height: "15px",
                                            backgroundColor: '#ffffff',
                                            borderRadius: 3,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            marginRight: "3px",
                                            marginLeft: "2px",
                                            marginTop: "3px"
                                        }}>
                                            <Text>{inform?.transporte} COP</Text>
                                        </View>
                                        <View style={{
                                            width: "100px",
                                            height: "15px",
                                            backgroundColor: '#ffffff',
                                            borderRadius: 3,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            marginRight: "3px",
                                            marginLeft: "2px",
                                            marginTop: "3px"
                                        }}>
                                            <Text>{inform?.total} COP</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        )
                    }
                </View>
            </View>
        </>
    )
}
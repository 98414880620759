import { faMinus, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, FormControl, FormSelect, Modal, Row, Table, Spinner, ButtonGroup } from 'react-bootstrap'
import { useAuth, useAxios } from '../../../contexts';
import { usePermissions } from '../../../hooks';
import { VALORES_INFORME } from '../../../utils';

export const ReplaceParts = ({ edit, asignados, setAsignados, replaceParts, appliedParts, setParts, inform, setChecks, setTouched }) => {
  const { user } = useAuth();
  const [showAddPart, setShowAddPart] = useState(false);
  const { privateFetch } = useAxios();
  const [clientes, setCliente] = useState([]);
  const [avQty, setAvQty] = useState(0);
  const [limitQty, setLimitQty] = useState(0);
  const repuestoRef = useRef();
  const cantidadRef = useRef();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [notReplaceParts, setNotReplaceParts] = useState(false);
  const { validate } = usePermissions();
  const [clienteId, setClienteId] = useState(inform?.equipo?.cliente_id ?? null);
  const [repuestoId, setRepuestoId] = useState(null);
  const [limits, setLimits]= useState([]);
  useEffect(() => {
    //setAppliedFromSaved();
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setTouched(true);
    if (appliedParts?.length === 0) return setChecks(false);
    setChecks(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appliedParts])
  useEffect(() => {
    if (notReplaceParts) setChecks(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notReplaceParts]);
  useEffect(() => {
    validatePart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clienteId, repuestoId]);
  const handleClose = () => {
    setShowAddPart(false)
    cantidadRef.current.value = "";
    repuestoRef.current.value = "";
    setAvQty(0);
    setError("");
    setLimitQty(0);
    setClienteId(inform?.equipo?.cliente_id ?? "0");
    setRepuestoId(null)
  };
  const getData = async () => {
    const { data: clients } = await privateFetch.get(`/api/clients/getClients`);
    setCliente(clients.clients)
  }
  const addItem = () => {
    setLoading(true);
    const { value: cantidad } = cantidadRef?.current;
    const { value: inventario } = repuestoRef?.current;
    const inventario_id = inventario !== "" ? parseInt(inventario) : null;
    const cantidadQ = cantidad !== "" ? parseInt(cantidad) : null;
    if (!inventario_id || !cantidadQ || cantidadQ > limitQty) {
      setError("El repuesto no puede ser nulo o tener cantidades menores a 1 o mayores a " + limitQty);
      setLoading(false);
      return;
    }
    setError("");
    const index = appliedParts?.findIndex((val) => val.inv_id === inventario_id && val?.cliente_id === clienteId);
    if (index !== -1) {
      setParts(prev => {
        const copy = [...prev];
        copy[index].cantidad += cantidadQ;
        return copy;
      })
      setLimits(prev => {
        prev[index] -= cantidadQ;
        return prev;
      })
    } else {
      const actualProcedure = replaceParts?.filter(val => val.id === inventario_id)?.[0];
      const copy = [...appliedParts];
      const newData = {
        id: null,
        inv_id: actualProcedure?.id,
        cliente_id: clienteId,
        nombre: actualProcedure?.nombre,
        codigo: actualProcedure?.codigo,
        valor: actualProcedure?.cliente_id !== inform?.equipo?.cliente_id ? actualProcedure?.valor : 0,
        cantidad: cantidadQ,
        se_cobra: clienteId !== inform?.equipo?.cliente_id,
        type: 1
      }
      copy.push(newData);
      setLimits(prev => {
        prev.push(limitQty - cantidadQ);
        return prev;
      });
      setParts(copy);
    }
    const actualRepuesto = asignados?.filter(val => val?.id === inventario_id)?.[0];
    const keyRepuesto = asignados?.indexOf(actualRepuesto);
    const availableUserClient = actualRepuesto?.main_inv_asignados?.filter((val) => val?.cliente_id === clienteId && val?.user_id === parseInt(inform?.user_id ?? "0"))?.[0];
    const keyAsignado = actualRepuesto?.main_inv_asignados?.indexOf(availableUserClient);
    availableUserClient.cantidad -= cantidadQ;
    actualRepuesto.main_inv_asignados[keyAsignado] = availableUserClient;
    setAsignados(prev => {
      prev[keyRepuesto] = actualRepuesto;
      return prev;
    })
    handleClose();
    setLoading(false);
  }
  const deleteItem = (key) => {
    const proceduresActual = appliedParts?.filter(val => parseInt(val.type ?? "0") === 1).filter((val, keyA) => keyA === key)?.[0];
    const actualRepuesto = asignados?.filter(val => val?.id === proceduresActual?.inv_id)?.[0];
    const keyRepuesto = asignados?.indexOf(actualRepuesto);
    const availableUserClient = actualRepuesto?.main_inv_asignados?.filter((val) => val?.cliente_id === proceduresActual?.cliente_id && val?.user_id === parseInt(inform?.user_id ?? "0"))?.[0];
    const keyAsignado = actualRepuesto?.main_inv_asignados?.indexOf(availableUserClient);
    availableUserClient.cantidad += proceduresActual?.cantidad ?? 0;
    actualRepuesto.main_inv_asignados[keyAsignado] = availableUserClient;
    setAsignados(prev => {
      prev[keyRepuesto] = actualRepuesto;
      return prev;
    })
    const proceduresF = appliedParts?.filter(val => parseInt(val.type ?? "0") === 1)?.filter((val, keyA) => keyA !== key);
    setParts(proceduresF);
  }
  const lessOne = (key) => {
    const copy = [...appliedParts];
    if (copy?.[key]?.cantidad === 1) return;
    copy[key].cantidad -= 1;
    setLimits(prev => {
      prev[key] += 1;
      return prev;
    })
    setParts(copy)
  }
  const plusOne = (key) => {
    const copy = [...appliedParts];
    if (limits[key] === 0) return;
    copy[key].cantidad += 1;
    setLimits(prev => {
      prev[key] -= 1;
      return prev;
    })
    setParts(copy)
  }
  const validatePart = () => {
    const repuestoVal = repuestoRef?.current?.value ? parseInt(repuestoRef?.current?.value) : null;
    if (repuestoVal === null) {
      setAvQty(0);
      setLimitQty(0);
      return;
    };
    const actualRepuesto = asignados?.filter(val => val?.id === parseInt(repuestoVal ?? "0"))?.[0];
    const availableUserClient = actualRepuesto?.main_inv_asignados?.filter((val) => val?.cliente_id === clienteId && val?.user_id === parseInt(inform?.user_id ?? "0"))?.[0];
    if (!availableUserClient) {
      setAvQty(0);
      setLimitQty(0);
      return;
    }
    setAvQty((availableUserClient?.cantidad));
    setLimitQty(availableUserClient?.cantidad);
  }

  return (
    <>
      <Row>
        <Col sm={4}>
          <label>Repuestos</label><br />
          <Button size="sm" onClick={e => setShowAddPart(true)} >
            Añadir repuesto
          </Button>
        </Col>
        {
          appliedParts?.length === 0 && (
            <Col sm="auto">
              <label>Validaciones</label><br />
              <Button size="sm" variant='warning' onClick={e => setNotReplaceParts(true)} >
                NO APLICA
              </Button>
            </Col>
          )
        }
      </Row>
      <Row className='mt-3'>
        <Col sm>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>#</th>
                <th>Nombre</th>
                <th>Cliente</th>
                <th>Cantidad</th>
                <th>Código</th>
                {
                  validate(user, VALORES_INFORME) && (
                    <>
                      <th>Valor unitario</th>
                      <th>Valor total</th>
                    </>
                  )
                }
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {
                appliedParts?.filter(val => parseInt(val.type ?? "0") === 1)?.map((val, key) => (
                  <tr key={key}>
                    <td>
                      {key + 1}
                    </td>
                    <td>
                      {val?.nombre}
                    </td>
                    <td>
                      {clientes?.filter(val1 => val1?.id === val?.cliente_id)?.[0]?.nombre}
                    </td>
                    <td>
                      {val?.cantidad}
                    </td>
                    <td>
                      {val?.codigo}
                    </td>
                    {
                      validate(user, VALORES_INFORME) && (
                        <>
                          <td>
                            {val?.valor}
                          </td>
                          <td>
                            {val?.valor * val?.cantidad}
                          </td>
                        </>
                      )
                    }
                    <td>
                      <ButtonGroup size='sm'>
                        {
                          val?.cantidad > 1 && (
                            <Button variant='warning' size="sm" onClick={e => lessOne(key)}>
                              <FontAwesomeIcon icon={faMinus} />
                            </Button>
                          )
                        }
                        <Button variant='success' size="sm" onClick={e => plusOne(key)}>
                          <FontAwesomeIcon icon={faPlus} />
                        </Button>
                        <Button variant='danger' size="sm" onClick={e => deleteItem(key)}>
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </Button>
                      </ButtonGroup>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </Table>
        </Col>
      </Row>
      <Modal show={showAddPart} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Añadir repuesto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm>
              <label>Cliente</label>
              <FormSelect size='sm' defaultValue={inform?.equipo?.cliente_id ?? ""} onChange={e => { setClienteId(parseInt(e?.target?.value ?? "0")) }} >
                <option value="">Seleccione</option>
                {
                  clientes?.map((val, i) => (
                    <option key={i} value={val?.id}>{val?.nombre}</option>
                  ))
                }
              </FormSelect>
            </Col>
          </Row>
          <Row>
            <Col sm>
              <label>Repuesto</label>
              <FormSelect size='sm' ref={repuestoRef} onChange={e => setRepuestoId(parseInt(e?.target?.value ?? "0"))}>
                <option value="">Seleccione</option>
                {
                  replaceParts?.map((val, i) => (
                    <option key={i} value={val?.id}>{val?.nombre}</option>
                  ))
                }
              </FormSelect>
            </Col>
          </Row>
          <Row>
            <Col sm>
              <label>Cantidad</label>
              <FormControl type='number' placeholder={avQty ?? 0} min={1} max={avQty} size='sm' ref={cantidadRef} />
              {
                avQty === 0 ? (
                  <>
                    <small className="text-danger">NO tienes repuestos asignados para utilizar en ésta Nevera</small>
                  </>
                ) : (
                  <>
                    <small className="text-success">Tienes {avQty} repuestos asignados para utilizar en ésta Nevera</small>
                  </>
                )
              }
              {
                error !== "" && (
                  <>
                    <br />
                    <small className='text-danger'>Error: {error}</small>
                  </>
                )
              }
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
          <Button size="sm" variant="primary" disabled={avQty === 0 || loading} onClick={addItem}>
            {
              loading ? (
                <>
                  <Spinner animation="grow" size="sm" variant="warning" />
                </>
              ) : (
                <>
                  Guardar
                </>
              )
            }
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

import React, { useEffect, useRef, useState } from 'react'
import { useAuth, useAxios } from '../../../contexts';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Container, FormControl, Row, Form, InputGroup, FormSelect, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faSave } from "@fortawesome/free-solid-svg-icons";
import { useForm, useNotify, usePermissions } from '../../../hooks';
import { Loader } from '../../Loader/Loader';
import { GESTION_USUARIOS } from '../../../utils';
import CanvasDraw from "react-canvas-draw";


export const EditUsers = () => {
  const [usuario, setUsuario] = useState({});
  const [showPass, setShowPass] = useState(false)
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [spinnerLoading, setSpinnerLoading] = useState(false);
  const { serialize } = useForm();
  const { user } = useAuth();
  const { notify } = useNotify();
  const { id } = useParams();
  const { privateFetch } = useAxios();
  const navigate = useNavigate();
  const { validateRoutePermission, validate } = usePermissions();
  const [clientes, setClientes] = useState([]);
  const [showClients, setShowClients] = useState(false);
  const [showCities, setShowCities] = useState(false);
  const [cities, setCities] = useState([]);
  const [savedSign, setSavedSign] = useState(false);
  const [sign, setSign] = useState({});
  const signRef = useRef();
  useEffect(() => {
    document.title = "Editar usuario";
    if (!id) navigate("/home");
    if (parseInt(id ?? "0") !== user?.id) validateRoutePermission(user, GESTION_USUARIOS);
    getUsuario();
    return () => {
      setUsuario({});
      setRoles([]);
      setShowPass(false);
      setLoading(false);
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (usuario?.firma) {
      setSign({
        firma: usuario?.firma,
        firma_base64: usuario?.firma_base64
      })
      setSavedSign(true);
    }
  }, [usuario]);
  const getUsuario = async () => {
    setLoading(true);
    const { data: users } = await privateFetch.get(`/api/users/getUsuarios/${id}`);
    const { data: roles } = await privateFetch.get("/api/users/getRoles");
    const { data: clients } = await privateFetch.get(`/api/clients/getClients`);
    const { data: citiesS } = await privateFetch.get("api/location/getCities");
    setCities(citiesS.cities);
    setClientes(clients.clients);
    if (users?.users?.rol_id === 4) setShowClients(true);
    if (users?.users?.rol_id === 2) setShowCities(true);
    setUsuario(users.users);
    setRoles(roles.roles)
    setLoading(false);
  }
  const editUsuarioRequest = async (ev) => {
    ev.preventDefault();
    setSpinnerLoading(true);
    const formData = serialize(ev.target);
    const { data } = await privateFetch.put(
      "api/users/actualizarUsuario/" + id, {
      ...formData,
      ...sign
    }
    );
    notify({
      ...data
    })
    setSpinnerLoading(false);
  }
  const validateRol = (ev) => {
    const value = parseInt(ev?.target?.value ?? "0");
    switch (value) {
      case 2:
        setShowCities(true)
        break;
      case 4:
        setShowCities(false)
        setShowClients(true)
        break;
      default:
        setShowClients(false);
        break;
    }
  }
  const getSign = () => {
    const { current } = signRef;
    if (!current) return;
    if (current?.lines?.length === 0) {
      setSavedSign(false)
      return;
    }
    const firmaData = current.getSaveData();
    const firmaBase64 = current.getDataURL("png");
    setSign({
      firma: firmaData,
      firma_base64: firmaBase64,
    })
    setSavedSign(true);
  }
  const deleteSign = () => {
    const { current } = signRef;
    if (!current) return;
    setSavedSign(false);
    current.clear();
  }
  return (
    <>
      <Container fluid className='mt-2'>
        <Card>
          <Card.Header>Editar Usuario</Card.Header>
          {!loading ? (
            <Form onSubmit={editUsuarioRequest} autoComplete='off'>
              <Card.Body>
                <Row>
                  <Col sm="auto">
                    <label>Cedula</label>
                    <FormControl size="sm" required name='cifnif' defaultValue={usuario?.cifnif} />
                  </Col>
                  <Col sm="auto">
                    <label>Nombre</label>
                    <FormControl size="sm" required name='nombre' defaultValue={usuario?.nombre} />
                  </Col>
                  <Col sm="auto">
                    <label>Apellidos</label>
                    <FormControl size="sm" required name='apellido' defaultValue={usuario?.apellido} />
                  </Col>
                  <Col sm>
                    <label>Correo</label>
                    <FormControl size='sm' name='correo' required defaultValue={usuario?.correo} />
                  </Col>
                  <Col sm >
                    <label>Contraseña</label>
                    <InputGroup size="sm">
                      <Form.Control
                        type={showPass ? "text" : "password"}
                        name="password"

                      />
                      <Button onClick={(e) => setShowPass(!showPass)}>
                        <FontAwesomeIcon icon={showPass ? faEye : faEyeSlash} />
                      </Button>
                    </InputGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm='auto'>
                    <label>Id de telegram</label>
                    <FormControl size='sm' name='telegram_id' defaultValue={usuario?.telegram_id} />
                  </Col>
                  <Col sm='auto'>
                    <label>Rol</label>
                    <FormSelect defaultValue={usuario?.rol_id} size="sm" required name="rol_id" onChange={validateRol} disabled={!validate(user, GESTION_USUARIOS)}>
                      <option value="">Seleccione</option>
                      {roles.map((val, id) => (
                        <option key={id} value={val.id}>
                          {val.nombre}
                        </option>
                      ))}
                    </FormSelect>
                  </Col>
                  {
                    showClients && (
                      <Col sm={3}>
                        <label>Cliente</label>
                        <FormSelect size="sm" defaultValue={usuario?.cliente_id} required name="cliente_id">
                          <option value="">Seleccione</option>
                          {clientes.map((val, id) => (
                            <option key={id} value={val.id}>
                              {val.nombre}
                            </option>
                          ))}
                        </FormSelect>
                      </Col>
                    )
                  }
                  {
                    showCities && (
                      <Col sm={3}>
                        <label>Ciudad principal</label>
                        <FormSelect size="sm" defaultValue={usuario?.city_id} required name="city_id">
                          <option value="">Seleccione</option>
                          {cities.map((val, id) => (
                            <option key={id} value={val.id}>
                              {val.nombre}
                            </option>
                          ))}
                        </FormSelect>
                      </Col>
                    )
                  }
                  {
                    validate(user, GESTION_USUARIOS) && (
                      <Col sm="auto">
                        <label>Vigencia</label>
                        <FormControl type='date' size="sm" required name='vigencia' defaultValue={usuario?.vigencia} />
                      </Col>
                    )
                  }
                  {
                    validate(user, GESTION_USUARIOS) && (
                      <>
                        <Col sm="auto">
                          <label>Tiempo informes</label>
                          <FormSelect size="sm" defaultValue={usuario?.cantidad_informe} required name="cantidad_informe">
                            <option value="">Seleccione</option>
                            <option value={1}>1 Mes</option>
                            <option value={2}>3 Meses</option>
                            <option value={3}>6 Meses</option>
                            <option value={4}>1 Año</option>
                            <option value={5}>Todos</option>
                          </FormSelect>
                        </Col>
                        <Col sm="auto">
                          <label>Valor informe</label>
                          <FormControl type='number' step="any" size="sm" required name='valor_informe' defaultValue={usuario?.valor_informe} />
                        </Col>
                        <Col sm="auto">
                          <label>Porcentaje km</label>
                          <FormControl type='number' min={0} max={100} size="sm" required name='tr_percentage' defaultValue={usuario?.tr_percentage} />
                        </Col>
                      </>
                    )
                  }
                </Row>
                <Row>
                  <Col sm className='mt-2'>
                    <label>Direccion</label>
                    <FormControl
                      as="textarea"
                      size="sm"
                      type="text"
                      name="direccion"
                      required
                      defaultValue={usuario?.direccion}
                    />
                  </Col>
                </Row>
                {
                  showCities && (
                    <Row className='justify-content-center mt-3'>
                      <Col sm="auto">
                        <Row className='my-1'>
                          <Col sm>
                            <Button size='sm' variant={savedSign ? "success" : "primary"} onClick={getSign}  >
                              {
                                savedSign ? (
                                  <>
                                    Firma Guardada
                                  </>
                                ) : (
                                  <>
                                    Guardar Firma
                                  </>
                                )
                              }
                            </Button>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm>
                            <Button size='sm' variant='danger' onClick={deleteSign}  >
                              Borrar Firma
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                      <Col sm="auto">
                        <CanvasDraw willReadFrequently {...(usuario?.firma && ({ saveData: usuario?.firma }))} ref={signRef} canvasWidth={600} brushRadius={4} lazyRadius={0} gridLineWidth={1} gridColor='#333' />
                      </Col>
                    </Row>
                  )
                }

              </Card.Body>
              <Card.Footer>
                <Button type='submit' size='sm' variant='primary' disabled={showCities && !savedSign}>
                  {
                    spinnerLoading ? (
                      <>
                        <Spinner animation="grow" variant="warning" />
                      </>
                    ) : (
                      <>
                        <FontAwesomeIcon icon={faSave} /> &nbsp;
                        Guardar
                      </>
                    )
                  }
                </Button>
              </Card.Footer>
            </Form>
          ) : (
            <Loader />
          )}
        </Card>
      </Container>
    </>
  )
}

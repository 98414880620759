import { Badge, Button, ButtonGroup } from "react-bootstrap";
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { GeneralDatatable } from "../../General";

export const InventoryTable = (props) => {
  const columns = [
    {
      id: "id",
      name: "Número",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Nombre",
      selector: (row) => row.nombre,
      sortable: true,
    },
    {
      name: "Tipo",
      sortable: true,
      cell: (row) => {
        let color = "info";
        switch (row.tipo) {
          case 2:
            color = "primary";
            break;
          default:
            color = "info"
            break;
        }
        return (
          <Badge pill bg={color}>
            {row.tipo === 1 ? "Repuesto" : "Producto"}
          </Badge>
        );
      },
    },
    {
      name: "Estado",
      sortable: true,
      cell: (row) => {
        let color = "warning";
        switch (row.estado) {
          case true:
            color = "success";
            break;
          default:
            color = "warning"
            break;
        }
        return (
          <Badge pill bg={color}>
            {row.estado ? "Activo" : "Inactivo"}
          </Badge>
        );
      },
    },
    {
      name: "Acciones",
      button: true,
      cell: (row) => {
        return (
          <>
            <ButtonGroup aria-label="Basic example">
              <Button
                size="sm"
                variant="warning"
                onClick={(e) => props.onEdit(row.id)}
              >
                <FontAwesomeIcon icon={faEdit} />
              </Button>
              <Button
                size="sm"
                variant="danger"
                onClick={(e) => props.onDelete(row.id)}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </Button>
            </ButtonGroup>
          </>
        );
      },
    },
  ];
  return (
    <>
      <GeneralDatatable
        columns={columns}
        data={props.data}
        customProps={{ defaultSortFieldId: "id", defaultSortAsc: false }}
      />
    </>
  )
}

import React, { useEffect, useState } from 'react'
import { faPlus, faSync } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Form, FormControl, Modal, Row } from 'react-bootstrap'
import { DptosTable } from './DptosTable'
import { Loader } from '../../Loader'
import { GESTION_DEPARTAMENTOS_CIUDADES } from '../../../utils'
import { useAlert, useForm, useNotify, usePermissions } from '../../../hooks'
import { useAuth, useAxios } from '../../../contexts'

export const ManageDptos = ({ reload }) => {
  const { user } = useAuth();
  const [showAddItem, setShowAddItem] = useState(false);
  const handleShowCreate = () => setShowAddItem(true);
  const handleCloseCreate = () => setShowAddItem(false);
  const [loading, setLoading] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const handleCloseEdit = () => setShowModalEdit(false);
  const [sLoading, setSloading] = useState(false);
  const { validateRoutePermission } = usePermissions();
  const [departamentos, setDepartamentos] = useState([]);
  const { privateFetch } = useAxios();
  const { serialize } = useForm();
  const { notify, DelAlert } = useNotify();
  const { Alert } = useAlert();
  const [editDptoState, setEditDptoState] = useState();

  useEffect(() => {
    validateRoutePermission(user, GESTION_DEPARTAMENTOS_CIUDADES);
    document.title = "Departamentos || Ciudades";
    getDpto();
    return () => {
      setDepartamentos([]);
      setLoading(false);
    }
    // eslint-disable-next-line 
  }, [])

  const getDpto = async () => {
    setLoading(true);
    const { data } = await privateFetch.get("/api/location/getDepartments");
    setDepartamentos(data.states);
    setLoading(false);
  }

  const guardarDpto = async (ev) => {
    ev.preventDefault();
    setLoading(true);
    const formData = serialize(ev.target);
    const { data } = await privateFetch.post("/api/location/saveDepartment", formData);
    notify({
      ...data,
      callback: () => {
        ev.target.reset();
      },
    });
    getDpto();
    reload(prev => !prev)
    setLoading(false);
    setShowAddItem(false);
  }

  const editDpto = async (id) => {
    const dpto = departamentos.filter(val => val.id === id)?.[0];
    setEditDptoState(dpto);
    setShowModalEdit(true);
  }

  const editarDpto = async (ev) => {
    ev.preventDefault();
    setSloading(true);
    const formData = serialize(ev.target);
    const { data } = await privateFetch.put("api/location/editDepartment/" + editDptoState?.id, formData);
    notify({
      ...data,
      callback: () => {
        ev.target.reset();
        setEditDptoState();
      },
    });
    getDpto();
    setShowModalEdit(false);
    setSloading(false);
  }

  const deleteDpto = async (id) => {
    await DelAlert({
      text: "¿Desea eliminar el departamento?",
      onConfirm: async () => {
        const { data } = await privateFetch.delete(
          "/api/location/deleteDepartment/" + id
        );
        notify(data);
        getDpto();
      },
      onCancel: () => {
        Alert({
          type: "warning",
          text: "Eliminación cancelada",
        });
      },
    });
  };


  return loading ? (
    <Loader />
  ) : (
    <>
      <Row className="mb-2">
        <Col sm="auto">
          <Button size="sm" variant="primary" onClick={getDpto}>
            <FontAwesomeIcon icon={faSync} />
          </Button>
        </Col>
        <Col sm="auto">
          <Button size="sm" variant="primary" onClick={handleShowCreate}>
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </Col>
      </Row>
      <DptosTable data={departamentos} onEdit={editDpto} onDelete={deleteDpto} />
      <Modal show={showAddItem} onHide={handleCloseCreate}>
        <Modal.Header closeButton>
          <Modal.Title>Nuevo Departamento</Modal.Title>
        </Modal.Header>
        {!loading ? (
          <Form onSubmit={guardarDpto} autoComplete='off'>
            <Modal.Body>
              <Row>
                <Col sm>
                  <label>Nombre Departamento</label>
                  <FormControl size="sm" type="text" name="nombre" required />
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button size="sm" variant="secondary" onClick={handleCloseCreate}>
                Cerrar
              </Button>
              <Button type="submit" size="sm" variant="primary">
                Guardar
              </Button>
            </Modal.Footer>
          </Form>
        ) : (
          <Loader />
        )}
      </Modal>
      <Modal show={showModalEdit} onHide={handleCloseEdit}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Elemento</Modal.Title>
        </Modal.Header>
        <Form onSubmit={editarDpto} autoComplete="off">
          <Modal.Body>
            <Row>
              <Col sm>
                <label>Nombre Departamento</label>
                <FormControl size="sm" type="text" name="nombre" defaultValue={editDptoState?.nombre} required />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button size="sm" variant="secondary" onClick={handleCloseEdit}>
              Cerrar
            </Button>
            <Button
              type="submit"
              size="sm"
              variant="primary"
              disabled={sLoading}
            >
              {sLoading ? <></> : <>Guardar</>}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}
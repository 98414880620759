import React from 'react'
import { Badge, Button, ButtonGroup } from "react-bootstrap";
import {faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GeneralDatatable } from "../../General";


export const CCEditTable = (props) => {
    const columns = [
        {
            id: "id",
            name: "Número",
            selector: (row) => row?.id,
            sortable: true,
        },
        {
            name: "Fecha",
            sortable: true,
            cell: row => {
                let fecha = `${row?.fecha ?? ""}`;
                return (
                    <>
                        {fecha !== "" && (
                            <>
                                {fecha} <br />
                            </>
                        )}
                    </>
                )
            }
        },
        {
            name: "Placa",
            selector: (row) => row?.equipo?.placa,
            sortable: true
        },
        {
            name: "Total",
            selector: (row) => (row?.user?.valor_informe + (row?.mano_obra * (row?.user?.tr_percentage / 100))),
            sortable: true
        },
        {
            name: "Estado",
            sortable: true,
            cell: (row) => {
                let color = "primary";
                let border = "";
                let label = ""
                switch (row.estado) {
                    case 0:
                        color = "danger";
                        border = "danger";
                        label = "Servicio solicitado";
                        break;
                    case 1:
                        color = "warning";
                        border = "warning";
                        label = "Por realizar";
                        break;
                    case 2:
                        color = "success";
                        border = "success";
                        label = "Realizado";
                        break;
                    case 3:
                        color = "warning";
                        border = "danger";
                        label = "En seguimiento";
                        break;
                    default:
                        color = "primary";
                        border = "primary";
                        label = "Facturado";
                        break;
                }
                return (
                    <Badge pill bg={color} className={`border border-${border}`} >
                        {label}
                    </Badge>
                );
            },
        },
        {
            name: "Acciones",
            button: true,
            cell: (row) => {
                return (
                    <>
                        <ButtonGroup aria-label="Basic example">

                            <Button
                                size="sm"
                                variant="info"
                                onClick={(e) => props.onView(row.id)}
                            >
                                <FontAwesomeIcon icon={faEye} />
                            </Button>

                        </ButtonGroup>
                    </>
                );
            },
        },
    ]
    return (
        <>
            <GeneralDatatable
                columns={columns}
                data={props.data}
                customProps={{ defaultSortFieldId: "id", defaultSortAsc: false }}
            />
        </>
    )
}

import React from 'react'
import { View, Text, StyleSheet } from '@react-pdf/renderer';

export const ProductsTable = ({ products }) => {
    const styles = StyleSheet.create({
        scrollContainer: {
            flexGrow: 1,
        },
        table: {
            margin: 20,
            borderColor: '#cccccc',
            borderStyle: 'solid',
        },
        row: {
            flexDirection: 'row',
            borderBottomWidth: 1,
            borderBottomColor: '#cccccc',
            paddingVertical: 5,
        },
        headerCell: {
            flex: 1,
            textAlign: 'center',
            fontWeight: 'bold',
        },
        cell: {
            flex: 1,
            textAlign: 'center',
            minWidth: 50,
        },
    });
    return (
        <>
        <Text style={{
            textAlign: "center",
        }}
        >Lista de Productos</Text>

            <View style={styles.table}>
                <View style={styles.row}>
                    <Text style={styles.headerCell}>Número</Text>
                    <Text style={styles.headerCell}>Producto</Text>
                    <Text style={styles.headerCell}>Codigo</Text>
                    <Text style={styles.headerCell}>Cantidad</Text>
                </View>
                {
                    
                    products?.map((val, i) => (
                        <View key={i} style={styles.row}>
                            <Text style={styles.cell}>{i + 1}</Text>
                            <Text style={styles.cell}>{val?.inv?.nombre}</Text>
                            <Text style={styles.cell}>{val?.inv?.codigo}</Text>
                            <Text style={styles.cell}>{val?.cantidad}</Text>
                        </View>
                    ))
                }
            </View>
        </>
    )
}
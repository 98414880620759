import { useAlert } from './useAlert'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const MySwal = withReactContent(Swal);

export const useNotify = () => {
    const { Alert } = useAlert();
    const notify = (data) => {
        switch (data.code) {
            case 201:
            case 200:
                Alert({
                    title: "Éxito",
                    text: data.text,
                    type: "success",
                    callback: data.callback
                });
                break;
            default:
                Alert({
                    title: "Advertencia",
                    text: data.text,
                    type: "warning",
                    callback: data.callback
                });
                break;
        }
    }
    const DelAlert = async (props) => {
        const {
            title = process.env.REACT_APP_APP_NAME,
            text = "Alert",
            onConfirm = () => { },
            onCancel = () => { },
            confirmButtonText = "Eliminar",
            icon = "error"
        } = props;
        const response = await MySwal.fire({
            title,
            text,
            icon,
            timerProgressBar: true,
            showConfirmButton: true,
            showCancelButton: true,
            heightAuto: false,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText,
            cancelButtonText: "Cancelar"
        });
        if (response.isConfirmed) {
            onConfirm();
        } else {
            onCancel();
        }
    }
    return {
        notify,
        DelAlert
    }
}
import React from 'react';
import { Badge } from 'react-bootstrap';
import { GeneralDatatable } from '../../General';

export const PermissionsTable = (props) => {
  const columns = [
    {
      id: "id",
      name: "Número",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Permiso",
      selector: (row) => row?.permiso?.permiso,
      sortable: true,
    },
    {
      name: "Estado",
      button: true,
      cell: (row) => {
        let color = "success";
        switch (row?.estado) {
          case false:
            color = "danger";
            break;
          default:
            color = "success";
            break;
        }
        return (
          <Badge pill bg={color} onClick={e => props.onClick(row?.permiso?.cod, !row?.estado)}>
            {row?.estado ? 'Activo' : 'Inactivo'}
          </Badge>
        );
      },
    }
  ];
  return (
    <GeneralDatatable
      columns={columns}
      data={props.data}
      customProps={{ defaultSortFieldId: "id", defaultSortAsc: false }}
    />
  )
}

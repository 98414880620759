import PropTypes from 'prop-types';

export const Step = ({
    indicator,
    label,
    navigateToStepHandler,
    index,
    isActive,
    isComplete,
    isWarning,
    isError,
    isRightToLeftLanguage,
}) => {
    const classes = [''];

    if (isActive) {
        classes.push('is-active');
    }
    if (isComplete) {
        classes.push('is-complete');
    }
    if (isWarning) {
        classes.push('is-warning');
    }
    if (isError) {
        classes.push('is-error');
    }
    if (isRightToLeftLanguage) {
        classes.push('rightToLeft');
    }

    return (
        <div className={`stepper-step ${classes.join(' ')}`}>
            <div className="stepper-indicator">
                <span
                    className="stepper-indicator-info"
                    onClick={isComplete || isError ? () => navigateToStepHandler(index) : null}
                >
                    {isComplete ? (
                        <svg className="stepper-tick" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490 490">
                            <path d="M452.253 28.326L197.831 394.674 29.044 256.875 0 292.469l207.253 169.205L490 54.528z" />
                        </svg>
                    ) : (
                        indicator
                    )}
                </span>
            </div>
            <div className="stepper-label">{label}</div>
        </div>
    );
};

Step.propTypes = {
    indicator: PropTypes.oneOfType([PropTypes.node, PropTypes.number]),
    label: PropTypes.string.isRequired,
    navigateToStepHandler: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    isActive: PropTypes.bool,
    isComplete: PropTypes.bool,
    isError: PropTypes.bool,
    isWarning: PropTypes.bool,
    isRightToLeftLanguage: PropTypes.bool,
};

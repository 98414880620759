import { Image, StyleSheet, Text } from '@react-pdf/renderer'
import React from 'react'

export const PlateImage = ({ src }) => {
    const styles = StyleSheet.create({
        placa: {
            width: "300px",
            height: "200px",
            maxWidth: "500px",
            maxHeight: "300px",
            margin: "10px 10px 10px 10px",
            alignSelf: "center"
        },
        titulo: {
            alignSelf: "center"
        }
    })
    return (
        <>
            <Text style={styles.titulo}>Foto de placa</Text><br />
            <Image
                //debug
                style={styles.placa}
                src={src}
            />
        </>
    )
}

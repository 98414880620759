import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { SelectorChecks } from './SelectorChecks';
import { useAlert } from '../../../hooks';

export const Checks2 = ({ inform, setChecks, setTouched2, setFormData, refrig }) => {
    const { Alert } = useAlert();
    const [fields1, setFields1] = useState([
        {
            label: "",
            name: "referencia_compresor",
            isEmpty: true,
            type: "text",
            inputs: [
                {
                    label: "Referencia compresor",
                    name: "referencia_compresor",
                    value: inform?.["referencia_compresor"] ?? "",
                    required: false,
                    type: "text",
                },
            ]
        },
        {
            label: "Tipo de refrigerante",
            name: "refrigerante_id",
            isEmpty: true,
            type: "selector",
            required: false,
            value: inform?.["refrigerante_id"] ?? "",
            options: refrig
        },
        {
            label: "T°C",
            name: "",
            isEmpty: true,
            type: "text",
            inputs: [
                {
                    label: "Llegada",
                    name: "temp_llegada",
                    value: inform?.["temp_llegada"] ?? "0",
                    required: false,
                    type: "text",
                },
                {
                    label: "Salida",
                    name: "temp_salida",
                    value: inform?.["temp_salida"] ?? "0",
                    required: false,
                    type: "text",
                }
            ]
        },
    ])
    const [fields2, setFields2] = useState([
        {
            label: "",
            name: "cond_normal",
            isEmpty: true,
            type: "checkbox",
            inputs: [
                {
                    label: "Normal",
                    name: "cond_normal",
                    value: inform?.["cond_normal"] ?? false,
                    required: false,
                    type: "checkbox",
                    props: {
                        defaultChecked: inform?.["cond_normal"],
                    }
                },
            ]
        },
        {
            label: "",
            name: "cond_obst_grasa",
            isEmpty: true,
            type: "checkbox",
            inputs: [
                {
                    label: "Obstruido de grasa",
                    name: "cond_obst_grasa",
                    value: inform?.["cond_obst_grasa"] ?? false,
                    required: false,
                    type: "checkbox",
                    props: {
                        defaultChecked: inform?.["cond_obst_grasa"],
                    }
                },
            ]
        },
        {
            label: "",
            name: "cond_obst_polvo",
            isEmpty: true,
            type: "checkbox",
            inputs: [
                {
                    label: "Obstruido de polvo",
                    name: "cond_obst_polvo",
                    value: inform?.["cond_obst_polvo"] ?? false,
                    required: false,
                    type: "checkbox",
                    props: {
                        defaultChecked: inform?.["cond_obst_polvo"],
                    }
                },
            ]
        },
        {
            label: "",
            name: "cond_fugas",
            isEmpty: true,
            type: "checkbox",
            inputs: [
                {
                    label: "Fugas",
                    name: "cond_fugas",
                    value: inform?.["cond_fugas"] ?? false,
                    required: false,
                    type: "checkbox",
                    props: {
                        defaultChecked: inform?.["cond_fugas"],
                    }
                },
            ]
        },
        {
            label: "",
            name: "cond_otros",
            isEmpty: true,
            type: "text",
            inputs: [
                {
                    label: "Otros",
                    name: "cond_otros",
                    value: inform?.["cond_otros"] ?? "",
                    required: false,
                    type: "text",
                    props: {
                        defaultChecked: inform?.["cond_otros"],
                    }
                },
            ]
        },

    ])
    const [fields3, setFields3] = useState([

        {
            label: "",
            name: "evap_normal",
            isEmpty: true,
            type: "checkbox",
            inputs: [
                {
                    label: "Normal",
                    name: "evap_normal",
                    value: inform?.["evap_normal"] ?? false,
                    required: false,
                    type: "checkbox",
                    props: {
                        defaultChecked: inform?.["evap_normal"],
                    }
                },
            ]
        },
        {
            label: "",
            name: "evap_bloqueado",
            isEmpty: true,
            type: "checkbox",
            inputs: [
                {
                    label: "Bloqueado",
                    name: "evap_bloqueado",
                    value: inform?.["evap_bloqueado"] ?? false,
                    required: false,
                    type: "checkbox",
                    props: {
                        defaultChecked: inform?.["evap_bloqueado"],
                    }
                },
            ]
        },
        {
            label: "",
            name: "evap_fugas",
            isEmpty: true,
            type: "checkbox",
            inputs: [
                {
                    label: "Fugas",
                    name: "evap_fugas",
                    value: inform?.["evap_fugas"] ?? false,
                    required: false,
                    type: "checkbox",
                    props: {
                        defaultChecked: inform?.["evap_fugas"],
                    }
                },
            ]
        },
        {
            label: "",
            name: "evap_otros",
            isEmpty: true,
            type: "text",
            inputs: [
                {
                    label: "Otros",
                    name: "evap_otros",
                    value: inform?.["evap_otros"] ?? "",
                    required: false,
                    type: "text",
                    props: {
                        defaultChecked: inform?.["evap_otros"],
                    }
                },
            ]
        },
        {
            label: "",
            name: "",
            isEmpty: false,
            type: "text",
            inputs: [
                {
                    label: "Temperatura Entrega",
                    name: "evap_temp_entrada",
                    value: inform?.["evap_temp_entrada"] ?? "0",
                    required: true,
                    type: "text",
                },
            ]
        },

    ])
    const [fields4, setFields4] = useState([

        {
            label: "Perdida producto",
            name: "averias",
            isEmpty: false,
            required:true,
            type: "boolean",
            value: inform?.["averias"] ?? false,
            options: [{ id: true, nombre: "Sí" }, { id: false, nombre: "No" }]
        },
        {
            label: "Tipo pérdida",
            name: "averia_tipo",
            isEmpty: true,
            required: false,
            type: "selector",
            value: inform?.["averia_tipo"] ?? null,
            options: [{ id: 1, nombre: "Total" }, { id: 2, nombre: "Parcial" }, {id: 3, nombre: "Unidades"}]
        },
    ])
    const validateFields = (val) => {
        let returnData = {}
        let returnValidate = true;
        if (val.type === "boolean" || val.type === "selector") {
            if (!val?.isEmpty && ["", null, "0"].includes(val?.value) && !val?.required) {
                returnValidate = false;
            }
            if (returnValidate !== false) returnData[val.name] = val?.value;
        }
        if (val.type === "text") {
            val?.inputs?.forEach(val1 => {
                if (!val?.isEmpty && ["", null, "0"].includes(val1?.value)) {
                    returnValidate = false;
                }
                if (returnValidate !== false) returnData[val1.name] = val1?.value;
            })
        }
        if(val?.type === "checkbox"){
            val?.inputs?.forEach(val1 => {
                if (!val?.isEmpty && ["", null, "0"].includes(val1?.value)) {
                    returnValidate = false;
                }
                if (returnValidate !== false) returnData[val1.name] = val1?.value;
            })
        }
        return returnValidate ? returnData : false;
    }
    useEffect(() => {
        setChecks(false);
        // eslint-disable-next-line 
    }, [fields1, fields2, fields3, fields4]);
    const validateData = () => {
        let allData = {};
        setTouched2(true);
        let validated = true;
        let allOk = true;
        [...fields1, ...fields2, ...fields3, ...fields4].forEach((val) => {
            validated = validateFields(val);
            if (!validated) allOk = false;
            if (validated) {
                allData = {
                    ...allData,
                    ...validated
                }
                setFormData((prev) => ({
                    ...prev,
                    ...allData
                }))
            }
        })
        if (!allOk) {
            Alert({
                type: "warning",
                text: "EL formulario no está correcto, por favor revisa de nuevo"
            })
        }
        setChecks(allOk)
    }
 
    return (
        <>
            <Container fluid>
                <Row className='justify-content-center'>
                    <Col sm={6}>
                        <Row className='justify-content-center mb-2'><Col sm="auto"><strong>Sistema de refrigeración</strong></Col></Row>
                        {
                            fields1?.map((val, index) => (
                                <SelectorChecks key={index} index={index} data={val} setFields={setFields1} />
                            ))
                        }
                    </Col>
                </Row>
                <Row className='my-3 justify-content-center'>
                    <Col sm={6}>
                        <Row className='justify-content-center mb-2'><Col sm="auto"><strong>Condensador</strong></Col></Row>
                        {
                            fields2?.map((val, index) => (
                                <SelectorChecks key={index} index={index} data={val} setFields={setFields2} />
                            ))
                        }
                    </Col>
                </Row>
                <Row className='my-3 justify-content-center'>
                    <Col sm={6}>
                        <Row className='justify-content-center mb-2'><Col sm="auto"><strong>Evaporador</strong></Col></Row>
                        {
                            fields3?.map((val, index) => (
                                <SelectorChecks key={index} index={index} data={val} setFields={setFields3} />
                            ))
                        }
                    </Col>
                </Row>
                <Row className='my-3 justify-content-center'>
                    <Col sm={6}>
                        <Row className='justify-content-center mb-2'><Col sm="auto"><strong>Perdida de producto</strong></Col></Row>
                        {
                            fields4?.map((val, index) => (
                                <SelectorChecks key={index} index={index} data={val} setFields={setFields4} />
                            ))
                        }
                    </Col>
                </Row>
                <Row className='my-1 justify-content-end'>
                    <Col sm={"auto"}>
                        <Button size="sm" variant='primary' onClick={validateData} >
                            Validar
                        </Button>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

import React, { useState } from 'react'
import { Loader, StoreDataTable } from '../../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSync } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from 'react';
import { useAlert, useForm, useNotify, usePermissions } from '../../../hooks';
import { GESTION_BODEGAS } from '../../../utils';
import { useAuth, useAxios } from '../../../contexts';
import { Button, Col, FormControl, Modal, Row, Form, FormSelect } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export const Store = () => {
  const { user } = useAuth();
  const { validateRoutePermission } = usePermissions();
  const [loading, setLoading] = useState(false);
  const [showAddItem, setShowAddItem] = useState(false);
  const handleShowCreate = () => setShowAddItem(true);
  const handleCloseCreate = () => setShowAddItem(false);
  const [store, setStore] = useState([]);
  const { privateFetch } = useAxios();
  const { serialize } = useForm();
  const { notify, DelAlert } = useNotify();
  const [corregimientos, setCorregimientos] = useState([]);
  const { Alert } = useAlert();
  const navigate = useNavigate()


  useEffect(() => {
    validateRoutePermission(user, GESTION_BODEGAS);
    document.title = "Bodegas";
    getStores();
    return () => {
      setStore([]);
    }
    // eslint-disable-next-line
  }, [])

  const editStore = (id) => {
    navigate("/store/edit-store/" + id)
  };

  const getStores = async () => {
    setLoading(true);
    const { data } = await privateFetch.get(`api/store/getStores`);
    const { data: corregimientosDB } = await privateFetch.get("api/location/getCorregimientos");
    setStore(data.store);
    setCorregimientos(corregimientosDB.corregimiento);
    setLoading(false)
  }

  const saveStore = async (ev) => {
    ev.preventDefault();
    setLoading(true);
    const formData = serialize(ev.target);
    const { data } = await privateFetch.post(`api/store/saveStore`, formData);
    notify({
      ...data,
      callback: () => {
        ev.target.reset();
      },
    });
    getStores();
    setShowAddItem(false);
    setLoading(false);
  }

  const deleteStore = async (id) => {
    await DelAlert({
      text: "¿Desea eliminar la Bodega?",
      onConfirm: async () => {
        const { data } = await privateFetch.delete(
          "/api/store/deleteStore/" + id
        );
        notify(data);
        getStores();
      },
      onCancel: () => {
        Alert({
          type: "warning",
          text: "Eliminación cancelada",
        });
      },
    });
  };

  return loading ? (
    <Loader />
  ) : (
    <>
      <Row className="mb-2">
        <Col sm="auto">
          <Button size="sm" variant="primary" onClick={getStores}>
            <FontAwesomeIcon icon={faSync} />
          </Button>
        </Col>
        <Col sm="auto">
          <Button size="sm" variant="primary" onClick={handleShowCreate}>
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </Col>
      </Row>
      <StoreDataTable data={store} onDelete={deleteStore} onEdit={editStore} />
      <Modal show={showAddItem} onHide={handleCloseCreate}>
        <Modal.Header closeButton>
          <Modal.Title>Nuevo</Modal.Title>
        </Modal.Header>
        {!loading ? (
          <Form onSubmit={saveStore} autoComplete='off'>
            <Modal.Body>
              <Row>
                <Col sm>
                  <label>Codigo</label>
                  <FormControl size="sm" type="text" name="codigo" required />
                </Col>
              </Row>
              <Row>
                <Col sm>
                  <label>Nombre</label>
                  <FormControl size="sm" type="text" name="nombre" required />
                </Col>
              </Row>
              <Row>
                <Col sm>
                  <label>Cuidad</label>
                  <FormSelect defaultValue="" size="sm" name="ciudad_id" required >
                    <option value="">Seleccione</option>
                    {
                      corregimientos?.map((val, id) => (
                        <option key={id} value={val?.id}>
                          {val?.nombre}
                        </option>
                      ))
                    }
                  </FormSelect>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label>Estado</label>
                  <FormSelect defaultValue="" size="sm" required name="estado">
                    <option value="">Seleccione</option>
                    {[{ id: true, nombre: "Activo" }, { id: false, nombre: "Inactivo" }].map((val, id) => (
                      <option key={id} value={val.id}>
                        {val.nombre}
                      </option>
                    ))}
                  </FormSelect>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button size="sm" variant="secondary" onClick={handleCloseCreate}>
                Cerrar
              </Button>
              <Button type="submit" size="sm" variant="primary">
                Guardar
              </Button>
            </Modal.Footer>
          </Form>
        ) : (
          <Loader />
        )}
      </Modal>
    </>
  )
}

import React, { useEffect, useRef, useState } from 'react'
import CanvasDraw from "react-canvas-draw";
import { Row, Col, Button } from "react-bootstrap";

export const Sign = ({ inform, setFormData, setChecks, setTouched }) => {
    const signRef = useRef();
    const [saved, setSaved] = useState(false);
    useEffect(() => {
        setTouched(true);
        if(inform?.firma) {
            setChecks(true);
            setSaved(true);
        };
        return () => {
            setTouched(false);
            setChecks(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getSign = () => {
        const { current } = signRef;
        if (!current) return;
        if (current?.lines?.length === 0) {
            setChecks(false);
            return;
        }
        const firmaData = current.getSaveData();
        const firmaBase64 = current.getDataURL("png");
        setSaved(true);
        setFormData(prev => {
            prev.firma = firmaData;
            prev.firma_base64 = firmaBase64;
            return prev;
        })
        setChecks(true);
    }
    const deleteSign = () => {
        const { current } = signRef;
        if (!current) return;
        setSaved(false);
        setChecks(false)
        current.clear();
    }
    return (
        <Row className='justify-content-center'>
            <Col sm="auto">
                <Row className='my-1'>
                    <Col sm>
                        <Button size='sm' variant={saved ? "success" : "primary"} onClick={getSign}  >
                            {
                                saved  ? (
                                    <>
                                        Firma Guardada
                                    </>
                                ) : (
                                    <>
                                        Guardar Firma
                                    </>
                                )
                            }
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col sm>
                        <Button size='sm' variant='danger' onClick={deleteSign}  >
                            Borrar Firma
                        </Button>
                    </Col>
                </Row>
            </Col>
            <Col sm="auto">
                <CanvasDraw willReadFrequently {...(inform?.firma && ({ saveData: inform?.firma }))} ref={signRef} canvasWidth={600} brushRadius={4} lazyRadius={0} gridLineWidth={1} gridColor='#333' />
            </Col>
        </Row>

    )
}

import React from 'react'
import { useAuth, useAxios } from '../../../contexts';
import { useEffect } from 'react';
import { useAlert, useForm, useNotify, usePermissions } from '../../../hooks';
import { GESTION_PROCEDIMIENTOS } from '../../../utils';
import { useState } from 'react';
import { ProceduresTable } from '../../../components/controllers/procedures';
import { faPlus, faSync } from '@fortawesome/free-solid-svg-icons';
import { Button, Col, Form, FormControl, FormSelect, Modal, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Loader } from '../../../components/Loader';

export const Procedures = () => {
  const { user } = useAuth();
  const { validateRoutePermission } = usePermissions();
  const [loading, setLoading] = useState();
  const { privateFetch } = useAxios();
  const [procedures, setProcedures] = useState([]);
  const [showAddItem, setShowAddItem] = useState(false);
  const handleShowCreate = () => setShowAddItem(true);
  const handleCloseCreate = () => setShowAddItem(false);
  const { serialize } = useForm();
  const { notify, DelAlert } = useNotify();
  const { Alert } = useAlert();
  const [editProcedureState, setEditProcedureState] = useState();
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [sLoading, setSloading] = useState(false);
  const handleCloseEdit = () => setShowModalEdit(false);

  useEffect(() => {
    validateRoutePermission(user, GESTION_PROCEDIMIENTOS);
    document.title = "Procedimientos";
    getProcedures();
    return () => {
      setProcedures([]);
    }
    // eslint-disable-next-line
  }, [])

  const getProcedures = async () => {
    setLoading(true);
    const { data } = await privateFetch.get("/api/procedures/getProcedures");
    setProcedures(data.procedure);
    setLoading(false);
  };

  const saveProcedures = async (ev) => {
    ev.preventDefault();
    setLoading(true);
    const formData = serialize(ev.target);
    const { data } = await privateFetch.post("/api/procedures/SaveProcedure", formData);
    notify({
      ...data,
      callback: () => {
        ev.target.reset();
      },
    });
    getProcedures();
    setShowAddItem(false);
  }

  const openEdit = async (id) => {
    const eProcedure = procedures.filter(val => val.id === id)?.[0];
    setEditProcedureState(eProcedure);
    setShowModalEdit(true)
  }

  const editProcedure = async (ev) => {
    ev.preventDefault();
    setSloading(true);
    const formData = serialize(ev.target);
    const { data } = await privateFetch.put("/api/procedures/editProcedure/" + editProcedureState?.id, formData);
    notify({
      ...data,
      callback: () => {
        ev.target.reset();
        setEditProcedureState();
      },
    });
    getProcedures();
    setShowModalEdit(false);
    setSloading(false)
  }

  const deleteProcedure = async (id) => {
    await DelAlert({
      text: "¿Desea eliminar el procedimiento?",
      onConfirm: async () => {
        const { data } = await privateFetch.delete(
          "/api/procedures/deleteProcedure/" + id
        );
        notify(data);
        getProcedures();
      },
      onCancel: () => {
        Alert({
          type: "warning",
          text: "Eliminación cancelada",
        });
      },
    });
  };


  return loading ? (
    <Loader />
  ) : (
    <>
      <Row className="mb-2">
        <Col sm="auto">
          <Button size="sm" variant="primary" onClick={getProcedures}>
            <FontAwesomeIcon icon={faSync} />
          </Button>
        </Col>
        <Col sm="auto">
          <Button size="sm" variant="primary" onClick={handleShowCreate}>
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </Col>
      </Row>
      <ProceduresTable data={procedures} onDelete={deleteProcedure} onEdit={openEdit} />
      <Modal show={showAddItem} onHide={handleCloseCreate}>
        <Modal.Header closeButton>
          <Modal.Title>Agregar Procedimiento</Modal.Title>
        </Modal.Header>
        {!loading ? (
          <Form onSubmit={saveProcedures} autoComplete='off'>
            <Modal.Body>
              <Row>
                <Col sm>
                  <label>Nombre</label>
                  <FormControl size="sm" type="text" name="nombre" required />
                </Col>
              </Row>
              <Row>
                <Col sm>
                  <label>Codigo</label>
                  <FormControl size="sm" type="text" name="codigo" required />
                </Col>
              </Row>
              <Row>
                <Col sm>
                  <label>Precio</label>
                  <FormControl size="sm" type="number" name="precio" required />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label>Estado</label>
                  <FormSelect defaultValue="" size="sm" required name="estado">
                    <option value="">Seleccione</option>
                    {[{ id: true, nombre: "Activo" }, { id: false, nombre: "Inactivo" }].map((val, id) => (
                      <option key={id} value={val.id}>
                        {val.nombre}
                      </option>
                    ))}
                  </FormSelect>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button size="sm" variant="secondary" onClick={handleCloseCreate}>
                Cerrar
              </Button>
              <Button type="submit" size="sm" variant="primary">
                Guardar
              </Button>
            </Modal.Footer>
          </Form>
        ) : (
          <Loader />
        )}
      </Modal>
      <Modal show={showModalEdit} onHide={handleCloseEdit}>
        <Modal.Header closeButton>
          <Modal.Title>Editar procedimiento</Modal.Title>
        </Modal.Header>
        <Form onSubmit={editProcedure} autoComplete="off">
          <Modal.Body>
            <Row>
              <Col sm>
                <label>Nombre</label>
                <FormControl size="sm" type="text" name="nombre" defaultValue={editProcedureState?.nombre} required />
              </Col>
            </Row>
            <Row>
              <Col sm>
                <label>Codigo</label>
                <FormControl size="sm" type="text" name="codigo" defaultValue={editProcedureState?.codigo} required />
              </Col>
            </Row>
            <Row>
              <Col sm>
                <label>Precio</label>
                <FormControl size="sm" type="text" name="precio" defaultValue={editProcedureState?.precio} required />
              </Col>
            </Row>
            <Row>
              <Col>
                <label>Estado</label>
                <FormSelect defaultValue={editProcedureState?.estado} size="sm" name="estado">
                  <option value="">Seleccione</option>
                  {[{ id: true, nombre: "Activo" }, { id: false, nombre: "Inactivo" }].map((val, id) => (
                    <option key={id} value={val.id}>
                      {val.nombre}
                    </option>
                  ))}
                </FormSelect>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button size="sm" variant="secondary" onClick={handleCloseEdit}>
              Cerrar
            </Button>
            <Button
              type="submit"
              size="sm"
              variant="primary"
              disabled={sLoading}
            >
              {sLoading ? <></> : <>Guardar</>}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Container, FormControl, Row, Form, Spinner, FormSelect } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { Loader } from '../../Loader/Loader';
import { useAuth, useAxios } from '../../../contexts';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, useNotify, usePermissions } from '../../../hooks';
import { GESTION_EQUIPOS } from '../../../utils';
const estados = [
  {
    id: 0,
    nombre: "Inactivo"
  },
  {
    id: 1,
    nombre: "Activo"
  },
  {
    id: 2,
    nombre: "Por reparar"
  },
  {
    id: 3,
    nombre: "Reparado"
  },
  {
    id: 4,
    nombre: "En bodega"
  }
];

export const EditEquipment = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [spinnerLoading, setSpinnerLoading] = useState(false);
  const [equipment, setEquiment] = useState({});
  const { privateFetch } = useAxios();
  const navigate = useNavigate();
  const { validateRoutePermission } = usePermissions();
  const { serialize } = useForm();
  const { notify } = useNotify();
  const [store, setStore] = useState([]);
  const [inStore, setInStore] = useState(false);
  const [clientes, setClientes] = useState([]);
  const [tipos, setTipos] = useState([]);


  useEffect(() => {
    if ([2, 3, 4].includes(equipment?.estado)) setInStore(true);
  }, [equipment]);

  useEffect(() => {
    document.title = "Editar Equipo";
    if (!id) navigate("/home");
    validateRoutePermission(user, GESTION_EQUIPOS);
    getEquipment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const EditEquipment = async (ev) => {
    ev.preventDefault();
    setSpinnerLoading(true);
    const formData = serialize(ev.target);
    if (inStore) {
      formData.establecimiento = null;
    } else {
      formData.bodega_id = null;
    }
    const { data } = await privateFetch.put(`/api/equipment/editEquipment/${id}`, formData);
    notify({
      ...data
    })
    setSpinnerLoading(false);
  };
  const setChangeStatus = (ev) => {
    if ([2, 3, 4].includes(parseInt(ev.target?.value ?? "1"))) { setInStore(true); return; }
    setInStore(false);

  }

  const getEquipment = async () => {
    setLoading(true);
    const { data: equipment } = await privateFetch.get("/api/equipment/getEquipments/" + id);
    const { data } = await privateFetch.get(`api/store/getStores`);
    const { data: clients } = await privateFetch.get(`/api/clients/getClients`);
    const { data: tiposDB } = await privateFetch.get("api/equipment/getTipos");
    setStore(data.store);
    setTipos(tiposDB.tipos);
    setEquiment(equipment.equipment);
    setClientes(clients.clients);
    setLoading(false);
  };

  return (
    <>
      <Container fluid className='mt-2'>
        <Card>
          <Card.Header>Editar Equipo</Card.Header>
          {!loading ? (
            <Form onSubmit={EditEquipment} autoComplete='off'>
              <Card.Body>
                <Row>
                  {
                    inStore && (
                      <Col sm>
                        <label>Bodega</label>
                        <FormSelect defaultValue={equipment?.bodega_id} size="sm" name="bodega_id" required >
                          <option value="">Seleccione</option>
                          {store.map((val, id) => (
                            <option key={id} value={val.id}>
                              {val?.nombre}
                            </option>
                          ))}
                        </FormSelect>
                      </Col>
                    )
                  }
                  <Col>
                    <label>Estado</label>
                    <FormSelect defaultValue={equipment?.estado} size="sm" required name="estado" onChange={setChangeStatus}>
                      <option value="">Seleccione</option>
                      {estados.map((val, id) => (
                        <option key={id} value={val.id}>
                          {val?.nombre}
                        </option>
                      ))}
                    </FormSelect>
                  </Col>
                  <Col sm>
                    <label>Capacidad (Pies)</label>
                    <FormControl size="sm" type="text" name="capacidad_pies" defaultValue={equipment?.capacidad_pies} />
                  </Col>
                  <Col sm>
                    <label>Placa</label>
                    <FormControl size="sm" type="text" name="placa" defaultValue={equipment?.placa} />
                  </Col>
                </Row>
                <Row>
                  <Col sm>
                    <label>Cliente</label>
                    <FormSelect size="sm" name="cliente_id" required defaultValue={equipment?.cliente_id}>
                      <option value="">Seleccione</option>
                      {clientes.map((val, id) => (
                        <option key={id} value={val.id}>
                          {val?.nombre}
                        </option>
                      ))}
                    </FormSelect>
                  </Col>
                  <Col sm>
                    <label>Ubicacion</label>
                    <FormControl size="sm" type="text" name="lugar_ubicacion" defaultValue={equipment?.lugar_ubicacion} />
                  </Col>
                  <Col sm>
                    <label>Responsable</label>
                    <FormControl size="sm" type="text" name="responsable" defaultValue={equipment?.responsable} />
                  </Col>
                  <Col sm>
                    <label>Tipo</label>
                    <FormSelect defaultValue={equipment?.tipo_id} size="sm" required name="tipo_id">
                      <option value="">Seleccione</option>
                      {tipos.map((val, id) => (
                        <option key={id} value={val.id}>
                          {val?.nombre}
                        </option>
                      ))}
                    </FormSelect>
                  </Col>
                </Row>
                {
                  ([0, 1].includes(equipment?.estado) || !inStore) && (
                    <Row>
                      <Col className='mt-2'>
                        <label>Establecimiento</label>
                        <FormControl
                          as="textarea"
                          size="sm"
                          type="text"
                          name="establecimiento"
                          defaultValue={equipment?.establecimiento}
                          required
                        />
                      </Col>
                    </Row>
                  )
                }
              </Card.Body>
              <Card.Footer>
                <Button type='submit' size='sm' variant='primary'>
                  {
                    spinnerLoading ? (
                      <>
                        <Spinner animation="grow" variant="warning" />
                      </>
                    ) : (
                      <>
                        <FontAwesomeIcon icon={faSave} /> &nbsp;
                        Guardar
                      </>
                    )
                  }
                </Button>
              </Card.Footer>
            </Form>
          ) : (
            <Loader />
          )}
        </Card>
      </Container >
    </>
  )
}

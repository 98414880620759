import { faMinus, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, FormSelect, Row, Table, Modal, FormControl } from 'react-bootstrap'

export const Averias = ({ edit, averias, appliedAverias, setAverias, inform, setChecks, setTouched }) => {
  const [showModal, setShowModal] = useState(false);
  const averiasRef = useRef();
  const cantidadRef = useRef();
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const [notAverias, setNotAverias] = useState(false);
  useEffect(() => {
    setAppliedFromSaved();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setTouched(true);
    if (appliedAverias?.length === 0) return setChecks(false);
    setChecks(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appliedAverias])
  useEffect(() => {
    if (notAverias) setChecks(true)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notAverias]);
  const setAppliedFromSaved = () => {
    if (edit) {
      const averias = inform?.main_informe_invs?.filter(val => parseInt(val?.inv?.tipo ?? "0") === 2)
      const allCopy = [];
      for (let j = 0; j < averias?.length; j++) {
        const element = averias?.[j];
        allCopy.push({
          id: element?.id,
          inv_id: element?.inv_id,
          nombre: element?.inv?.nombre,
          codigo: element?.inv?.codigo,
          cantidad: element?.cantidad ?? 1,
          type: 2
        })
        setAverias(allCopy)
      }
    }
  }
  const addItem = (ev) => {
    const { current: averiasR } = averiasRef;
    const { current: cantidad } = cantidadRef;
    if ([null, ""].includes(averiasR?.value) || [null, ""].includes(cantidad?.value)) return;
    const value = parseInt(averiasR?.value ?? "0");
    const index = appliedAverias?.findIndex((val) => val.inv_id === value);
    if (index !== -1) {
      setAverias(prev => {
        const copy = [...prev];
        copy[index].cantidad += (parseInt(cantidad?.value ?? "0"));
        return copy;
      })
    } else {
      const actualProcedure = averias?.filter(val => val.id === value)?.[0];
      const copy = [...appliedAverias];
      copy.push({
        id: null,
        inv_id: actualProcedure?.id,
        nombre: actualProcedure?.nombre,
        codigo: actualProcedure?.codigo,
        cantidad: parseInt(cantidad?.value ?? "0"),
        type: 2
      })
      setAverias(copy);
    }
    averias.value = "";
    cantidad.value = "";
    handleClose();
  }
  const deleteItem = (key) => {
    const proceduresF = appliedAverias.filter((val, keyA) => keyA !== key);
    setAverias(proceduresF);
  }
  const lessOne = (key) => {
    const copy = [...appliedAverias];
    if (copy?.[key]?.cantidad === 1) return;
    copy[key].cantidad -= 1;
    setAverias(copy)
  }
  return (
    <>
      <Row>
        <Col sm={4}>
          <Button type='button' size='sm' variant='success' onClick={handleShow} >
            Añadir averia <FontAwesomeIcon icon={faPlus} />
          </Button>
        </Col>
        {
          appliedAverias?.length === 0 && (
            <Col sm="auto">
              <Button size="sm" variant='warning' onClick={e => setNotAverias(true)} >
                NO APLICA
              </Button>
            </Col>
          )
        }
      </Row>
      <Row className='mt-3'>
        <Col sm>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>#</th>
                <th>Nombre</th>
                <th>Cantidad</th>
                <th>Código</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {
                appliedAverias?.map((val, key) => (
                  <tr key={key}>
                    <td>
                      {key + 1}
                    </td>
                    <td>
                      {val?.nombre}
                    </td>
                    <td>
                      {val?.cantidad}
                    </td>
                    <td>
                      {val?.codigo}
                    </td>
                    <td>
                      {
                        val?.cantidad > 1 && (
                          <Button variant='warning' size="sm" onClick={e => lessOne(key)}>
                            <FontAwesomeIcon icon={faMinus} />
                          </Button>
                        )
                      }
                      <Button variant='danger' size="sm" onClick={e => deleteItem(key)}>
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </Button>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </Table>
        </Col>
      </Row>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Añadir producto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm>
              <label>Averias</label>
              <FormSelect size='sm' ref={averiasRef}>
                <option value="">Seleccione</option>
                {
                  averias?.map((val, index) => (
                    <option key={index} value={val?.id}>{val?.nombre}</option>
                  ))
                }
              </FormSelect>
            </Col>
          </Row>
          <Row>
            <Col sm>
              <label>Cantidad</label>
              <FormControl size='sm' type='number' ref={cantidadRef} />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button size='sm' variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
          <Button size='sm' type='button' variant="primary" onClick={addItem}>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

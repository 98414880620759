import moment from "moment-timezone";
import * as xlsx from "xlsx";
export const useXLSX = () => {
    const generateInvoice = (data) => {
        //Crear el libro
        const wb = xlsx.utils.book_new();
        //Crear la hoja
        const ws1 = xlsx.utils.json_to_sheet(data?.detallado ?? []);
        ws1["!cols"] = [{ wch: 20 }];
        xlsx.utils.book_append_sheet(wb, ws1, "Lista Detallada");
        const ws2 = xlsx.utils.aoa_to_sheet([
            ["ServicoldMD SAS", "Ciudad:", data?.factura?.ciudad],
            [],
            ["Fecha inicial", "Fecha final", "", "Factura #"],
            [data?.factura?.fecha_inicial, data?.factura?.fecha_final, "", data?.factura?.numero]
        ])
        ws2["!cols"] = [{ wch: 30 }];
        xlsx.utils.sheet_add_json(ws2, data?.factura?.lineas, { origin: "A6" });
        const maxRange = (6 + Object.keys(data?.factura?.lineas)?.length ?? 0);
        xlsx.utils.sheet_add_aoa(ws2, [["Subtotal"]], { origin: "D" + (maxRange + 2) });
        xlsx.utils.sheet_add_aoa(ws2, [["Iva"]], { origin: "D" + (maxRange + 3) });
        xlsx.utils.sheet_add_aoa(ws2, [["Total"]], { origin: "D" + (maxRange + 4) });
        xlsx.utils.sheet_add_aoa(ws2, [[{ t: "n", v: 3, f: `SUM(E7:E${maxRange})` }]], { origin: "E" + (maxRange + 2) });
        xlsx.utils.sheet_add_aoa(ws2, [[{ t: "n", v: 3, f: `=E${maxRange + 2}*0.19` }]], { origin: "E" + (maxRange + 3) });
        xlsx.utils.sheet_add_aoa(ws2, [[{ t: "n", v: 3, f: `=SUM(E${maxRange + 2}:E${maxRange + 3})` }]], { origin: "E" + (maxRange + 4) });
        xlsx.utils.book_append_sheet(wb, ws2, "Factura");
        const fileName = `Factura_ServicoldMD_${new Date().toISOString()}.xlsx`;
        xlsx.writeFile(wb, fileName);
    }
    const generateInform = (filter, data = []) => {
        //Crear el libro
        const wb = xlsx.utils.book_new();
        //Crear la hoja
        const ws1 = xlsx.utils.aoa_to_sheet([
            [`Informe de reportes`, `Filtro: ${JSON.stringify(filter)}`],
        ])
        xlsx.utils.sheet_add_json(ws1, data ?? [], { origin: 'A2' });
        ws1["!cols"] = [{ wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }];
        xlsx.utils.book_append_sheet(wb, ws1, "Informes");
        const fileName = `Informes_${moment().tz("America/Bogota").format("YYYYMMDDHHmmss")}.xlsx`;
        xlsx.writeFile(wb, fileName);
    }
    const getExtention = (filename) => {
        return filename?.split('.').pop()?.toString() || '';
    }
    const generateInventoryFile = (data) => {
        const wb = xlsx.utils.book_new();
        const ws = xlsx.utils.aoa_to_sheet([
            [`Informe de inventario`, `${moment().tz("America/Bogota").format("YYYY-MM-DD HH:mm:ss")}`],
        ])
        xlsx.utils.sheet_add_json(ws, data ?? [], { origin: 'A2' });
        xlsx.utils.book_append_sheet(wb, ws, "Inventario");
        const fileName = `Informe_inventario_${moment().tz("America/Bogota").format("YYYYMMDDHHmmss")}.xlsx`;
        xlsx.writeFile(wb, fileName);
    }
    return {
        generateInvoice,
        getExtention,
        generateInform,
        generateInventoryFile
    }
}

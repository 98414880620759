import React from 'react'
import "./style.css"
import { useNavigate } from 'react-router-dom'
import { Button, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';

export const Error404 = () => {
	const navigate = useNavigate();
	const goHome = () => {
		navigate("/home")
	}
	return (
		<>
			<div className="container not-found-container  align-items-center justify-content-center">
				<div className="row">
					<div className="col-md-12 text-center">
						<h1 className="display-1">404</h1>
						<h2 className="display-4">Página no encontrada</h2>
						<p className="lead">Lo sentimos, la página que estás buscando no se encuentra disponible o NO existe.</p>
					</div>
				</div>
				<Row className='justify-content-center'>
					<Col sm="auto">
						<Button size="sm" onClick={goHome}>
							<FontAwesomeIcon icon={faHome} />
						</Button>
					</Col>
				</Row>
			</div>
		</>
	)
}

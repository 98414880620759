import React, { useEffect, useState } from 'react'
import { useAuth, useAxios } from '../../../contexts'
import { useForm, useFormat, useNotify, usePermissions, useXLSX } from '../../../hooks';
import { EDITAR_FACTURAS } from '../../../utils';
import { Container, Row, Col, FormSelect, FormControl, Form, Button, Spinner } from "react-bootstrap";
import { InvoiceEditTable, Loader } from '../../../components';
import { useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
const states = [
  {
    id: 1,
    nombre: "Generada",
  },
  {
    id: 2,
    nombre: "Aprobado",
  },
  {
    id: 3,
    nombre: "Facturado",
  },
  {
    id: 4,
    nombre: "En revisión",
  },
];

export const EditInvoice = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const { validateRoutePermission } = usePermissions();
  const [loading, setLoading] = useState(false);
  const { privateFetch } = useAxios();
  const [clientes, setClientes] = useState([]);
  const [invoice, setInvoices] = useState({});
  const [informes, setInformes] = useState([]);
  const navigate = useNavigate();
  const [loadingSave, setLoadingSave] = useState(false);
  const [showBtn, setShowBtn] = useState(false);
  const { serialize } = useForm();
  const { notify } = useNotify();
  const [cities, setCities] = useState([]);
  const [loadingXLSX, setLoadingXLSX] = useState(false);
  const { formatNumber } = useFormat();
  const { generateInvoice } = useXLSX();
  useEffect(() => {
    validateRoutePermission(user, EDITAR_FACTURAS);
    document.title = "Editar Factura";
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getData = async () => {
    setShowBtn(false)
    setLoading(true);
    const [
      { data },
      { data: clients },
      { data: citiesS }
    ] = await Promise.all([
      privateFetch.get("/api/invoices/getInvoices/" + id),
      privateFetch.get(`/api/clients/getClients`),
      privateFetch.get(`/api/location/getCities`),
    ]);
    setCities(citiesS?.cities);
    setInformes(data?.invoice?.main_informe_tecs ?? []);
    setClientes(clients?.clients);
    setInvoices(data?.invoice);
    setLoading(false);
  }
  const seeInform = (idI) => {
    navigate("/inform/edit-inform/" + idI)
  }
  const saveInvoice = async (ev) => {
    ev.preventDefault();
    setLoadingSave(true);
    const formData = serialize(ev.target);
    const { data } = await privateFetch.put("/api/invoices/updateInvoice/" + id, formData);
    notify(data);
    getData();
    setLoadingSave(false);
  }
  const generateExcel = async (ev) => {
    setLoadingXLSX(true);
    const { data } = await privateFetch.post("/api/invoices/getInvoiceXLSX/" + id);
    generateInvoice(data?.data);
    setLoadingXLSX(false);
  }
  const showSaveBtn = (ev) => {
    if (!ev?.target) return;
    if (parseInt(ev?.target?.value ?? "0") !== invoice?.estado) return setShowBtn(true);
    setShowBtn(false);
  }
  return loading ? (
    <Loader />
  ) : (
    (
      <Container fluid className='mt-2'>
        <Row>
          <Col sm>
            <label>Cliente</label>
            <FormSelect size='sm' defaultValue={invoice?.cliente_id} disabled={!loading} >
              <option value="">Seleccione</option>
              {
                clientes?.map((val) => (
                  <option key={val?.id} value={val?.id}>{val?.nombre}</option>
                ))
              }
            </FormSelect>
          </Col>
          <Col sm>
            <label>Fecha inicial</label>
            <FormControl size='sm' type='date' disabled={!loading} defaultValue={invoice?.fecha_inicial} />
          </Col>
          <Col sm>
            <label>Fecha final</label>
            <FormControl size='sm' type='date' disabled={!loading} defaultValue={invoice?.fecha_final} />
          </Col>
          <Col sm>
            <label>Fecha factura</label>
            <FormControl size='sm' type='date' disabled={!loading} defaultValue={invoice?.fecha} />
          </Col>
        </Row>
        <Form onSubmit={saveInvoice}>
          <Row>
            <Col sm={3}>
              <label>Ciudad</label>
              <FormSelect size='sm' defaultValue={invoice?.city_id} disabled={!loading} >
                <option value="">Seleccione</option>
                {
                  cities?.map((val) => (
                    <option key={val?.id} value={val?.id}>{val?.nombre}</option>
                  ))
                }
              </FormSelect>
            </Col>
            <Col sm={3}>
              <label>Estado</label>
              <FormSelect size='sm' name='estado' defaultValue={invoice?.estado} disabled={loading} onChange={showSaveBtn} >
                <option value="">Seleccione</option>
                {
                  states?.filter(val => {
                    if (user?.rol_id === 4) return [1, 2].includes(val?.id);
                    return true;
                  })?.map((val) => (
                    <option key={val?.id} value={val?.id}>{val?.nombre}</option>
                  ))
                }
              </FormSelect>
            </Col>
            <Col sm="auto">
              <br />
              <Button type='button' variant='success' size='sm' onClick={generateExcel} disabled={loadingXLSX} >
                {
                  loadingXLSX ? (
                    <>
                      <Spinner animation='grow' size='sm' variant='warning' /> &nbsp; Generando excel...
                    </>
                  ) : (
                    <>
                      <FontAwesomeIcon icon={faFileExcel} />
                    </>
                  )
                }
              </Button>
            </Col>
            {
              showBtn && (
                <Col sm>
                  <br />
                  <Button size="sm" type='submit' disabled={loadingSave || !showBtn}>
                    Guardar
                  </Button>
                </Col>
              )
            }
          </Row>
        </Form>
        <Row className='mt-3 justify-content-start'>
          <Col sm={2}>
            <strong> SubTotal:</strong>
          </Col>
          <Col sm={2} >
            {
              formatNumber(invoice?.subtotal)
            }
          </Col>
          <Col sm="auto">
            COP
          </Col>
        </Row>
        <Row className='mt-1 justify-content-start'>
          <Col sm={2}>
            <strong> IVA:</strong>
          </Col>
          <Col sm={2}>
            {
              formatNumber(invoice?.iva)
            }
          </Col>
          <Col sm="auto">
            COP
          </Col>
        </Row>
        <Row className='mt-1 justify-content-start'>
          <Col sm={2}>
            <strong> Total:</strong>
          </Col>
          <Col sm={2}>
            {
              formatNumber(invoice?.total)
            }
          </Col>
          <Col sm="auto">
            COP
          </Col>
        </Row>
        <Row className='mt-3'>
          <Col sm>
            <h3>Servicios</h3>
          </Col>
        </Row>
        <InvoiceEditTable data={informes} onView={seeInform} />
      </Container>
    )
  )
}

import React, { useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import { EditUsers } from './EditUsers';
import { ManagePermissions } from './ManagePermissions';
import { useAuth } from '../../../contexts';
import { usePermissions } from '../../../hooks';
import { GESTION_USUARIOS } from '../../../utils';

export const TabsEditUser = () => {
    const { user } = useAuth();
    const [key, setKey] = useState('profile');
    const { validate } = usePermissions();

    return (
        <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3 mt-4"
        >
            <Tab eventKey="profile" title="Perfil">
                <EditUsers />
            </Tab>
            <Tab eventKey="permissions" title="Permisos">
                {
                    validate(user, GESTION_USUARIOS) && (
                        <ManagePermissions />
                    )
                }
            </Tab>
        </Tabs>
    )
}

export const useFormat = () => {
    const formatNumber = (number, fractions = 2) => {
        return (new Intl.NumberFormat('es-CO', { maximumFractionDigits: fractions }).format(
            number ?? 0
        ))
    }
    return {
        formatNumber
    }
}

import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, FormControl, Button, Spinner } from "react-bootstrap";
import { useAxios } from '../../../contexts';
import noImage from "../../../assets/img/no_image.png"

export const DamagePhoto = ({ inform, setFormData, setChecks, setTouched, id }) => {
    const [saved, setSaved] = useState(false);
    const [img2Show, setImg2Show] = useState(null);
    const [file, setFile] = useState(null);
    const [fileUrl, setFileUrl] = useState(null)
    const [loadingImg, setLoadingImg] = useState(false);
    const fileRef = useRef();
    const { privateFetch } = useAxios();
    useEffect(() => {
        setTouched(true);
        if (inform?.foto_placa && typeof inform?.foto_placa === "string") {
            getSavedFoto();
            setChecks(true);
            setSaved(true);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saved])
    const getSavedFoto = async () => {
        setLoadingImg(true);
        const { data, status } = await privateFetch.get(inform?.foto_placa, {
            responseType: 'blob',
        });
        if (status === 200) {
            const reader = new window.FileReader();
            reader.readAsDataURL(data);
            reader.onload = () => {
                setImg2Show(reader.result);
            }
        }
        setLoadingImg(false);
    }
    const setImage = async () => {
        setLoadingImg(true);
        if (!file) return;
        const formData = new FormData();
        formData.append("foto_placa", file);
        const { data, status } = await privateFetch.post("/api/informs/editImage/" + id, formData, {
            headers: {
                "Content-type": "multipart/form-data"
            }
        });
        if (status === 200 && data?.code === 200) {
            setSaved(true);
            setChecks(true);
            setFormData(prev => {
                prev.foto_placa = data?.image;
                return prev;
            })
        }
        setLoadingImg(false);

    }
    const setImageState = (e) => {
        if (e?.target?.value !== "") {
            setLoadingImg(true);
            setFileUrl(URL.createObjectURL(e?.target?.files?.[0]));
            setFile(e?.target?.files?.[0])
            setLoadingImg(false);
        }
    }
    const deleteImage = async (e) => {
        setLoadingImg(true);
        if (!file && !img2Show) return;
        const formData = new FormData();
        formData.append("foto_placa", file);
        const { data, status } = await privateFetch.delete("/api/informs/deleteImage/" + id);
        console.log(data)
        if (status === 200 && data?.code === 200) {
            setSaved(false);
            setChecks(false);
            setFile(null);
            setFileUrl(null);
            setFormData(prev => {
                prev.foto_placa = null;
                return prev;
            })
            setImg2Show(null);
            if (fileRef.current) fileRef.current.value = "";
        }
        setLoadingImg(false);
    }
    return (
        <Row className='justify-content-center'>
            <Col sm="auto">
                <Row className='my-1'>
                    <Col sm>
                        <label>Imagen</label>
                        <FormControl ref={fileRef} size='sm' type='file' accept='.png,.jpg,.jpeg,.webp' onChange={setImageState} />
                        <small>Foto de la placa del congelador</small> <small className='text-danger'>*</small>
                    </Col>
                </Row>
                <Row>
                    <Col sm>
                        <Button size='sm' variant={saved ? 'success' : 'warning'} onClick={e => !saved ? setImage(e) : null}>
                            {
                                saved ? "Imagen guardada" : "Guardar imagen"
                            }
                        </Button>
                    </Col>
                    <Col sm>
                        <Button size='sm' variant='danger' onClick={deleteImage}>
                            Borrar imagen
                        </Button>
                    </Col>
                </Row>
            </Col>
            <Col sm="auto">
                {
                    loadingImg ? (
                        <>
                            <Spinner animation='grow' size='sm' variant='warning' />
                        </>
                    ) : (
                        <>
                            <img width="500px" src={img2Show ?? fileUrl ?? noImage} alt='foto' />
                        </>
                    )
                }
            </Col>
        </Row>
    )
}

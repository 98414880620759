import React, { useEffect, useState } from 'react'
import { useAuth, useAxios } from '../../../contexts'
import { useForm, useNotify, usePermissions } from '../../../hooks';
import { EDITAR_FACTURAS } from '../../../utils';
import { Container, Row, Col, FormSelect, FormControl, Form, Button } from "react-bootstrap";
import { CCEditTable, Loader } from '../../../components';
import { useParams, useNavigate } from 'react-router-dom';
const states = [
  {
    id: 1,
    nombre: "Generada",
  },
  {
    id: 2,
    nombre: "Aprobado",
  },
  {
    id: 3,
    nombre: "Pagado",
  },
];

export const EditCC = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const { validateRoutePermission } = usePermissions();
  const [loading, setLoading] = useState(false);
  const { privateFetch } = useAxios();
  const [clientes, setClientes] = useState([]);
  const [invoice, setInvoices] = useState({});
  const [informes, setInformes] = useState([]);
  const navigate = useNavigate();
  const [loadingSave, setLoadingSave] = useState(false);
  const [showBtn, setShowBtn] = useState(false);
  const { serialize } = useForm();
  const { notify } = useNotify();
  useEffect(() => {
    validateRoutePermission(user, EDITAR_FACTURAS);
    document.title = "Editar Cuenta de cobro";
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getData = async () => {
    setShowBtn(false)
    setLoading(true);
    const { data } = await privateFetch.get("/api/caccounts/getCC/" + id);
    setInformes(data?.cc?.main_informe_tecs ?? []);
    const { data: users } = await privateFetch.post("/api/users/getUsersFiltered", { rol_id: 2 });
    setClientes(users?.users);
    setInvoices(data?.cc);
    setLoading(false);
  }
  const seeInform = (idI) => {
    navigate("/inform/edit-inform/" + idI)
  }
  const saveInvoice = async (ev) => {
    ev.preventDefault();
    setLoadingSave(true);
    const formData = serialize(ev.target);
    const { data } = await privateFetch.put("/api/caccounts/updateCC/" + id, formData);
    notify(data);
    getData();
    setLoadingSave(false);
  }
  const showSaveBtn = (ev) => {
    if (!ev?.target) return;
    if (parseInt(ev?.target?.value ?? "0") !== invoice?.estado) return setShowBtn(true);
    setShowBtn(false);
  }
  return loading ? (
    <Loader />
  ) : (
    (
      <Container fluid className='mt-2'>
        <Row>
          <Col sm>
            <label>Usuario</label>
            <FormSelect size='sm' defaultValue={invoice?.user_id} disabled={!loading} >
              <option value="">Seleccione</option>
              {
                clientes?.map((val) => (
                  <option key={val?.id} value={val?.id}>{val?.nombre}</option>
                ))
              }
            </FormSelect>
          </Col>
          <Col sm>
            <label>Fecha inicial</label>
            <FormControl size='sm' type='date' disabled={!loading} defaultValue={invoice?.desde} />
          </Col>
          <Col sm>
            <label>Fecha final</label>
            <FormControl size='sm' type='date' disabled={!loading} defaultValue={invoice?.hasta} />
          </Col>
          <Col sm>
            <label>Fecha factura</label>
            <FormControl size='sm' type='date' disabled={!loading} defaultValue={invoice?.fecha} />
          </Col>
        </Row>
        <Form onSubmit={saveInvoice}>
          <Row>
            <Col sm={3}>
              <label>Estado</label>
              <FormSelect size='sm' name='estado' defaultValue={invoice?.estado} disabled={loading} onChange={showSaveBtn} >
                <option value="">Seleccione</option>
                {
                  states?.filter(val => {
                    if (user?.rol_id === 2) return [1, 2].includes(val?.id);
                    return true;
                  })?.map((val) => (
                    <option key={val?.id} value={val?.id}>{val?.nombre}</option>
                  ))
                }
              </FormSelect>
            </Col>
            {
              showBtn && (
                <Col sm>
                  <br />
                  <Button size="sm" type='submit' disabled={loadingSave || !showBtn}>
                    Guardar
                  </Button>
                </Col>
              )
            }
          </Row>
        </Form>
        <Row className='mt-3 justify-content-start'>
          <Col sm={2}>
            <strong> Total:</strong>
          </Col>
          <Col sm={1} >
            {invoice?.valor?.toFixed(2)}
          </Col>
          <Col sm="auto">
            COP
          </Col>
        </Row>
        <Row className='mt-3'>
          <Col sm>
            <h3>Servicios</h3>
          </Col>
        </Row>
        <CCEditTable data={informes} onView={seeInform} />
      </Container>
    )
  )
}

import React from 'react'
import { Sidebar, NavBar } from './';


export const BasePanel = (props) => {
    const { children } = props;
    return (
        <>
            <Sidebar />
            <main className="content">
                <NavBar />
                {children}
            </main>
        </>
    )
}

import React, { useEffect, useState } from 'react'
import { EquipmentTable } from '../../../components/controllers/equipment';
import { Row, Col, Button, Modal, Form, FormControl, FormSelect, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuth, useAxios } from '../../../contexts';
import { faPlus, faSync } from '@fortawesome/free-solid-svg-icons';
import { Loader } from '../../../components';
import { useNavigate } from 'react-router-dom';
import { useAlert, useForm, useNotify, usePermissions } from '../../../hooks';
import { GESTION_EQUIPOS } from '../../../utils';

export const Equipment = () => {
  const { user } = useAuth();
  const [showAddItem, setShowAddItem] = useState(false);
  const handleShowCreate = () => setShowAddItem(true);
  const handleCloseCreate = () => setShowAddItem(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false)
  const { validateRoutePermission } = usePermissions();
  const { privateFetch } = useAxios();
  const [equipment, setEquiment] = useState([]);
  const { serialize } = useForm();
  const { notify, DelAlert } = useNotify();
  const { Alert } = useAlert();
  const [clientes, setClientes] = useState([]);
  const [tipos, setTipos] = useState([]);
  const estados = [
    {
      id: 0,
      nombre: "Inactivo"
    },
    {
      id: 1,
      nombre: "Activo"
    },
    {
      id: 2,
      nombre: "Por reparar"
    },
    {
      id: 3,
      nombre: "Reparado"
    },
    {
      id: 4,
      nombre: "En bodega"
    }
  ];


  useEffect(() => {
    validateRoutePermission(user, GESTION_EQUIPOS);
    document.title = "Equipos"
    getEquipment();
    return () => {
      setEquiment([]);
    }
    // eslint-disable-next-line
  }, [])

  const EditEquipment = (id) => {
    navigate(`/equipment/edit-equipment/${id}`)
  };

  const getEquipment = async () => {
    setLoading(true);
    const { data: equipment } = await privateFetch.get("api/equipment/getEquipments");
    const { data: tiposDB} = await privateFetch.get("api/equipment/getTipos");
    const { data: clients } = await privateFetch.get(`/api/clients/getClients`);
    setTipos(tiposDB.tipos);
    setEquiment(equipment.equipment);
    setClientes(clients.clients);
    setLoading(false);
  };

  const saveEquipment = async (ev) => {
    ev.preventDefault();
    setLoadingSave(true)
    const formData = serialize(ev.target);
    const { data } = await privateFetch.post(`api/equipment/saveEquipment`, formData);
    notify({
      ...data,
      callback: () => {
        ev.target.reset();
      }
    })
    getEquipment();
    setShowAddItem(false);
    setLoadingSave(false)
  }

  const deleteEquipment = async (id) => {
    await DelAlert({
      text: "Desea eliminar este equipo?",
      onConfirm: async () => {
        const { data } = await privateFetch.delete(
          "/api/equipment/deleteEquipment/" + id
        );
        notify(data);
        getEquipment();
      },
      onCancel: () => {
        Alert({
          type: "warning",
          text: "Eliminación cancelada",
        });
      },
    });
  };

  return loading ? (
    <Loader />
  ) : (
    <>
      <Row className="mb-2">
        <Col sm="auto">
          <Button size="sm" variant="primary" onClick={getEquipment}>
            <FontAwesomeIcon icon={faSync} />
          </Button>
        </Col>
        <Col sm="auto">
          <Button size="sm" variant="primary" onClick={handleShowCreate}>
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </Col>
      </Row>
      <EquipmentTable data={equipment} onEdit={EditEquipment} onDelete={deleteEquipment} />
      <Modal show={showAddItem} onHide={handleCloseCreate}>
        <Modal.Header closeButton>
          <Modal.Title>Nuevo Equipo</Modal.Title>
        </Modal.Header>
        {!loading ? (
          <Form onSubmit={saveEquipment} autoComplete="off">
            <Modal.Body>
              <Row>
                <Col sm>
                  <label>Cliente</label>
                  <FormSelect defaultValue="" size="sm" name="cliente_id" required >
                    <option value="">Seleccione</option>
                    {clientes.map((val, id) => (
                      <option key={id} value={val.id}>
                        {val?.nombre}
                      </option>
                    ))}
                  </FormSelect>
                </Col>
              </Row>
              <Row>
                <Col sm>
                  <label>Capacidad (Pies)</label>
                  <FormControl size="sm" type="number" name="capacidad_pies" required />
                </Col>
              </Row>
              <Row>
                <Col sm>
                  <label>Placa</label>
                  <FormControl size="sm" type="text" name="placa" required />
                </Col>
              </Row>
              <Row>
                <Col sm>
                  <label>Ubicacion</label>
                  <FormControl size="sm" type="text" name="lugar_ubicacion" required />
                </Col>
              </Row>
              <Row>
                <Col sm>
                  <label>Tipo</label>
                  <FormSelect defaultValue="" size="sm" required name="tipo_id">
                    <option value="">Seleccione</option>
                    {tipos.map((val, id) => (
                      <option key={id} value={val.id}>
                        {val?.nombre}
                      </option>
                    ))}
                  </FormSelect>
                </Col>
              </Row>
              <Row>
                <Col sm>
                  <label>Establecimiento</label>
                  <FormControl size="sm" type="text" name="establecimiento" required />
                </Col>
              </Row>
              <Row>
                <Col sm>
                  <label>Responsable</label>
                  <FormControl size="sm" type="text" name="responsable" required />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label>Estado</label>
                  <FormSelect size="sm" required name="estado" defaultValue={1}>
                    <option value="">Seleccione</option>
                    {estados.map((val, id) => (
                      <option key={id} value={val.id}>
                        {val?.nombre}
                      </option>
                    ))}
                  </FormSelect>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button size="sm" variant="secondary" onClick={handleCloseCreate}>
                Cerrar
              </Button>
              <Button type="submit" size="sm" variant="primary" disabled={loadingSave}>
               {
                loadingSave ? (
                  <>
                    <Spinner size='sm' variant='warning' animation='grow'  /> &nbsp; Cargando...
                  </>
                ) : (
                  <>
                     Guardar
                  </>
                )
               }
              </Button>
            </Modal.Footer>
          </Form>
        ) : (
          <Loader />
        )}
      </Modal>
    </>
  )
}

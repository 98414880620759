import React from 'react';
import "./Loader.css";

export const Loader = () => {
    return (
        <div id='outer'>
            <div id='middle'>
                <div id='inner'>
                </div>
            </div>
        </div>
    )
}

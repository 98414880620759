import React, { useEffect, useState } from 'react'
import { useAuth, useAxios } from '../../../contexts'
import { InvoicesTable, Loader } from '../../../components';
import { Row, Col, Button, Modal, Form, FormControl, Spinner, FormSelect } from "react-bootstrap";
import { faPlus, faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAlert, useForm, useNotify, usePermissions, useTime } from '../../../hooks';
import { GENERAR_FACTURAS } from '../../../utils';
import { useNavigate } from "react-router-dom";

export const ManageInvoices = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [loadingInv, setLoadingInv] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [cities, setCities] = useState([]);
  const { privateFetch } = useAxios();
  const [showAddInvoice, setShowAddInvoice] = useState(false);
  const handleShowAddInv = () => setShowAddInvoice(true);
  const handleCloseAddinv = () => setShowAddInvoice(false);
  const { serialize } = useForm();
  const { actualTimeCol } = useTime();
  const { validateRoutePermission } = usePermissions();
  const { notify, DelAlert } = useNotify();
  const { Alert } = useAlert();
  const navigate = useNavigate();
  useEffect(() => {
    validateRoutePermission(user, GENERAR_FACTURAS)
    document.title = "Facturas";
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const getData = async () => {
    setLoading(true);
    const [
      { data },
      { data: clients },
      { data: citiesS },
    ] = await Promise.all([
      privateFetch.get("/api/invoices/getInvoices"),
      privateFetch.get(`/api/clients/getClients`),
      privateFetch.get(`/api/location/getCities`),
    ])
    setCities(citiesS.cities)
    setClientes(clients?.clients);
    setInvoices(data?.invoice);
    setLoading(false);
  }
  const generateInvoice = async (ev) => {
    ev.preventDefault();
    const formData = serialize(ev.target);
    setLoadingInv(true);
    const { data } = await privateFetch.post("/api/invoices/generateInvoice", formData);
    notify({
      ...data,
      callback: () => {
        ev.target.reset();
      },
    });
    getData();
    handleCloseAddinv();
    setLoading(false);
    setLoadingInv(false);
  }
  const editInvoice = (id) => {
    navigate("/invoices/edit-invoice/" + id)
  }
  const deleteInvoice = async (id) => {
    await DelAlert({
      text: "Desea eliminar este elemento?",
      onConfirm: async () => {
        const { data } = await privateFetch.delete(
          "/api/invoices/deleteInvoice/" + id
        );
        notify(data);
        getData();
      },
      onCancel: () => {
        Alert({
          type: "warning",
          text: "Eliminación cancelada",
        });
      },
    });
  }
  return loading ? (
    <Loader />
  ) : (
    <>
      <Row className="mb-2">
        <Col sm="auto">
          <Button size="sm" variant="primary" onClick={getData}>
            <FontAwesomeIcon icon={faSync} />
          </Button>
        </Col>
        <Col sm="auto">
          <Button size="sm" variant="primary" onClick={handleShowAddInv}>
            Generar Factura &nbsp;
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </Col>
      </Row>
      <InvoicesTable data={invoices} onEdit={editInvoice} onDelete={deleteInvoice} />
      <Modal show={showAddInvoice} onHide={handleCloseAddinv}>
        <Modal.Header closeButton>
          <Modal.Title>Generar Factura</Modal.Title>
        </Modal.Header>
        <Form autoComplete='off' onSubmit={generateInvoice}>
          <Modal.Body>
            <Row>
              <Col sm>
                <label>Desde:</label>
                <FormControl type='date' size="sm" name="desde" required max={actualTimeCol("YYYY-MM-DD")} />
              </Col>
            </Row>
            <Row>
              <Col sm>
                <label>Hasta:</label>
                <FormControl type='date' size="sm" name="hasta" required max={actualTimeCol("YYYY-MM-DD")} />
              </Col>
            </Row>
            <Row>
              <Col sm>
                <label>Cliente</label>
                <FormSelect name="cliente_id" required size="sm">
                  <option value="">Seleccione</option>
                  {
                    clientes?.map((val) => (
                      <option key={val?.id} value={val?.id}>{val?.nombre}</option>
                    ))
                  }
                </FormSelect>
              </Col>
            </Row>
            <Row>
              <Col sm>
                <label>Ciudad</label>
                <FormSelect name="city_id" required size="sm">
                  <option value="">Seleccione</option>
                  {
                    cities?.map((val) => (
                      <option key={val?.id} value={val?.id}>{val?.nombre}</option>
                    ))
                  }
                </FormSelect>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button size="sm" variant="secondary" onClick={handleCloseAddinv}>
              Cerrar
            </Button>
            <Button size="sm" variant="primary" type='submit' disabled={loadingInv}>
              {
                loadingInv ? (
                  <>
                    <Spinner size='sm' variant="warning" animation="grow" /> Generando factura ...
                  </>
                ) : (
                  <>
                    Generar
                  </>
                )
              }
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

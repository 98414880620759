import React, { useState, useEffect } from 'react'
import { CitiesTable } from './CitiesTable'
import { useAuth, useAxios } from '../../../contexts';
import { useAlert, useForm, useNotify, usePermissions } from '../../../hooks';
import { GESTION_DEPARTAMENTOS_CIUDADES } from '../../../utils';
import { Loader } from '../../Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSync } from '@fortawesome/free-solid-svg-icons';
import { Button, Col, Form, FormControl, Row, Modal, FormSelect } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export const ManageCiudades = ({ reload }) => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const { privateFetch } = useAxios();
  const { validateRoutePermission } = usePermissions();
  const [cities, setCities] = useState([]);
  const [showAddItem, setShowAddItem] = useState(false);
  const handleShowCreate = () => setShowAddItem(true);
  const handleCloseCreate = () => setShowAddItem(false);
  const [departamentos, setDepartamentos] = useState([]);
  const { serialize } = useForm();
  const { notify, DelAlert } = useNotify();
  const { Alert } = useAlert();
  const navigate = useNavigate()


  useEffect(() => {
    validateRoutePermission(user, GESTION_DEPARTAMENTOS_CIUDADES);
    getCities();
    return () => {
      setCities();
      setLoading(false);
    }
    // eslint-disable-next-line 
  }, [reload])

  const getCities = async () => {
    setLoading(true);
    const { data: cities } = await privateFetch.get("api/location/getCities");
    const { data: deptos } = await privateFetch.get("/api/location/getDepartments");
    setCities(cities.cities);
    setDepartamentos(deptos.states)
    setLoading(false);
  }

  const saveCity = async (ev) => {
    ev.preventDefault();
    setLoading(true);
    const formData = serialize(ev.target);
    const { data } = await privateFetch.post("api/location/saveCity", formData);
    notify({
      ...data,
      callback: () => {
        ev.target.reset();
      },
    });
    getCities();
    setShowAddItem(false);
  }

  const deleteCity = async (id) => {
    await DelAlert({
      text: "¿Desea eliminar la ciudad?",
      onConfirm: async () => {
        const { data } = await privateFetch.delete(
          "/api/location/deleteCity/" + id
        );
        notify(data);
        getCities();
      },
      onCancel: () => {
        Alert({
          type: "warning",
          text: "Eliminación cancelada",
        });
      },
    });
  };

  const editCity = (id) => {
    navigate("/depto-cities/edit-city/" + id)

  };

  return loading ? (
    <Loader />
  ) : (
    <>
      <Row className="mb-2">
        <Col sm="auto">
          <Button size="sm" variant="primary" onClick={getCities}>
            <FontAwesomeIcon icon={faSync} />
          </Button>
        </Col>
       
          <Col sm="auto">
          <Button size="sm" variant="primary" onClick={handleShowCreate}>
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </Col>
         
      </Row>
      <CitiesTable data={cities} onDelete={deleteCity} onEdit={editCity} />
      <Modal show={showAddItem} onHide={handleCloseCreate}>
        <Modal.Header closeButton>
          <Modal.Title>Nueva Ciudad</Modal.Title>
        </Modal.Header>
        {!loading ? (
          <Form onSubmit={saveCity} autoComplete='off'>
            <Modal.Body>
              <Row>
                <Col sm>
                  <label>Nombre Ciudad</label>
                  <FormControl size="sm" type="text" name="nombre" required />
                </Col>
              </Row>
              <Row>
                <Col sm>
                  <label>Departamento</label>
                  <FormSelect size="sm" required name="departamento_id" defaultValue="">
                    <option value="">Seleccione</option>
                    {
                      departamentos.map((val, id) => (
                        <option key={id} value={val.id}>
                          {val.nombre}
                        </option>
                      ))}
                  </FormSelect>
                  <small>Si no encuentra su departamento por favor agréguelo</small>
                </Col>
              </Row>
              <Row>
                <Col sm>
                  <label>Código</label>
                  <FormControl size="sm" type="text" name="codigo" required />
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button size="sm" variant="secondary" onClick={handleCloseCreate}>
                Cerrar
              </Button>
              <Button type="submit" size="sm" variant="primary">
                Guardar
              </Button>
            </Modal.Footer>
          </Form>
        ) : (
          <Loader />
        )}
      </Modal>
    </>
  )
}

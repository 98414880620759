import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { SelectorChecks } from './SelectorChecks';
import { useAlert } from '../../../hooks';

export const Checks1 = ({ inform, setChecks, setTouched1, setFormData }) => {
    const { Alert } = useAlert();
    const booleans = [{ id: true, nombre: "Bueno" }, { id: false, nombre: "Malo" }];
    const [fields1, setFields1] = useState([
        {
            label: "Relay",
            name: "relay",
            isEmpty: false,
            required: true,
            type: "boolean",
            value: inform?.["relay"] ?? true,
            options: booleans
        },
        {
            label: "Térmico",
            name: "termico",
            isEmpty: false,
            required: true,
            type: "boolean",
            value: inform?.["termico"] ?? true,
            options: booleans
        },
        {
            label: "Capacitor",
            name: "capacitor",
            isEmpty: false,
            required: true,
            type: "boolean",
            value: inform?.["capacitor"] ?? true,
            options: booleans
        },
        {
            label: "Contactor",
            name: "contactor",
            isEmpty: false,
            required: true,
            type: "boolean",
            value: inform?.["contactor"] ?? true,
            options: booleans
        },
        {
            label: "Iluminacion interna",
            name: "iluminacion_interna",
            isEmpty: false,
            required: true,
            type: "boolean",
            value: inform?.["iluminacion_interna"] ?? true,
            options: booleans
        },
        {
            label: "Iluminacion externa",
            name: "iluminacion_externa",
            isEmpty: false,
            required: true,
            type: "boolean",
            value: inform?.["iluminacion_externa"] ?? true,
            options: booleans
        },
        {
            label: "Balasto",
            name: "balasto",
            isEmpty: false,
            required: true,
            type: "boolean",
            value: inform?.["balasto"] ?? true,
            options: booleans
        },
        {
            label: "Starter",
            name: "starter",
            isEmpty: false,
            required: true,
            type: "boolean",
            value: inform?.["starter"] ?? true,
            options: booleans
        },
        {
            label: "Cable de alimentación",
            name: "cable_alimentacion",
            isEmpty: false,
            required: true,
            type: "boolean",
            value: inform?.["cable_alimentacion"] ?? true,
            options: booleans
        }
    ])
    const [fields2, setFields2] = useState([
        {
            label: "M/V Evaporador",
            name: "mv_evaporador",
            isEmpty: false,
            required: true,
            type: "boolean",
            value: inform?.["mv_evaporador"] ?? true,
            options: booleans
        },
        {
            label: "M/V Condensador",
            name: "mv_condensador",
            isEmpty: false,
            required: true,
            type: "boolean",
            value: inform?.["mv_condensador"] ?? true,
            options: booleans
        },
        {
            label: "Aspa Evaporador",
            name: "aspa_evap",
            isEmpty: false,
            required: true,
            type: "boolean",
            value: inform?.["aspa_evap"] ?? true,
            options: booleans
        },
        {
            label: "Aspa Condensador",
            name: "aspa_conde",
            isEmpty: true,
            required: true,
            type: "boolean",
            value: inform?.["aspa_conde"] ?? true,
            options: booleans
        },
        {
            label: "",
            name: "voltaje_linea",
            isEmpty: true,
            type: "text",
            inputs: [
                {
                    label: "Voltaje de linea",
                    name: "voltaje_linea",
                    value: inform?.["voltaje_linea"] ?? "0",
                    required: false,
                    type: "number",
                    props: {
                        step: "any",
                        min: "0",
                    }
                }
            ]
        },
        {
            label: "",
            name: "amperaje",
            isEmpty: true,
            type: "text",
            inputs: [
                {
                    label: "Amperaje",
                    name: "amperaje",
                    value: inform?.["amperaje"] ?? "0",
                    required: false,
                    type: "number",
                    props: {
                        step: "any",
                        min: "0",
                    }
                }
            ]
        },
        {
            label: "",
            name: "tipo_acometida",
            isEmpty: true,
            type: "text",
            inputs: [
                {
                    label: "Tipo de acometida",
                    name: "tipo_acometida",
                    value: inform?.["tipo_acometida"] ?? "",
                    required: false,
                    type: "text"
                }
            ]
        },
        {
            label: "",
            name: "tipo_extension",
            isEmpty: true,
            type: "text",
            inputs: [
                {
                    label: "Tipo de extensión",
                    name: "tipo_extension",
                    value: inform?.["tipo_extension"] ?? "",
                    required: false,
                    type: "text"
                }
            ]
        },
        {
            label: "",
            name: "calibre_extension",
            isEmpty: true,
            type: "text",
            inputs: [
                {
                    label: "Calibre de extensión",
                    name: "calibre_extension",
                    value: inform?.["calibre_extension"] ?? "",
                    required: false,
                    type: "text"
                }
            ]
        },
    ])
    const validateFields = (val) => {
        let returnData = {}
        let returnValidate = true;
        if (val.type === "boolean") {
            if (!val?.isEmpty && ["", null, "0"].includes(val?.value)) {
                returnValidate = false;
            }
            if (returnValidate !== false) returnData[val.name] = val?.value;
        }
        if (val.type === "text") {
            val?.inputs?.forEach(val1 => {
                if (!val?.isEmpty && ["", null, "0"].includes(val1?.value)) {
                    returnValidate = false;
                }
                if (returnValidate !== false) returnData[val1.name] = val1?.value;
            })
        }
        return returnValidate ? returnData : false;
    }
    useEffect(() => {
        setChecks(false);
        // eslint-disable-next-line 
    }, [fields1, fields2]);
    const validateData = () => {
        let allData = {};
        setTouched1(true);
        let validated = true;
        let allOk = true;
        [...fields1, ...fields2].forEach((val) => {
            validated = validateFields(val);
            if (!validated) allOk = false;
            if (validated) {
                allData = {
                    ...allData,
                    ...validated
                }
                setFormData((prev) => ({
                    ...prev,
                    ...allData
                }))
            }
        })
        if (!allOk) {
            Alert({
                type: "warning",
                text: "EL formulario no está correcto, por favor revisa de nuevo"
            })
        }
        setChecks(allOk)
    }
    return (
        <>
            <Container fluid>
                <Row className='justify-content-center'>
                    <Col sm={6}>
                        <Row className='justify-content-center mb-2'><Col sm="auto"><strong>Sistema eléctrico</strong></Col></Row>
                        {
                            fields1?.map((val, index) => (
                                <SelectorChecks key={index} index={index} data={val} setFields={setFields1} />
                            ))
                        }
                    </Col>
                </Row>
                <Row className='my-3 justify-content-center'>
                    <Col sm={6}>
                        <Row className='justify-content-center mb-2'><Col sm="auto"><strong>Condiciones</strong></Col></Row>
                        {
                            fields2?.map((val, index) => (
                                <SelectorChecks key={index} index={index} data={val} setFields={setFields2} />
                            ))
                        }
                    </Col>
                </Row>
                <Row className='my-1 justify-content-end'>
                    <Col sm={"auto"}>
                        <Button size="sm" variant='primary' onClick={validateData} >
                            Validar
                        </Button>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

import React from "react";
import { Button, ButtonGroup, Badge } from "react-bootstrap";
import { GeneralDatatable } from "../../General";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { useFormat, usePermissions } from "../../../hooks";
import { useAuth } from "../../../contexts";
import { EDITAR_FACTURAS, ELIMINAR_FACTURAS } from "../../../utils";

export const InvoicesTable = (props) => {
  const { user } = useAuth();
  const { formatNumber } = useFormat();
  const { validate } = usePermissions();
  const columns = [
    {
      id: "id",
      name: "Número",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Fecha",
      selector: (row) => row.fecha,
      sortable: true,
    },
    {
      name: "Ciudad",
      selector: (row) => row?.city?.nombre,
      sortable: true,
    },
    {
      name: "Cliente",
      selector: (row) => row?.cliente?.nombre,
      sortable: true,
    },
    {
      name: "Rango Fechas",
      sortable: true,
      cell: (row) => {
        return (
          <>
            Fecha inicial: {row.fecha_inicial} <br />
            Fecha final: {row.fecha_final}
          </>
        )
      },
    },
    {
      name: "Total",
      sortable: true,
      cell: row => (
        <>
          {formatNumber(row?.total, 2)}
        </>
      )
    },
    {
      name: "Estado",
      sortable: true,
      cell: (row) => {
        let color = "primary";
        let border = "";
        let label = ""
        switch (row.estado) {
          case 1:
            color = "primary";
            border = "primary";
            label = "Generada";
            break;
          case 2:
            color = "success";
            border = "success";
            label = "Aprobada";
            break;
          case 3:
            color = "warning";
            border = "warning";
            label = "Facturada";
            break;
          default:
            color = "danger";
            border = "warning";
            label = "En revisión";
            break;
        }
        return (
          <Badge pill bg={color} className={`border border-${border}`} >
            {label}
          </Badge>
        );
      },
    },
    {
      name: "Acciones",
      button: true,
      cell: (row) => {
        return (
          <>
            <ButtonGroup aria-label="Basic example">
              {
                validate(user, EDITAR_FACTURAS) && (
                  <Button
                    size="sm"
                    variant="warning"
                    onClick={(e) => props.onEdit(row.id)}
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </Button>
                )
              }
              {
                validate(user, ELIMINAR_FACTURAS) && (
                  <Button
                    size="sm"
                    variant="danger"
                    onClick={(e) => props.onDelete(row.id)}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </Button>
                )
              }
            </ButtonGroup>
          </>
        );
      },
    },
  ];
  return (
    <GeneralDatatable
      columns={columns}
      data={props.data}
      customProps={{ defaultSortFieldId: "id", defaultSortAsc: false }}
    />
  )
}

import { useState, useEffect } from 'react';
import { Loader } from '../../Loader';
import { usePermissions } from '../../../hooks';
import { GESTION_BODEGAS } from '../../../utils';
import { useAuth, useAxios } from '../../../contexts';
import { EditStoreEquipmentTable } from './EditStoreEquipmentTable';
import { useParams } from 'react-router-dom';

export const EditStoreEquipment = () => {
    const {id} = useParams();
    const { user } = useAuth();
    const [loading, setLoading] = useState(false);
    const [equipment, setEquiment] = useState([]);
    const { validateRoutePermission } = usePermissions();
    const { privateFetch } = useAxios();

    useEffect(() => {
        validateRoutePermission(user, GESTION_BODEGAS);
        document.title = "Equipos"
        getEquipment();
        return () => {
            setEquiment([]);
        }
        // eslint-disable-next-line
    }, [])

    const getEquipment = async () => {
        setLoading(true);
        const { data: equipment } = await privateFetch.post("api/equipment/getEquipmentFiltered", {
            bodega_id: id,
        });
        setEquiment(equipment.equipment);
        setLoading(false);
    };
    return loading ? (
        <Loader />
    ) : (
        <>
            <EditStoreEquipmentTable data={equipment}/>
        </>
    )
}

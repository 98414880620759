import React from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { CAccounts, DepartamentosCiudades, EditCC, EditClient, EditInvoice, Equipment, Error404, Home, Inform, Login, ManageClientes, ManageInventory, ManageInvoices, Mant, Procedures, Store, Users } from "../pages";
import { useAuth } from '../contexts/AuthProvider';
import { AddInform, BasePanel, EditCity, EditEquipment, EditInventory, EditTabsStore, PDFInform, TabsEditUser } from '../components';

export const Router = () => {
    const { isAutenticated } = useAuth();
    const Protected = ({ element }) => {
        switch (true) {
            case (!isAutenticated()):
                return <Navigate to="/login" />
            default:
                return (
                    <BasePanel>
                        {element}
                    </BasePanel>
                );
        }
    }
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Navigate to="/login" />} />
                <Route path="/login" element={<Login />} />
                <Route path="/mant" element={<Mant />} />
                <Route path='/home'>
                    <Route path="/home" element={<Protected element={<Home />} />} />
                </Route>
                <Route path='/users'>
                    <Route path='/users' element={<Protected element={<Users />} />} />
                    <Route path='/users/edit-user/:id' element={<Protected element={<TabsEditUser />} />} />
                </Route>
                <Route path='/clients'>
                    <Route path='/clients' element={<Protected element={<ManageClientes />} />} />
                    <Route path='/clients/edit-client/:id' element={<Protected element={<EditClient />} />} />
                </Route>
                <Route path='/invoices'>
                    <Route path='/invoices' element={<Protected element={<ManageInvoices />} />} />
                    <Route path='/invoices/edit-invoice/:id' element={<Protected element={<EditInvoice />} />} />
                </Route>
                <Route path='/caccount'>
                    <Route path='/caccount' element={<Protected element={<CAccounts />} />} />
                    <Route path='/caccount/edit-cc/:id' element={<Protected element={<EditCC />} />} />
                </Route>
                <Route path='/inventory'>
                    <Route path='/inventory' element={<Protected element={<ManageInventory />} />} />
                    <Route path='/inventory/edit-element/:id' element={<Protected element={<EditInventory />} />} />
                </Route>
                <Route path='/depto-cities'>
                    <Route path='/depto-cities' element={<Protected element={<DepartamentosCiudades />} />} />
                    <Route path='/depto-cities/edit-city/:id' element={<Protected element={<EditCity />} />} />
                </Route>
                <Route path='/procedures'>
                    <Route path='/procedures' element={<Protected element={<Procedures />} />} />
                </Route>
                <Route path='/equipment'>
                    <Route path='/equipment' element={<Protected element={<Equipment />} />} />
                    <Route path='/equipment/edit-equipment/:id' element={<Protected element={<EditEquipment />} />} />
                </Route>
                <Route path='/inform'>
                    <Route path='/inform' element={<Protected element={<Inform />} />} />
                    {/*<Route path='/inform/add-inform' element={<Protected element={<AddInform edit={false} view={false} />} />} />*/}
                    <Route path='/inform/edit-inform/:id' element={<Protected element={<AddInform edit={true} view={false} />} />} />
                    <Route path='/inform/pdfinform/:id' element={<PDFInform />} />
                </Route>
                <Route path='/store'>
                    <Route path='/store' element={<Protected element={<Store />} />} />
                    <Route path='/store/edit-store/:id' element={<Protected element={<EditTabsStore />} />} />
                </Route>
                <Route path='*' element={<Error404 />} />
            </Routes>
        </BrowserRouter>
    )
}

import React, { useEffect, useState } from 'react'
import { PermissionsTable } from './PermissionsTable'
import { useAlert, useNotify } from '../../../hooks'
import { useAxios } from '../../../contexts';
import { useParams } from 'react-router-dom'
import { Loader } from '../../Loader/Loader'

export const ManagePermissions = () => {
  const { id: user_id } = useParams();
  const { DelAlert } = useNotify();
  const { privateFetch } = useAxios();
  const { Alert } = useAlert();
  const [loading, setLoading] = useState(false);
  const [permissions, setPermissions] = useState([])
  const { notify } = useNotify();
  useEffect(() => {
    getPermisos();
    // eslint-disable-next-line
  }, [])
  const getPermisos = async () => {
    setLoading(true);
    const { data: permisos } = await privateFetch.get(`/api/permissions/getPermissions/${user_id}`);
    setPermissions(permisos.permisos);
    setLoading(false);
  }
  const cambiarPermiso = (cod, estado) => {
    setLoading(true);
    DelAlert({
      text: "Realmente desea cambiar el estado de éste permiso?",
      confirmButtonText: "Modificar",
      icon: "warning",
      onCancel: () => {
        Alert({
          title: "Advertencia",
          text: "Cancelado",
          type: "warning",
        })
      },
      onConfirm: async () => {
        const { data } = await privateFetch.post(`/api/permissions/changePermission`, {
          user_id,
          codigoPermiso: cod,
          status: estado
        });
        notify({
          ...data,
          callback: () => {
            getPermisos();
          }
        });
      }
    })
    setLoading(false)
  }

  return loading ? (
    <Loader />
  ) : (
    <>
      <PermissionsTable onClick={cambiarPermiso} data={permissions} />
    </>
  )
}

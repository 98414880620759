import React from 'react'
import { useState } from 'react';
import { Modal, Row, Col, FormControl, Button, Form } from 'react-bootstrap';
import { useNotify, useForm } from '../../hooks';
import { useAxios } from '../../contexts';
import { Loader } from '../Loader/Loader';

export const ModalRecovery = ({ setShow, show }) => {
    const [loading, setLoading] = useState(false);
    const { serialize } = useForm();
    const { publicFetch } = useAxios();
    const { notify } = useNotify();
    const handleClose = e => setShow(false);
    const recoverPasswordForm = async (e) => {
        e.preventDefault();
        setLoading(true);
        const formData = serialize(e.target);
        const { data } = await publicFetch.post("/api/login/recoverPass", formData);
        notify({
            ...data
        });
        setLoading(false);
    }
    return (
        loading ? (
            <Loader />
        ) : (
            <>
                <Modal show={show} onHide={handleClose}>
                    <Form onSubmit={recoverPasswordForm} autoComplete='off'>
                        <Modal.Header closeButton>
                            <Modal.Title>Reestablecer contraseña</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col sm>
                                    Para reestablecer su contraseña por favor escriba su correo electrónico
                                </Col>
                            </Row>
                            <Row>
                                <Col sm>
                                    <label>
                                        <strong>Correo electrónico</strong>
                                    </label>
                                    <FormControl size='sm' type="email" name="email" />
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button size="sm" variant="secondary" onClick={handleClose}>
                                Cerrar
                            </Button>
                            <Button type='submit' size="sm" variant="primary" onClick={handleClose}>
                                Guardar
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </>
        )
    )
}

import React, { useEffect, useState } from "react";
import { useAuth, useAxios } from "../../../contexts";
import { UsersTable } from "./UsersTable";
import { Loader } from "../../Loader/Loader";
import {
  useAlert,
  useForm,
  useNotify,
  usePermissions,
} from "../../../hooks";
import {
  Button,
  Col,
  Form,
  FormControl,
  FormSelect,
  InputGroup,
  Modal,
  Row,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faPlus, faSync } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { GESTION_USUARIOS } from "../../../utils";

export const UsuariosCRUD = () => {
  const { user } = useAuth();
  const { privateFetch } = useAxios();
  const [usuarios, setUsuarios] = useState([]);
  const [showPass, setShowPass] = useState(false);
  const [roles, setRoles] = useState([]);
  const [showAddUser, setShowAddUser] = useState(false);
  const handleCloseCreate = () => {
    setShowAddUser(false);
    setShowClients(false);
  };
  const handleShowCreate = () => setShowAddUser(true);
  const [loading, setLoading] = useState(false);
  const { serialize } = useForm();
  const { Alert } = useAlert();
  const { notify, DelAlert } = useNotify();
  const navigate = useNavigate()
  const { validateRoutePermission } = usePermissions();
  const [clientes, setClientes] = useState([]);
  const [showClients, setShowClients] = useState(false);
  useEffect(() => {
    validateRoutePermission(user, GESTION_USUARIOS);
    document.title = "Usuarios || Roles";
    getUsuariosandRoles();
    return () => {
      setUsuarios([]);
      setRoles([]);
      setShowPass(false);
      setLoading(false);
    };
    // eslint-disable-next-line
  }, []);
  const getUsuariosandRoles = async () => {
    setLoading(true);
    const { data: users } = await privateFetch.get("/api/users/getUsuarios");
    const { data: roles } = await privateFetch.get("/api/users/getRoles/");
    const { data: clients } = await privateFetch.get(`/api/clients/getClients`);
    setClientes(clients.clients);
    setUsuarios(users.users);
    setRoles(roles.roles);
    setLoading(false);
  };
  const EditarUsuario = (id) => {
    navigate("/users/edit-user/" + id)
  };
  const Eliminarusuario = async (id) => {
    await DelAlert({
      text: "Desea eliminar el usuario?",
      onConfirm: async () => {
        const { data } = await privateFetch.delete(
          "/api/users/deleteUser/" + id
        );
        notify(data);
        getUsuariosandRoles();
      },
      onCancel: () => {
        Alert({
          type: "warning",
          text: "Eliminación cancelada",
        });
      },
    });
  };
  const guardarUsuario = async (ev) => {
    ev.preventDefault();
    setLoading(true);
    const formData = serialize(ev.target);
    const { data } = await privateFetch.post(
      "/api/users/guardarUsuario",
      formData
    );
    notify({
      ...data,
      callback: () => {
        ev.target.reset();
      },
    });
    getUsuariosandRoles();
    setShowAddUser(false);
    setLoading(false);
  };
  const validateRol = (ev) => {
    const value = parseInt(ev?.target?.value ?? "0");
    if (value === 4) return setShowClients(true);
    return setShowClients(false);
  }
  return loading ? (
    <Loader />
  ) : (
    <>
      <Row className="mb-2">
        <Col sm="auto">
          <Button size="sm" variant="primary" onClick={getUsuariosandRoles}>
            <FontAwesomeIcon icon={faSync} />
          </Button>
        </Col>
        <Col sm="auto">
          <Button size="sm" variant="primary" onClick={handleShowCreate}>
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </Col>
      </Row>
      <UsersTable
        data={usuarios}
        onEdit={EditarUsuario}
        onDelete={Eliminarusuario}
      />
      <Modal show={showAddUser} onHide={handleCloseCreate}>
        <Modal.Header closeButton>
          <Modal.Title>Nuevo Usuario</Modal.Title>
        </Modal.Header>
        {!loading ? (
          <Form onSubmit={guardarUsuario} autoComplete="off">
            <Modal.Body>
              <Row>
                <Col sm>
                  <label>Nombre</label>
                  <FormControl size="sm" type="text" name="nombre" required />
                </Col>
              </Row>
              <Row>
                <Col sm>
                  <label>Apellido</label>
                  <FormControl size="sm" type="text" name="apellido" required />
                </Col>
              </Row>
              <Row>
                <Col sm>
                  <label>Cédula</label>
                  <FormControl size="sm" type="text" name="cifnif" required />
                </Col>
              </Row>
              <Row>
                <Col sm>
                  <label>Correo</label>
                  <FormControl size="sm" type="email" name="correo" required />
                </Col>
              </Row>
              <Row>
                <Col sm>
                  <label>Contraseña</label>
                  <InputGroup size="sm">
                    <Form.Control
                      type={showPass ? "text" : "password"}
                      name="password"
                      required
                    />
                    <Button onClick={(e) => setShowPass(!showPass)}>
                      <FontAwesomeIcon icon={showPass ? faEye : faEyeSlash} />
                    </Button>
                  </InputGroup>
                </Col>
              </Row>
              <Row>
                <Col sm>
                  <label>Rol</label>
                  <FormSelect size="sm" required name="rol_id" onChange={validateRol}>
                    <option value="">Seleccione</option>
                    {roles.map((val, id) => (
                      <option key={id} value={val.id}>
                        {val.nombre}
                      </option>
                    ))}
                  </FormSelect>
                </Col>
              </Row>
              {
                showClients && (
                  <Row>
                    <Col sm>
                      <label>Cliente</label>
                      <FormSelect size="sm" required name="cliente_id">
                        <option value="">Seleccione</option>
                        {clientes.map((val, id) => (
                          <option key={id} value={val.id}>
                            {val.nombre}
                          </option>
                        ))}
                      </FormSelect>
                    </Col>
                  </Row>
                )
              }
              <Row>
                <Col sm>
                  <label>Vigencia</label>
                  <FormControl size="sm" type="date" name="vigencia" required />
                </Col>
              </Row>
              <Row>
                <Col sm>
                  <label>Dirección</label>
                  <FormControl
                    as="textarea"
                    size="sm"
                    type="text"
                    name="direccion"
                    required
                  />
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button size="sm" variant="secondary" onClick={handleCloseCreate}>
                Cerrar
              </Button>
              <Button type="submit" size="sm" variant="primary">
                Guardar
              </Button>
            </Modal.Footer>
          </Form>
        ) : (
          <Loader />
        )}
      </Modal>
    </>
  );
};

import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { GeneralDatatable } from "../../General";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

export const ClientesTable = (props) => {

  const columns = [
    {
      id: "id",
      name: "Número",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Razón social",
      selector: (row) => row.nombre,
      sortable: true,
    },
    {
      name: "Nit",
      selector: (row) => row.nit,
      sortable: true,
    },
    {
      name: "Direccion",
      selector: (row) => row.direccion,
      sortable: true,
    },
    {
      name: "Telefono",
      selector: (row) => row.telefono,
      sortable: true,
    },
    {
      name: "Responsable",
      selector: (row) => row.nombre_responsable,
      sortable: true,
    },
    {
      name: "Correo electrónico",
      cell: (row) => {
        return (
          <>
            {row?.correo}<br/>
            {row?.correo_alternativo}
          </>
        )
      }
    },
    {
      name: "Acciones",
      button: true,
      cell: (row) => {
        return (
          <>
            <ButtonGroup aria-label="Basic example">
              <Button
                size="sm"
                variant="warning"
                onClick={(e) => props.onEdit(row.id)}
              >
                <FontAwesomeIcon icon={faEdit} />
              </Button>

              <Button
                size="sm"
                variant="danger"
                onClick={(e) => props.onDelete(row.id)}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </Button>
            </ButtonGroup>
          </>
        );
      },
    },
  ];
  return (
    <GeneralDatatable
      columns={columns}
      data={props.data}
      customProps={{ defaultSortFieldId: "id", defaultSortAsc: false }}
    />
  )
}

import React from 'react'
import { useAuth, useAxios } from '../../../contexts';
import { useEffect, useState } from 'react';
import { useForm, usePermissions, useNotify, useAlert, useXLSX } from '../../../hooks';
import { GESTION_INVENTARIO } from '../../../utils';
import { InventoryTable } from '../../../components/controllers/inventory/InventoryTable';
import { faFileExcel, faPlus, faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Button, Modal, Form, FormControl, FormSelect, Spinner } from "react-bootstrap";
import { Loader } from '../../../components';
import { useNavigate } from 'react-router-dom';


export const ManageInventory = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const { privateFetch } = useAxios();
  const [inventory, setInventory] = useState([]);
  const { validateRoutePermission } = usePermissions();
  const [showAddItem, setShowAddItem] = useState(false);
  const handleShowCreate = () => setShowAddItem(true);
  const handleCloseCreate = () => setShowAddItem(false);
  const { serialize } = useForm();
  const [editInventoryState, setEditInventoryState] = useState({});
  const [showModalEdit, setShowModalEdit] = useState(false);
  const handleCloseEdit = () => setShowModalEdit(false);
  const [sLoading,] = useState(false);
  const { notify, DelAlert } = useNotify();
  const { Alert } = useAlert();
  const [, setSpinnerLoading] = useState(false);
  const navigate = useNavigate();
  const [filter, setFilter] = useState({});
  const [loadingXLSX, setLoadingXLSX] = useState(false);
  const {generateInventoryFile} = useXLSX();

  useEffect(() => {
    validateRoutePermission(user, GESTION_INVENTARIO);
    document.title = "Inventario";
    getInventory();
    return () => {
      setInventory([]);
    }
    // eslint-disable-next-line
  }, [filter])
  const getInventory = async () => {
    setLoading(true);
    const { data } = await privateFetch.post(`api/inventory/getInventoryFiltered`, filter);
    setInventory(data.inventory)
    setLoading(false);
  }
  const guardarInventario = async (ev) => {
    ev.preventDefault();
    setLoading(true);
    const formData = serialize(ev.target);
    const { data } = await privateFetch.post(`api/inventory/createInventory`, formData)
    notify({
      ...data,
      callback: () => {
        ev.target.reset();
      },
    });
    getInventory();
    setShowAddItem(false);
    setLoading(false);
  }

  const editInventario = (id) => {
    const editInventory = inventory.filter(val => val.id === id)?.[0];
    if (editInventory?.tipo === 1) navigate("/inventory/edit-element/" + id)
    setEditInventoryState(editInventory);
    setShowModalEdit(true);
  }
  const editarInventario = async (ev) => {
    ev.preventDefault();
    setSpinnerLoading(true);
    const formData = serialize(ev.target);
    const { data } = await privateFetch.put(`api/inventory/editInventory/${editInventoryState?.id}`, formData);
    notify({
      ...data,
      callback: () => {
        ev.target.reset();
      },
    });
    setShowModalEdit(false);
    getInventory();
    setShowAddItem(false);
    setSpinnerLoading(false);
  }

  const deleteItem = async (id) => {
    await DelAlert({
      text: "Desea eliminar este elemento?",
      onConfirm: async () => {
        const { data } = await privateFetch.delete(
          "/api/inventory/deleteInventory/" + id
        );
        notify(data);
        getInventory();
      },
      onCancel: () => {
        Alert({
          type: "warning",
          text: "Eliminación cancelada",
        });
      },
    });
  };

  const setFilterData = (ev) => {
    if (ev.target?.value === "") {
      return setFilter({});
    } else {
      setFilter({
        tipo: ev.target?.value
      })
    }
  }
  const generateExcel = async (ev) => {
    setLoadingXLSX(true);
    const { data } = await privateFetch.post("/api/inventory/getInventoryByUser");
    generateInventoryFile(data?.dataFormated);
    setLoadingXLSX(false);
  }
  return loading ? (
    <Loader />
  ) : (
    <>
      <Row className="mb-2">
        <Col sm="auto">
          <Button size="sm" variant="primary" onClick={getInventory}>
            <FontAwesomeIcon icon={faSync} />
          </Button>
        </Col>
        <Col sm="auto">
          <Button size="sm" variant="primary" onClick={handleShowCreate}>
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </Col>
        <Col sm={3}>
          <FormSelect size='sm' onChange={setFilterData} defaultValue={filter?.tipo}>
            <option value="">Filtro</option>
            {[{ id: 1, nombre: "Repuesto" }, { id: 2, nombre: "Producto" }].map((val, id) => (
              <option key={id} value={val.id}>
                {val.nombre}
              </option>
            ))}
          </FormSelect>
        </Col>
        <Col sm="auto">
          <Button type='button' variant='success' size='sm' onClick={generateExcel} disabled={loadingXLSX} >
            {
              loadingXLSX ? (
                <>
                  <Spinner animation='grow' size='sm' variant='warning' /> &nbsp; Generando excel...
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={faFileExcel} />
                </>
              )
            }
          </Button>
        </Col>
      </Row>
      <InventoryTable data={inventory} onEdit={editInventario} onDelete={deleteItem} />
      <Modal show={showAddItem} onHide={handleCloseCreate}>
        <Modal.Header closeButton>
          <Modal.Title>Nuevo Elemento</Modal.Title>
        </Modal.Header>
        {!loading ? (
          <Form onSubmit={guardarInventario} autoComplete='off'>
            <Modal.Body>
              <Row>
                <Col sm>
                  <label>Nombre Elemento</label>
                  <FormControl size="sm" type="text" name="nombre" required />
                </Col>
              </Row>
              <Row>
                <Col sm>
                  <label>Codigo</label>
                  <FormControl size="sm" type="text" name="codigo" required />
                </Col>
              </Row>
              <Row>
                <Col sm>
                  <label>Tipo</label>
                  <FormSelect defaultValue="" size="sm" required name="tipo">
                    <option value="">Seleccione</option>
                    {[{ id: 1, nombre: "Repuesto" }, { id: 2, nombre: "Producto" }].map((val, id) => (
                      <option key={id} value={val.id}>
                        {val.nombre}
                      </option>
                    ))}
                  </FormSelect>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label>Estado</label>
                  <FormSelect defaultValue="" size="sm" required name="estado">
                    <option value="">Seleccione</option>
                    {[{ id: true, nombre: "Activo" }, { id: false, nombre: "Inactivo" }].map((val, id) => (
                      <option key={id} value={val.id}>
                        {val.nombre}
                      </option>
                    ))}
                  </FormSelect>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button size="sm" variant="secondary" onClick={handleCloseCreate}>
                Cerrar
              </Button>
              <Button type="submit" size="sm" variant="primary">
                Guardar
              </Button>
            </Modal.Footer>
          </Form>
        ) : (
          <Loader />
        )}
      </Modal>
      <Modal show={showModalEdit} onHide={handleCloseEdit}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Elemento</Modal.Title>
        </Modal.Header>
        <Form onSubmit={editarInventario} autoComplete="off">
          <Modal.Body>
            <Row>
              <Col sm>
                <label>Nombre</label>
                <FormControl size="sm" type="text" name="nombre" required defaultValue={editInventoryState?.nombre} />
              </Col>
            </Row>
            <Row>
              <Col sm>
                <label>Codigo</label>
                <FormControl size="sm" type="text" name="codigo" required defaultValue={editInventoryState?.co} />
              </Col>
            </Row>
            <Row>
              <Col>
                <label>Estado</label>
                <FormSelect defaultValue={editInventoryState?.estado} size="sm" name="estado">
                  <option value="">Seleccione</option>
                  {[{ id: true, nombre: "Activo" }, { id: false, nombre: "Inactivo" }].map((val, id) => (
                    <option key={id} value={val.id}>
                      {val.nombre}
                    </option>
                  ))}
                </FormSelect>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button size="sm" variant="secondary" onClick={handleCloseEdit}>
              Cerrar
            </Button>
            <Button
              type="submit"
              size="sm"
              variant="primary"
              disabled={sLoading}
            >
              {sLoading ? <></> : <>Guardar</>}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}
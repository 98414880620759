import { faPlus, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Container, FormControl, Row, Form, FormSelect, Spinner, Modal } from 'react-bootstrap';
import { Loader } from '../../Loader/Loader';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth, useAxios } from '../../../contexts';
import { useForm, useNotify, usePermissions } from '../../../hooks';
import { GESTION_INVENTARIO } from '../../../utils';
import { AssignmentTable } from './AssignmentTable';

export const EditInventory = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [spinnerLoading, setSpinnerLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [fromId, setFromId] = useState(null);
  const [toId, setToId] = useState(null);
  const [from, setFrom] = useState({});
  const [to, setTo] = useState({});
  const { validateRoutePermission } = usePermissions();
  const navigate = useNavigate();
  const { privateFetch } = useAxios();
  const [inventory, setInventory] = useState({});
  const [assigned, setAssigned] = useState([]);
  const [users, setUsers] = useState([]);
  const [clientes, setClientes] = useState([]);
  const { serialize } = useForm();
  const { notify } = useNotify();
  const assignmentData = [
    {
      type: 1,
      title: "De empresas",
      filter: (val) => val.user_id === null
    },
    {
      type: 2,
      title: "De técnicos",
      filter: (val) => val.user_id !== null
    }
  ];
  useEffect(() => {
    document.title = "Editar Repuesto";
    if (!id) navigate("/home");
    validateRoutePermission(user, GESTION_INVENTARIO);
    getInventory();
    getClientsUsers();
    // eslint-disable-next-line
  }, [])
  const getClientsUsers = async () => {
    setLoading(true);
    const { data: clients } = await privateFetch.get(`/api/clients/getClients`);
    const { data: users } = await privateFetch.post("/api/users/getUsersFiltered", { orCondition: { rol_id: [1, 2] } });
    setUsers(users.users);
    setClientes(clients.clients)
    setLoading(false);
  }
  const getInventory = async () => {
    setLoading(true);
    const { data } = await privateFetch.get(`api/inventory/getInventory/${id}`);
    setInventory(data.inventory)
    setAssigned(data.inventory?.main_inv_asignados);
    setLoading(false);
  }
  const editarRespuesto = async (ev) => {
    ev.preventDefault();
    setSpinnerLoading(true);
    setLoading(true);
    const copy = [...assigned];
    const formData = {
      ...serialize(ev.target),
      asignados: copy.map((val) => {
        delete val?.cliente;
        delete val?.inventario;
        delete val?.user;
        delete val?.createdAt;
        delete val?.updatedAt;
        return val;
      })
    };
    const { data } = await privateFetch.put(
      "api/inventory/editInventory/" + id, formData
    );
    notify({
      ...data
    })
    getInventory();
    setLoading(false);
    setSpinnerLoading(false);
  }
  const handleShow = (e) => {
    //if (fromId === null) return;
    setShowModal(true)
  };
  const handleClose = () => {
    setFromId(null);
    setToId(null);
    setFrom({});
    setTo({});
    setShowModal(false)
  };
  const editAssignment = async (ev) => {
    ev.preventDefault();
    const formData = serialize(ev.target);
    const data = [...assigned];
    if (formData.hasOwnProperty('client_id') && formData.hasOwnProperty('user_id')) {
      if (formData?.hasOwnProperty('from_id')) {
        const desde = data?.filter((val) => val.id === parseInt(formData?.from_id))?.[0];
        const indexDesde = data.indexOf(desde);
        data[indexDesde] = {
          ...desde,
          cantidad: parseInt(desde.cantidad ?? "0") - parseInt(formData?.cantidad ?? '0'),
        }
      }
      formData.user_id = formData?.user_id === '' ? null : parseInt(formData?.user_id ?? '0');
      const exists = data?.findIndex((val) => val?.user_id === formData?.user_id && val?.cliente_id === parseInt(formData?.client_id ?? '0'));
      if (exists !== -1) {
        data[exists].cantidad = parseInt(data[exists].cantidad ?? "0") + parseInt(formData?.cantidad ?? '0');
        setAssigned(data);
      } else {
        setAssigned([
          ...data,
          {
            id: null,
            inventario_id: id,
            cliente_id: parseInt(formData?.client_id ?? '0'),
            cliente: clientes?.filter((val) => val?.id === parseInt(formData?.client_id ?? '0'))?.[0],
            user_id: formData?.user_id ? parseInt(formData?.user_id) : null,
            user: users?.filter((val) => val?.id === parseInt(formData?.user_id ?? '0'))?.[0],
            cantidad: formData?.cantidad
          }
        ])
      }
    } else {
      const desde = data?.filter((val) => val.id === parseInt(formData?.from_id))?.[0];
      const para = data?.filter((val) => val.id === parseInt(formData?.to_id))?.[0];
      const indexDesde = data.indexOf(desde);
      const indexPara = data.indexOf(para);
      data[indexDesde] = {
        ...desde,
        cantidad: desde.cantidad - parseInt(formData?.cantidad ?? '0'),
      }
      data[indexPara] = {
        ...para,
        cantidad: para.cantidad + parseInt(formData?.cantidad ?? '0'),
      }
      setAssigned(data);
    }
    handleClose();
  }
  const setIds = (e, id) => {
    if (fromId === null) {
      setFromId(id);
      const de = assigned.filter((val) => val.id === id)?.[0];
      setFrom(de);
    }
    if (fromId !== null && toId === null) {
      setToId(id);
      const para = assigned.filter((val) => val.id === id)?.[0];
      setTo(para);
    }
    if (fromId !== null) handleShow();
  }
  return (
    <>
      <Container fluid className='mt-2'>
        <Card>
          <Card.Header>Editar Repuesto</Card.Header>
          {!loading ? (
            <Form onSubmit={editarRespuesto} autoComplete='off'>
              <Card.Body>
                <Row>
                  <Col sm="auto">
                    <label>Nombre</label>
                    <FormControl size="sm" required name='nombre' defaultValue={inventory?.nombre} />
                  </Col>
                  <Col sm='auto'>
                    <label>Estado</label>
                    <FormSelect size="sm" required name="estado" defaultValue={inventory?.estado}>
                      <option value="">Seleccione</option>
                      {[{ id: true, nombre: "Activo" }, { id: false, nombre: "Inactivo" }].map((val, id) => (
                        <option key={id} value={val.id}>
                          {val.nombre}
                        </option>
                      ))}
                    </FormSelect>
                  </Col>
                  <Col sm="auto">
                    <label>Valor</label>
                    <FormControl type='number' step="any" size="sm" required name='valor' defaultValue={inventory?.valor} />
                  </Col>
                  <Col sm="auto">
                    <label>Codigo</label>
                    <FormControl type='text' step="any" size="sm" required name='codigo' defaultValue={inventory?.codigo} />
                  </Col>
                  <Col sm="auto">
                    <br />
                    <Button size='sm' variant='success' onClick={handleShow}>
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  </Col>
                </Row>
                <Row className='mt-3'>
                  {
                    assignmentData?.map((val, index) => (
                      <AssignmentTable key={index} info={val} data={assigned} setAssigned={setAssigned} fromId={fromId} toId={toId} setId={setIds} />
                    ))
                  }
                </Row>
              </Card.Body>
              <Card.Footer>
                <Button type='submit' size='sm' variant='primary'>
                  {
                    spinnerLoading ? (
                      <>
                        <Spinner animation="grow" variant="warning" />
                      </>
                    ) : (
                      <>
                        <FontAwesomeIcon icon={faSave} /> &nbsp;
                        Guardar
                      </>
                    )
                  }
                </Button>
              </Card.Footer>
            </Form>
          ) : (
            <Loader />
          )}
        </Card>
        <Modal show={showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Movimiento de inventario</Modal.Title>
          </Modal.Header>
          <Form onSubmit={editAssignment} autoComplete='off'>
            <Modal.Body>
              {
                fromId !== null && (
                  <>
                    <Row>
                      <Col sm>
                        <label>De:</label><br />
                        <FormControl name="from_id" required hidden defaultValue={from?.id} />
                        Empresa: {from?.cliente?.nombre}  <br />
                        {
                          from?.user_id !== null && (
                            <>
                              Técnico: {from?.user?.nombre} <br />
                            </>
                          )
                        }
                        Cantidad actual: {from?.cantidad}
                      </Col>
                    </Row>
                  </>
                )
              }
              {
                toId !== null ? (
                  <>
                    <Row>
                      <Col sm>
                        <label>Para:</label><br />
                        <FormControl name="to_id" required hidden defaultValue={to?.id} />
                        Empresa: {to?.cliente?.nombre}  <br />
                        {
                          to?.user_id !== null && (
                            <>
                              Técnico: {to?.user?.nombre} <br />
                            </>
                          )
                        }
                        Cantidad actual: {to?.cantidad}
                      </Col>
                    </Row>
                  </>
                ) : (
                  <Row>
                    <Col sm>
                      <label>Para:</label>
                      <Row>
                        <Col sm>
                          <label>Cliente</label>
                          <FormSelect size='sm' name='client_id' required>
                            <option value="">Seleccione</option>
                            {
                              clientes?.map((val) => (
                                <option key={val?.id} value={val?.id}>{val?.nombre}</option>
                              ))
                            }
                          </FormSelect>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm>
                          <label>Técnico</label>
                          <FormSelect size='sm' name='user_id'>
                            <option value="">Seleccione</option>
                            {
                              users?.map((val) => (
                                <option key={val?.id} value={val?.id}>{val?.nombre}</option>
                              ))
                            }
                          </FormSelect>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )
              }
              <Row>
                <Col sm>
                  <label>Cantidad</label>
                  <FormControl size="sm" type='number' max={toId !== null ? from?.cantidad : "any"} name='cantidad' required />
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button size='sm' variant="secondary" onClick={handleClose}>
                Cerrar
              </Button>
              <Button type='submit' size='sm' variant="primary">
                Guardar
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </Container >
    </>
  )
}
import { useNavigate } from "react-router-dom";

export const usePermissions = () => {
  const navigate = useNavigate();
  const validate = (user, codigoPermiso) => {
    let validated = false;
    user?.main_permisos_usuarios?.forEach(val => {
      if(val?.permiso?.cod === codigoPermiso && val?.estado === true){
        validated = true;
      }
    })
    return validated;
  }
  const validateRoutePermission = (user, codigoPermiso) => {
    const permiso = validate(user, codigoPermiso);
    if(!permiso){
      navigate("/home")
    }
  }
  return {
    validate,
    validateRoutePermission
  }
}

import React from 'react'
import { Badge} from "react-bootstrap";
import { GeneralDatatable } from "../../General";

export const EditStoreEquipmentTable = (props) => {
  const columns = [
    {
      id: "id",
      name: "Número",
      selector: (row) => row?.id,
      sortable: true,
    },
    {
      name: "Ciudad",
      selector: (row) => row?.ciudad?.nombre,
      sortable: true,
    },
    {
      name: "Placa",
      selector: (row) => row?.placa,
      sortable: true,
    },
    {
      name: "Modelo",
      selector: (row) => row?.modelo,
      sortable: true,
    },
    {
      name: "Ubicacion",
      selector: (row) => row?.lugar_ubicacion,
      sortable: true,
    },
    {
      name: "Nombre",
      selector: (row) => row?.nombre,
      sortable: true,
    },
    {
      name: "Estado",
      sortable: true,
      cell: (row) => {
        let color = "primary";
        let label = "";
        switch (row.estado) {
          case 0:
            color = "danger";
            label = "Inactivo"
            break;
          case 1:
            color = "success";
            label = "Activo"
            break;
            case 2:
              color = "warning";
              label = "Por reparar"
              break;
            case 3:
              color = "primary";
              label = "Reparado"
              break;
          default:
            color = "info"
            label = "En bodega"
            break;
        }
        return (
          <Badge pill bg={color}>
            {label}
          </Badge>
        );
      },
    },
    
  ]
  return (
    <>
      <GeneralDatatable
        columns={columns}
        data={props.data}
        customProps={{ defaultSortFieldId: "id", defaultSortAsc: false }}
      />
    </>
  )
}

import React, { useEffect } from 'react'
import { Navbar, Container, Nav } from 'react-bootstrap'
import { useAuth } from '../../contexts/AuthProvider';

export const Mant = () => {
  const {LogOut} = useAuth();
  useEffect(() => {
    document.title = "Mantenimiento";
  }, []);

 // const [loading, setLoading] = useState(false);

  return (
    <>
      <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
        <Container>
          <Navbar.Brand href="#home">Servicold</Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              {/* <Nav.Link href="#features">Notificaciones</Nav.Link>
              <Nav.Link href="#pricing">Historial</Nav.Link>
              <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">
                  Another action
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">
                  Separated link
                </NavDropdown.Item>
              </NavDropdown> */}
            </Nav>
            <Nav>
              <Nav.Link eventKey={2} onClick={LogOut} href="#/">
                Cerrar Sesion
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar >


      <div className="m-0 hv-100 justify-content-center align-items-center">
        <div className='h1 text-center my-5'><strong>Bienvenido a Servicold </strong></div>
        <div className="text-center"> Gracias por participar en nuestro proyecto, estamos trabajando
          para terminarlo</div>
      </div>
    </>
  )
}

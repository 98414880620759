import React from 'react';
import { Text, View } from "@react-pdf/renderer";

export const Checks2 = ({ inform }) => {
    return (
        <>
          <View style={{
            width: "48%",
            backgroundColor: '#f0f0f0',
            padding: "5px"
          }}>
            <Text style={{
              textAlign: 'center',
              paddingTop: "5px"
            }} >Sistema Eléctrico</Text>
            <View style={{
              display: "flex",
              flexDirection: 'row',
              padding: "5px"
            }}>
              <View style={{
                width: "50%",
                padding: "3px"
              }}>
                <Text style={{ marginTop: "2.2px" }}>Relay</Text>
                <Text style={{ marginTop: "2.2px" }}>Termico</Text>
                <Text style={{ marginTop: "2.2px" }}>Capacitor</Text>
                <Text style={{ marginTop: "2.2px" }}>Contactor</Text>
                <Text style={{ marginTop: "2.2px" }}>Iluminacion Interna</Text>
                <Text style={{ marginTop: "2.2px" }}>Iluminacion Externa</Text>
                <Text style={{ marginTop: "2.2px" }}>Balasto</Text>
                <Text style={{ marginTop: "2.2px" }}>Starter</Text>
                <Text style={{ marginTop: "2.2px" }}>Cable Alimentación</Text>
              </View>
              <View style={{ width: "50%", alignItems: 'center', }}>
                <View style={{
                  width: "50px",
                  height: "15px",
                  backgroundColor: '#ffffff',
                  borderRadius: 3,
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: "3px",
                  marginLeft: "2px",
                  marginTop: "3px"
                }}>
                  <Text>{inform?.relay ? "Bueno" : "Malo"}</Text>
                </View>
                <View style={{
                  width: "50px",
                  height: "15px",
                  backgroundColor: '#ffffff',
                  borderRadius: 3,
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: "3px",
                  marginLeft: "2px",
                  marginTop: "3px"
                }}>
                  <Text>{inform?.termico ? "Bueno" : "Malo"}</Text>
                </View>
                <View style={{
                  width: "50px",
                  height: "15px",
                  backgroundColor: '#ffffff',
                  borderRadius: 3,
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: "3px",
                  marginLeft: "2px",
                  marginTop: "3px"
                }}>
                  <Text>{inform?.capacitor ? "Bueno" : "Malo"}</Text>
                </View>
                <View style={{
                  width: "50px",
                  height: "15px",
                  backgroundColor: '#ffffff',
                  borderRadius: 3,
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: "3px",
                  marginLeft: "2px",
                  marginTop: "3px"
                }}>
                  <Text>{inform?.contactor ? "Bueno" : "Malo"}</Text>
                </View>
                <View style={{
                  width: "50px",
                  height: "15px",
                  backgroundColor: '#ffffff',
                  borderRadius: 3,
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: "3px",
                  marginLeft: "2px",
                  marginTop: "3px"
                }}>
                  <Text>{inform?.iluminacion_interna ? "Bueno" : "Malo"}</Text>
                </View>
                <View style={{
                  width: "50px",
                  height: "15px",
                  backgroundColor: '#ffffff',
                  borderRadius: 3,
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: "3px",
                  marginLeft: "2px",
                  marginTop: "3px"
                }}>
                  <Text>{inform?.iluminacion_externa ? "Bueno" : "Malo"}</Text>
                </View>
                <View style={{
                  width: "50px",
                  height: "15px",
                  backgroundColor: '#ffffff',
                  borderRadius: 3,
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: "3px",
                  marginLeft: "2px",
                  marginTop: "3px"
                }}>
                  <Text>{inform?.balasto ? "Bueno" : "Malo"}</Text>
                </View>
                <View style={{
                  width: "50px",
                  height: "15px",
                  backgroundColor: '#ffffff',
                  borderRadius: 3,
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: "3px",
                  marginLeft: "2px",
                  marginTop: "3px"
                }}>
                  <Text>{inform?.starter ? "Bueno" : "Malo"}</Text>
                </View>
              </View>
            </View>
            <Text style={{
              textAlign: 'center',
              paddingTop: "3px"
            }} >Condiciones</Text>
            <View style={{
              display: "flex",
              flexDirection: 'row',
              padding: "3px"
            }}>
              <View style={{
                width: "50%",
                padding: "3px"
              }}>
                <Text style={{ marginTop: "4px" }}>M/V Evaporador</Text>
                <Text style={{ marginTop: "4px" }}>M/V Condensador</Text>
                <Text style={{ marginTop: "4px" }}>Aspa Evaporador</Text>
                <Text style={{ marginTop: "4px" }}>Aspa Condesandor</Text>
                <Text style={{ marginTop: "4px" }}>Volataje de linea</Text>
                <Text style={{ marginTop: "4px" }}>Amperaje</Text>
              </View>
              <View style={{ width: "50%", alignItems: 'center', }}>
                <View style={{
                  width: "50px",
                  height: "15px",
                  backgroundColor: '#ffffff',
                  borderRadius: 3,
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: "3px",
                  marginLeft: "2px",
                  marginTop: "3px"
                }}>
                  <Text>{inform?.mv_evaporador ? "Bueno" : "Malo"}</Text>
                </View>
                <View style={{
                  width: "50px",
                  height: "15px",
                  backgroundColor: '#ffffff',
                  borderRadius: 3,
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: "3px",
                  marginLeft: "2px",
                  marginTop: "3px"
                }}>
                  <Text>{inform?.mv_condensador ? "Bueno" : "Malo"}</Text>
                </View>
                <View style={{
                  width: "50px",
                  height: "15px",
                  backgroundColor: '#ffffff',
                  borderRadius: 3,
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: "3px",
                  marginLeft: "2px",
                  marginTop: "3px"
                }}>
                  <Text>{inform?.aspa_evap ? "Bueno" : "Malo"}</Text>
                </View>
                <View style={{
                  width: "50px",
                  height: "15px",
                  backgroundColor: '#ffffff',
                  borderRadius: 3,
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: "3px",
                  marginLeft: "2px",
                  marginTop: "3px"
                }}>
                  <Text>{inform?.aspa_conde ? "Bueno" : "Malo"}</Text>
                </View>
                <View style={{
                  width: "50px",
                  height: "15px",
                  backgroundColor: '#ffffff',
                  borderRadius: 3,
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: "3px",
                  marginLeft: "2px",
                  marginTop: "3px"
                }}>
                  <Text>{inform?.voltaje_linea}</Text>
                </View>
                <View style={{
                  width: "50px",
                  height: "15px",
                  backgroundColor: '#ffffff',
                  borderRadius: 3,
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: "3px",
                  marginLeft: "2px",
                  marginTop: "3px"
                }}>
                  <Text>{inform?.amperaje}</Text>
                </View>
              </View>
            </View>
            <View style={{
              paddingLeft: "5px"
            }}>
              <View style={{
                display: "flex",
                flexDirection: 'row',
              }}>
                <Text>Tipo de Acometida:</Text>
                <Text style={{
                  maxWidth: "130px",
                  marginLeft: "3px"
                }}>{inform?.tipo_acometida}</Text>
              </View>
              <View style={{
                display: "flex",
                flexDirection: 'row',
              }}>
                <Text style={{ marginTop: "4px" }}>Tipo de Extension:</Text>
                <Text style={{
                  maxWidth: "130px",
                  marginLeft: "3px",
                  marginTop: "4px"
                }}>{inform?.tipo_extension}</Text>
              </View>
              <View style={{
                display: "flex",
                flexDirection: 'row',
              }}>
                <Text style={{ marginTop: "4px" }}>Calibre de Extesion:</Text>
                <Text style={{
                  maxWidth: "130px",
                  marginLeft: "3px",
                  marginTop: "4px"
                }}>{inform?.calibre_extension}</Text>
              </View>
            </View>
          </View>
        </>
      )
}
